import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {Header12} from '../layout/header12';
import Footer from '../layout/footer5';
import Button from '@mui/material/Button';
import { OilBarrel, MonetizationOn, LocalShipping, QueryStats} from '@mui/icons-material';


var bnr3 = require('./../../images/banner/bnr3.jpg');

// const styles={
//     text:{
//         overflow: "hidden",
//         position: "relative",
//         mixBlendMode: "hard-light",
//         '&:after':{
//             position: "absolute",
//             // content: "",
//             // left: '0px',
//             // top: '0px',
//             // height: '100%',
//             // width: '100%',
//             // // background: "linearGradient(transparent, gray)",
//             // // pointerEvents: 'none'
//         }
//     }
// }

function About2 () {
    const [isShowMore, setIsShowMore] = useState(false);

    return (
        <>
            <Header12/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">About us</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to={"/"}>Home</Link></li>
                                        <li>About us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="our-story">
                                            <span>OUR STORY</span>
                                            <h2 className="title"><b>Pronix</b><br/>Trading Company<br/></h2>
                                            <h4 className="title">Pronix Trading is a pioneer company which is active in variety of Commercial fields such as:</h4>
                                            <ul style={{marginLeft:'1rem'}}>
                                                <li><h6 className="title">Oil Products including Bitumen, Base Oil, RPO, Slack Wax etc.</h6></li>
                                                <li><h6 className="title">Petrochemicals including HDPE, LDPE, Urea, PVC etc.</h6></li>
                                                <li><h6 className="title">Oil and Gas Industry Equipment and Instrument.</h6></li>
                                            </ul>
                                            
                                            <p className={isShowMore?'':'paragText'}>
                                                We cooperate with the largest worldwide manufacturers and genuine producers in various industries such as oil, gas,
                                                petrochemicals and mining. We work together with our local and international partners to support the development of
                                                best companies across the globe. Our mission is to attract and attain customers by continuously supply of best quality products,
                                                and by creating value for our customers through research and development and commitment to highest standards. As part of this mission,
                                                we provide information and clarity on technical issues. We are proud to be the acknowledged partner of choice for our principles, customers,
                                                business partners
                                            </p>
                                            <Button variant='contained' color='primary' style={{color:'white'}}  onClick={()=>setIsShowMore(!isShowMore)}>{isShowMore? 'Show Less':'Show More'}</Button>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
                                        <img src={require("../../images/our-work/chemicals/noa.jpg")} className="radius-sm" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className="section-full box-about-list">
                            <div className="row spno">
                                <div className="col-lg-6 col-md-12"> 
                                    <img src={require("../../images/about/pic3.jpg")} alt="" className="img-cover"/> 
                                </div>
                                <div className="col-lg-6 col-md-12 bg-primary">
                                    <div className="max-w700 m-auto p-tb50 p-lr20">
                                        <div className="text-white">
                                            <h2>We're thriving and building <br/>better products</h2>
                                        </div>
                                        <div className="icon-bx-wraper m-b30 left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-factory"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Material Engineering</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                            </div>
                                        </div>
                                        <div className="icon-bx-wraper m-b30 left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-settings"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Chemical Research</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                            </div>
                                        </div>
                                        <div className="icon-bx-wraper left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-worker"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Petroleum Engineering</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="title">Our Services</h2>
                                </div>
                                <div className="section-content row">
                                    <div className="col-xl-6 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="01">
                                            <div className="icon-lg">
                                                <Link className="icon-cell"><QueryStats className='icon' style={{fontSize:'70px'}}/></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Market Analysis</h2>
                                                <p>PRONIX Trading Company provides market analysis services thorough qualitative and quantitative assessment of the current market. We provide practical data of volume and value of the market, potential customer segmentations and regular buying patterns. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="02">
                                            <div className="icon-lg">
                                                <Link  className="icon-cell" style={{cursor:'auto'}}><LocalShipping className='icon' style={{fontSize:'70px'}}/></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Logistic & Distribution</h2>
                                                <p>PRONIX Trading Company already has the necessary relationships with the suppliers and producers to perform a role as a bulk breaker, product carrier handling and even container delivery at the Loading & Discharging ports bringing cargos within our international network. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="03">
                                            <div className="icon-lg">
                                                <Link className="icon-cell" style={{cursor:'auto'}}><MonetizationOn className='icon' style={{fontSize:'70px'}}/></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Finance</h2>
                                                <p>PRONIX Trading Company has a wide network of relationship with international prime banks and other financial associations in Europe, Middle/Far East which would be able to set up suitable financial instruments for its specific deals all over the world. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="04">
                                            <div className="icon-lg">
                                                <Link className="icon-cell" style={{cursor:'auto'}}><OilBarrel className='icon' style={{fontSize:'70px'}}/></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Petroleum & Petrochemical Products Supply</h2>
                                                <p>PRONIX Trading Company in a full range of polymer/plastic raw materials, Chemicals, fertilizers, oil products and fuels through the Europe, CIS, Middle East, China, South east Asia and Africa. we offer various financial solutions to suppliers, to play more effective role in the market.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-xl-6 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="05">
                                            <div className="icon-lg">
                                                <Link  className="icon-cell"><AlarmOn className='icon' style={{fontSize:'70px'}}/></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">24/7 Customer Support</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="06">
                                            <div className="icon-lg">
                                                <Link className="icon-cell"><CandlestickChart className='icon' style={{fontSize:'70px'}}/></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Financial Services</h2>
                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod </p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className="section-full about-progress">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-7 col-md-12 bg-white">
                                        <div className="max-w700  m-auto content-inner">
                                            <div className="section-head">
                                                <h2 className="title">We provide the exceptional service we'd want to experience ourselves</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer</p>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Web Development<span className="progress-num pull-right">85%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.2s" data-wow-duration="2s" style={{width:"85%"}}></div>
                                                </div>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Web Designer<span className="progress-num pull-right">80%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.4s" data-wow-duration="2s" style={{width:"85%"}}></div>
                                                </div>
                                            </div>
                                            <div className="about-progress-box">
                                                <h6 className="text-primary">Performance<span className="progress-num pull-right">75%</span></h6>
                                                <div className="about-progress-bar bg-gray-dark">
                                                    <div className="about-progress bg-primary wow slideInLeft" data-wow-delay="0.6s" data-wow-duration="2s" style={{width:"85%"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12 p-a0">
                                        <img src={require("../../images/about/pic5.jpg")} alt="" className="img-cover "/> 
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

            <Footer/>
        </>
    )
}

export default About2;