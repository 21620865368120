import { Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
} from "@mui/material";
import React from "react";

function ProducerProducts({
  productsList,
  selectedProducts,
  setSelectedProducts,
}) {  
  return (
    <div style={{margin:'0px 10px'}}>
      <Autocomplete
        title="Search Products"
        disablePortal
        //   value={filteredItem.name}
        //   getOptionLabel={(agent) => agent.agentName}
        // getOptionSelected={(option, value) => option.name === value.name}
        onChange={(e, value) => {
          if (value) {
            setSelectedProducts((prevstate) => [
              ...prevstate,
              {
                productId: value.split(" ")[0],
                productName: value.split(" ")[1],
              },
            ]);
          }
        }}
        options={productsList.map((x) => x.productId + " " + x.productName)}
        sx={{ width: "100%",paddingBottom: "2rem" }}
        renderInput={(params) => (
          <TextField {...params} label={`Search Products...`} />
        )}
      />
      {selectedProducts.length?(
        <TableContainer
          // sx={
          //   productProducers.length
          //     ? { width: "75%", margin: "0.5rem" }
          //     : { width: "100%", margin: "0.5rem" }
          // }
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Product Id</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell align="right">remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProducts?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.productId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.productName}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="danger"
                      onClick={() =>
                        setSelectedProducts((prevstate) => [
                          ...prevstate.filter(
                            (x) => x.productId != row.productId
                          ),
                        ])
                      }
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ):null}
    </div>
  );
}

export default ProducerProducts;
