import React, { useState, useEffect } from "react";
import {
  AccountCircle,
  Close,
  Delete,
  Edit,
  Save,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Footer5 from "../layout/footer5";
import {Header12} from "../layout/header12";
import DefineProductSubcategory from "../components/defineProductSubcategory";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../request";
import DeleteModal from "../components/deleteModal";
import {useTheme} from "@mui/material";
import SideBar from "../components/sideBar";
import jwtDecode from "jwt-decode";
import { Link, useHistory } from "react-router-dom";

const styles = {
  containerBox2: {
    display: "grid",
    gridTemplateColumns: "auto ",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  containerBox3: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  box3: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "37rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  box2: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "45rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  innerBox: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "auto auto ",
    justifyContent: "space-around",
  },
  types: {
    backgroundColor: "#ccc",
    borderRadius: "0.5rem 0.5rem 0rem 0rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  newclass1: {
    position: "absolute",
    width: "unset",
    background: "none",
    left: "32%",
    top: "34.5%",
  },
  newclass2: {
    position: "absolute",
    width: "unset",
    background: "none",
    left: "6%",
    top: "34.5%",
  },
};
const bg = require("./../../images/banner/bnr5.jpg");
function ProductCategories() {
  const theme = useTheme();
  const history = useHistory();
  const code = (Math.random() * 1000000).toFixed(0);
  const [productCategory, setProductCategory] = useState({
    productCategoryCode: `product-category-${code}`,
    productCategoryName: "",
    productCategoryLocalName: "",
  });
  const [open, setOpen] = useState(false)
  const [isShowProduct, setIsShowProduct] = useState(false);
  const [editedCategory, setEditedCategory] = useState("");
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState("");
  const [productSubCategoryList, setProductSubCategoryList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deletedCategoryId, setDeletedCategoryId] = useState(-1);
  const [deleteSuccessful, setDeleteSuccessful] = useState(false);

  useEffect(() => {
    if(decode.role){
      fetchCategories();
    }else{
      history.push('/')
    }
  }, []);
  useEffect(() => {
    if(deleteSuccessful){
      setProductCategoryList(prevstate=>[...prevstate.filter(x=>x.productCategoryId !== deletedCategoryId)])
      setDeleteSuccessful(false);
      setDeletedCategoryId(-1);
    }
  }, [deleteSuccessful])
  const fetchCategories = async () => {
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory`)
      if(data && Object.keys(data).length){
        setProductCategoryList(data);
      }else{
        toast.error(error);
      }
  };
  const handleChange = (event) => {
    setProductCategory({
      ...productCategory,
      [event.target.name]: event.target.value,
    });
  };
  const handleRemoveCategory = async (id) => {
    setOpenModal(true);
    setDeletedCategoryId(id);
  };

  const handleEditCategory = async (id) => {
    setIsShowProduct(false);
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory/subcategories/${id}`)
    if(data ){
      setSelectedProductCategory(id);
      setProductSubCategoryList(data);
    }else{
      toast.error(error);
    }
  };
  const handleAddProductCategory = async () => {
    const {message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory`,productCategory)
    if (message === 'ok') {
      const code = (Math.random() * 1000000).toFixed(0);
      setProductCategoryList((prevstate) => [...prevstate, productCategory]);
      toast.success("product category added successfully!");
      setProductCategory({
        productCategoryCode: `product-category-${code}`,
        productCategoryName: "",
        productCategoryLocalName: "",
      });
    }
  };
  const editCategory = async (id) => {
    const categoryData = {
      ...productCategory,
      productCategoryId: editedCategory,
    };
    const {message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory/edit`,categoryData);
    if (message === 'ok') {
      productCategoryList[
        productCategoryList.findIndex(
          (x) => x.productCategoryId === editedCategory
        )
      ] = productCategory;
      setProductCategoryList(productCategoryList);
      setSelectedProductCategory("");
      setProductCategory({
        productCategoryCode: `product-category-${code}`,
        productCategoryName: "",
        productCategoryLocalName: "",
      });
      toast.success("category edited successfully!");
    }
  };
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };
  const token = localStorage.getItem('token');
  let decode={};
  if(token){
    decode = jwtDecode(token);
  } 
  return (
    <div>
      <Header12 />
      <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="container">
                <div className="dlab-bnr-inr-entry">
                    <h1 className="text-white">Product Categories</h1>
                    <div className="breadcrumb-row">
                        <ul className="list-inline">
                            <li><Link to="/">Home</Link></li>
                            <li>Product Categories</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      {decode.role === 'SuperAdmin'?
        <Button color='primary' variant='contained' onClick={toggleDrawer( true)} sx={{margin:'1rem 3rem'}}>user options <AccountCircle/></Button>
      :null}
      <Box
        component="form"
        sx={
          selectedProductCategory ? styles.containerBox3 : styles.containerBox2
        }
      >
        <Box>
          <Box sx={styles.types}>
            <Typography
              variant="h5"
              color="initial"
              sx={selectedProductCategory ? styles.newclass2 : styles.newclass1}
            >
              <span>Product Category</span>
            </Typography>
          </Box>
          <Box sx={selectedProductCategory ? styles.box3 : styles.box2}>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="Code"
                sx={{ width: "250px" }}
                name="productCategoryCode"
                value={productCategory.productCategoryCode}
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                label="Name"
                sx={{ width: "250px" }}
                name="productCategoryName"
                value={productCategory.productCategoryName}
                onChange={handleChange}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="localName"
                sx={{ width: "250px" }}
                name="productCategoryLocalName"
                value={productCategory.productCategoryLocalName}
                onChange={handleChange}
              />
              <div style={{ width: "250px" }}></div>
            </div>
            <div style={styles.innerBox}>
              <div style={{ width: "250px" }}>
                {editedCategory ? (
                  <ButtonGroup>
                    <Button
                      variant="contained"
                      color="danger"
                      sx={{ color: "#efefef" }}
                      onClick={() => {
                        setSelectedProductCategory("");
                        setEditedCategory("");
                        setProductCategory({
                          productCategoryCode: `product-category-${code}`,
                          productCategoryName: "",
                          productCategoryLocalName: "",
                        });
                      }}
                    >
                      cancel
                      <Close />
                    </Button>
                    <Button
                      variant="contained"
                      color="edit"
                      sx={{ color: "#efefef" }}
                      onClick={editCategory}
                    >
                      edit
                      <Edit />
                    </Button>
                  </ButtonGroup>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !productCategory.productCategoryCode ||
                      !productCategory.productCategoryName ||
                      !productCategory.productCategoryLocalName
                    }
                    sx={{ color: "white" }}
                    onClick={handleAddProductCategory}
                  >
                    save
                    <Save />
                  </Button>
                )}
              </div>
              <div style={{ width: "211px" }}></div>
            </div>
            <Box sx={{ width: "100%", padding: "0rem 3rem", }}>
              <TableContainer 
              component={Paper}
              sx={{height:'24rem',overflow:'auto'}}
              >
                <Table
                  sx={{ width: "95%", margin: "0.5rem" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>code</TableCell>
                      <TableCell align="right">name</TableCell>
                      <TableCell align="right">edit</TableCell>
                      <TableCell align="right">remove</TableCell>
                      <TableCell align="right">sub categories</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productCategoryList.length
                      ? productCategoryList.map((row) => (
                          <TableRow
                            key={row.code}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.productCategoryCode}
                            </TableCell>
                            <TableCell align="right">
                              {row.productCategoryName}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={() => {
                                  
                                  setEditedCategory(row.productCategoryId);
                                  setProductCategory(
                                    productCategoryList.find(
                                      (x) =>
                                        x.productCategoryId ===
                                        row.productCategoryId
                                    )
                                  );
                                }}
                              >
                                <Edit color="edit" />
                              </IconButton>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label=""
                                onClick={() =>
                                  handleRemoveCategory(row.productCategoryId)
                                }
                              >
                                <Delete color="danger" />
                              </IconButton>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label=""
                                onClick={() =>
                                  handleEditCategory(row.productCategoryId)
                                }
                              >
                                <Visibility sx={selectedProductCategory===row.productCategoryId? { color:theme.palette.primary.main}:{ color:'inherit'}}/>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
        <DefineProductSubcategory
          productSubCategoryList={productSubCategoryList}
          setProductSubCategoryList={setProductSubCategoryList}
          selectedProductCategory={selectedProductCategory}
          isShowProduct={isShowProduct}
          setIsShowProduct={setIsShowProduct}
        />
      </Box>
      <SideBar open={open} toggleDrawer={toggleDrawer} />

      <Footer5 />
      
      {openModal?
       <DeleteModal openDeleteModal={openModal} setOpenDeleteModal={setOpenModal} parameterName="product category" deleteURL={`${process.env.REACT_APP_BASE_URL}/ProductCategory/delete/${deletedCategoryId}`} destination={'NotLoad'} setIsSuccessful={setDeleteSuccessful}/>
        :null}
    </div>
  );
}

export default ProductCategories;
