import React from 'react';
import { Link } from 'react-router-dom';
import Team1 from '../element/team1';
import TeamRounded from '../element/teamrounded';
import Footer5 from '../layout/footer5';
import {Header12} from '../layout/header12';

const bg = require("./../../images/banner/bnr5.jpg");

function Team() {
  return (
    <div>
        <Header12/>
		<div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="container">
                <div className="dlab-bnr-inr-entry">
                    <h1 className="text-white">Team</h1>
                    <div className="breadcrumb-row">
                        <ul className="list-inline">
                            <li><Link to="/">Home</Link></li>
                            <li>Team</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="section-full bg-white content-inner">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="sort-title clearfix text-center">
							<h4>Our Support Team</h4>
						</div>
						<Team1 />
					</div>
				</div>
			</div>
		</div>
        <Footer5/>
    </div>
  )
}

export default Team