import { Typography, IconButton, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { HighlightOff } from "@mui/icons-material";
import CommentReplying from "./commentReplying";
import { getRequest, postRequest } from "../../request";

function PostBlog({ blogPosts, blogKeyWords, blogSection }) {
  const [blogDate, setBlogDate] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [repliedCommentId, setRepliedCommentId] = useState(-1);
  const [repliedComment, setRepliedComment] = useState({});
  // const [replayIterationCounter, setReplayIterationCounter] = useState(0)
  const postCreateDate = new Date();
  // let replayIterationCounter=0;
  const token = localStorage.getItem("token");
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }
  const [comment, setComment] = useState({
    postId: 0,
    replayId: 0,
    userId: decode.uid ? decode.uid : "",
    value: "",
    createDateTime: postCreateDate.toISOString(),
    updateDateTime: postCreateDate.toISOString(),
  });

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    const {message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/BlogComment`,comment);
    if (message=== 'ok') {
      toast.success("comment has been sent");
      window.location.reload();
    }
  };
  const fetchComments = async () => {
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogPost/comments/${blogPosts.id}`)
      if(data && Object.keys(data).length){
        setCommentList(data);
      }else{
        toast.error(error);
      }
  };
  useEffect(() => {
    if (repliedCommentId !== -1) {
      fetchRepliedComment();
      setComment({ ...comment, replayId: repliedCommentId });
    }
  }, [repliedCommentId]);

  const fetchRepliedComment = async () => {
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogComment/${repliedCommentId}`)
      if(data && Object.keys(data).length){
        setRepliedComment(data);
      }else{
        toast.error(error);
      }
  };

  useEffect(() => {
    if (blogPosts.createDateTime) {
      const date = new Date(blogPosts.createDateTime);
      setBlogDate(date.toString().split(" "));
    }
    if (blogPosts.id) {
      fetchComments();
      setComment({ ...comment, postId: blogPosts.id });
    }
  }, [blogPosts]);

  return (
    <div>
      <div className="blog-post blog-single">
        <div className="dlab-post-meta" style={{paddingLeft:'0.2rem'}}>
          <ul>
            {blogDate.length ? (
              <li className="post-date">
                {" "}
                <strong>
                  {blogDate[2]} {blogDate[1]}
                </strong>{" "}
                <span> {blogDate[3]}</span>{" "}
              </li>
            ) : null}
            {blogPosts.user ? (
              <li className="post-author">
                {" "}
                By <Link>{blogPosts.user}</Link>{" "}
              </li>
            ) : null}
          </ul>
        </div>
        <div className="dlab-post-title " style={{paddingLeft:'0.2rem'}}>
          <h4 className="post-title m-t0">
            <Link>{blogPosts.title}</Link>
          </h4>
        </div>
        <div className="dlab-post-media dlab-img-effect zoom-slow" style={{padding:'1rem'}}>
          {blogPosts.photo ? (
            <Link>
              <img
                style={{ maxHeight: "30rem", marginBottom: "3rem",boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
                // className='blog-header-img'
                src={`${process.env.REACT_APP_IMG_URL}`+blogPosts.photo}
                alt=""
              />
            </Link>
          ) : null}
        </div>
        {blogSection.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {blogSection
              .filter((x) => x.order !== 0)
              ?.map((x, index) => (
                <div key={x.id}>
                  {x.type === 1 ? (
                    <img src={`${process.env.REACT_APP_IMG_URL}`+x.value} alt="" className='blog-header-img'/>
                  ) : (
                    <div>
                      <p style={{ textAlign: "center" }}>{x.value}</p>
                      {/* {index+1===blogSection.length?null:<Divider/>} */}
                    </div>
                  )}
                </div>
              ))}
            {/* <img className="alignleft" width="300" src={require("../../images/blog/grid/pic4.jpg")} alt=""/>
	  		<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the releasefive centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release</p>
	  		<div className="dlab-divider bg-gray-dark"></div>
	  		<img className="alignright" width="300" src={require("../../images/blog/grid/pic1.jpg")} alt=""/>
	  		<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release</p> */}
          </div>
        ) : null}
        <div className="dlab-post-tags clear">
          <div className="post-tags">
            {blogKeyWords.length
              ? blogKeyWords.map((keyWord) => (
                  <Link key={keyWord.id}>
                    {keyWord.value}{" "}
                  </Link>
                ))
              : null}
            {/* <Link to="/#">Eduction </Link> <Link to="/#">Money </Link> <Link to="/#">Resturent </Link>  */}
          </div>
        </div>
      </div>
      {blogPosts.id?
      <div className="clear" id="comment-list" style={{padding:'0rem 0.5rem'}}>
        <div className="comments-area" id="comments">
          <h2 className="comments-title">{commentList.length} Comments</h2>
          <div className="clearfix">
            <ol className="comment-list">
              {/* <li className="comment">
							<div className="comment-body">
								<div className="comment-author vcard"> <img  className="avatar photo" src={require("../../images/testimonials/pic1.jpg")} alt=""/> <cite className="fn">Oliver</cite> <span className="says">says:</span> </div>
								<div className="comment-meta"> <Link to="/#">October 6, 2015 at 7:15 am</Link> </div>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neqnsectetur adipiscing elit. Nam viae neqnsectetur adipiscing elit.
									Nam vitae neque vitae sapien malesuada aliquet. </p>
								<div className="reply"> <Link to="/#" className="comment-reply-link">Reply</Link> </div>
							</div>
							<ol className="children">
								<li className="comment odd parent">
									<div className="comment-body">
										<div className="comment-author vcard"> <img  className="avatar photo" src={require("../../images/testimonials/pic2.jpg")} alt=""/> <cite className="fn">Harry</cite> <span className="says">says:</span> </div>
										<div className="comment-meta"> <Link to="/#">October 6, 2015 at 7:15 am</Link> </div>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
											In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
											ac elementum ligula blandit ac.</p>
										<div className="reply"> <Link to="/#" className="comment-reply-link">Reply</Link> </div>
									</div>
									<ol className="children">
										<li className="comment odd parent">
											<div className="comment-body">
												<div className="comment-author vcard"> <img  className="avatar photo" src={require("../../images/testimonials/pic3.jpg")} alt=""/> <cite className="fn">Thomas</cite> <span className="says">says:</span> </div>
												<div className="comment-meta"> <Link to="/#">October 6, 2015 at 7:15 am</Link> </div>
												<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
													In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
													ac elementum ligula blandit ac.</p>
												<div className="reply"> <Link to="/#" className="comment-reply-link">Reply</Link> </div>
											</div>
										</li>
									</ol>
								</li>
							</ol>
						</li> */}
              {/* {continue here} */}
              {commentList
                .filter((x) => x.replayId === 0)
                ?.map((comment, index) => (
                  <CommentReplying
                    key={index}
                    comment={comment}
                    setRepliedCommentId={setRepliedCommentId}
                    commentList={commentList}
                  />
                ))}
              
            </ol>

            <div className="comment-respond" id="respond">
              <h4 className="comment-reply-title" id="reply-title">
                {repliedCommentId !== -1 ? "Reply Comment" : "Leave a Comment"}{" "}
                {repliedCommentId !== -1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "1rem",
                      boxShadow: "0px 0px 2px 2px #ddd",
                      borderRadius: "5px",
                      bgcolor: "#efefef",
                    }}
                  >
                    <Typography component="h6">
                      {repliedComment.value}
                    </Typography>
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setRepliedCommentId(-1);
                        setRepliedComment({});
                        setComment({ ...comment, replayId: 0 });
                      }}
                    >
                      <HighlightOff sx={{ cursor: "pointer" }} />
                    </IconButton>
                  </Box>
                ) : null}
                <small>
                  {" "}
                  <Link
                    style={{ display: "none" }}
                    to="#"
                    id="cancel-comment-reply-link"
                    rel="nofollow"
                  >
                    Cancel reply
                  </Link>{" "}
                </small>{" "}
              </h4>
              <form
                className="comment-form"
                id="commentform"
                onSubmit={handleSubmitComment}
              >
                <p className="comment-form-comment">
                  <label for="comment">Comment</label>
                  <textarea
                    rows="8"
                    name="comment"
                    value={comment.value}
                    onChange={(e) =>
                      setComment({ ...comment, value: e.target.value })
                    }
                    placeholder="Comment"
                    id="comment"
                  ></textarea>
                </p>
                <p className="form-submit">
                  <input
                    type="submit"
                    value="Post Comment"
                    disabled={!comment.value}
                    className="submit"
                    id="submit"
                    name="submit"
                  />
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      :null}
    </div>
  );
}

export default PostBlog;
