import React, { useEffect,Fragment } from 'react';
import { Link } from 'react-router-dom';

import Sidebar from '../element/sidebar';
import {Header12} from '../layout/header12';
import Footer5 from '../layout/footer5';
import { getRequest } from '../../request';
import { toast } from 'react-toastify';
import { useState } from 'react';
import Loading from 'react-loading';
import { Box } from '@mui/system';

const bg = require('../../images/banner/bnr5.jpg')

function BlogList (){
	const [blogLists, setBlogLists] = useState([]);
	const [searchWord, setSearchWord] = useState('');
	const [keyWords, setKeyWords] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
	  fetchBlogs()
	}, []);
	useEffect(() => {
	  if(blogLists.length){
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	  }
	}, [blogLists])
	
	
	const fetchBlogs=async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogPost`)
        if(data && Object.keys(data).length){
            const list = data;
            let d;
            for(let i=0;i<list.length;i++){
				const sectionResponse = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogPost/sections/${list[i].id}`);
				if(sectionResponse.data && Object.keys(sectionResponse.data).length){
				const section =sectionResponse.data.filter(x=>x.type ===0)[0];
                d = new Date (list[i].updateDateTime);
                list[i].date = d.toString();
				list[i].section = section?.value;
			}
			const keyWordResponse = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogPost/keywords/${list[i].id}`);
			if(keyWordResponse.data && Object.keys(keyWordResponse.data).length){
				for(let i=0;i<keyWordResponse.data.length;i++){
					setKeyWords((prevstate=>[...prevstate,keyWordResponse.data[i].value]))
				}
			}
		}
            setBlogLists(list.reverse());
        }else{
            toast.error(error);
        }
    }
    return (
        <Fragment>
            <Header12/>
            <div className="page-content bg-white">
                <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white">Blog List</h1>
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link to="/">Home</Link></li>
                                    <li>Blog List</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
				{isLoading?
				<Box sx={{height:'80vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
					<Loading color='#000' type="cylon" />
				</Box>:
				<div className="content-area">
				<div className="container">
					<div className="row">
						<Sidebar  blogLists={blogLists} setSearchWord={setSearchWord} keyWords={keyWords}/>
						<div className="col-lg-9">
						{blogLists.length?
							blogLists.filter(x=>x.title.toLowerCase().includes(searchWord.toLowerCase())).map((x,item)=>(
								<div className="blog-post blog-md clearfix" key={item}>
									<div className="dlab-post-media dlab-img-effect zoom-slow"> 
										<img src={`${process.env.REACT_APP_IMG_URL}`+x.photo} alt=""/>
									</div>
									<div className="dlab-post-info">
										<div className="dlab-post-meta">
											<ul>
												<li className="post-date"> <strong>{x.date.split(' ')[2]} {x.date.split(' ')[1]}</strong> <span> {x.date.split(' ')[3]}</span>  </li>
												<li className="post-author"> By <Link >{x.user}</Link> </li>
											</ul>
										</div>
										<div className="dlab-post-title">
											<h4 className="post-title">{x.title.width>50?<h4 className="post-title1"><Link to={`/blog/:${x.id}`}>{x.title.slice(0,50)} <b>{'   ...'}</b></Link></h4>:
											<h4 className="post-title1"><Link to={`/blog/:${x.id}`}>{x.title}</Link></h4>}</h4>
										</div>
										<div className="dlab-post-text">
											<p style={{maxHeight:'5.5rem',overflow:'hidden'}}>{x.section}</p>
										</div>
										<div className="dlab-post-readmore"> 
											<Link to={`/blog/:${x.id}`} title="READ MORE" rel="bookmark" className="site-button">READ MORE
												<i className="ti-arrow-right"></i>
											</Link>
										</div>
									</div>
								</div>
							)):null
						}							
						</div>
					</div>
				</div>
			</div>
				}
				
            </div>                 
            <Footer5/>                
        </Fragment>
    )
    
}
export default BlogList;