import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import WhatsAppIcon from '../layout/whatsAppIcon';
//import Slider from "react-slick";

function Team1() {
	const womanEmployee = require("../../images/our-work/employee/womenEmployee.png");
	const manEmployee = require("../../images/our-work/employee/manEmployee.png");
        return (
            <>
                <div className="row" >
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="relative">
							<div className="dlab-box dlab-team1">
								<div className="inner">
									<div className="dlab-media">
										<Link to="#">
											<LazyLoadImage width="358" height="460" alt="" src={require('../../images/our-work/employee/sanaz.jpg')}/>
										</Link>
									</div>
									<div className="dlab-info">
										<h4 className="dlab-title"><Link to='#'>Ms.Sanaz</Link></h4>
										<span className="dlab-position">Turkey SalesPerson</span>
										<ul className="dlab-social-icon dez-border">
											<li ><Link to='#' className="fa fa-facebook" ></Link></li>
											{/* <li ><Link className="fa fa-twitter"  ></Link></li> */}
											<li ><Link className="fa fa-linkedin" to='#'
											onClick={() =>
												window.open(
													'https://www.linkedin.com/in/sanaz-moharrami-479259230/'
												)
											}
											>
											</Link></li>
											<li 
												onClick={() =>
													window.open(
													"https://wa.me/message/CFWX7L3FUN7QD1"
													)}
											>
												<Link to='#' className="fa" ><WhatsAppIcon color='#fff' /></Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="dlab-box m-b30 dlab-team1">
							<div className="dlab-media">
								<Link to='#'>
									<LazyLoadImage width="358" height="460" alt="" src={require('../../images/our-work/employee/golpasha.jpg')}/>
								</Link>
							</div>
							<div className="dlab-info">
								<h4 className="dlab-title"><Link to='#'>Mr.Golpasha</Link></h4>
								<span className="dlab-position">Dubai SalesPerson</span>
								<ul className="dlab-social-icon dez-border">
									<li><Link className="fa fa-facebook" to='#'></Link></li>
									{/* <li><Link className="fa fa-twitter"  ></Link></li> */}
									<li><Link className="fa fa-linkedin" to='#' 
									onClick={() =>
										window.open(
											'https://www.linkedin.com/in/hamid-golpasha-72387a7a/'
										)
									} ></Link></li>
									
									<li><Link to='#' className="fa"><WhatsAppIcon color='#fff' /></Link></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="dlab-box m-b30 dlab-team1">
							<div className="dlab-media">
								<Link to={"#"}>
									<LazyLoadImage width="358" height="460" alt="" src={require('../../images/our-work/employee/zeynali.jpg')}/>
								</Link>
							</div>
							<div className="dlab-info">
								<h4 className="dlab-title"><Link to={"#"}>Ms.Maria</Link></h4>
								<span className="dlab-position">India & Europe SalesPerson</span>
								<ul className="dlab-social-icon dez-border">
									<li><Link className="fa fa-facebook" to='#'></Link></li>
									{/* <li><Link className="fa fa-twitter"  ></Link></li> */}
									<li><Link className="fa fa-linkedin" to='#'
										onClick={() =>
											window.open(
												'http://linkedin.com/in/maryam-zeinali-112ab41ba'
											)
										}
									>
									</Link></li>
									<li
										onClick={() =>
											window.open(
											"https://wa.me/message/24APVY3IOIYHL1"
											)}
									><Link className="fa" to='#'><WhatsAppIcon color='#fff' /></Link></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-sm-6">
						<div className="dlab-box m-b30 dlab-team1">
							<div className="dlab-media">
								<Link to='#'>
									<LazyLoadImage width="358" height="460" alt="" src={require('../../images/our-work/employee/faghihi.jpg')}/>
								</Link>
							</div>
							<div className="dlab-info">
								<h4 className="dlab-title"><Link to={"#"}>Ms.Marzie</Link></h4>
								<span className="dlab-position">CIS SalesPerson</span>
								<ul className="dlab-social-icon dez-border">
									<li><Link className="fa fa-facebook"  to='#'></Link></li>
									{/* <li><Link className="fa fa-twitter"   ></Link></li> */}
									<li><Link className="fa fa-linkedin"  to='#'
									onClick={() =>
										window.open(
										"https://www.linkedin.com/in/marzieh-faghihi-b8715759/"
										)}></Link></li>
									<li 
									onClick={() =>
										window.open(
										"https://wa.me/971566879135"
									)}><Link className="fa" to='#'><WhatsAppIcon color='#fff' /></Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>

            </>
        )
    
}
export default Team1;