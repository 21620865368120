import {
  Button,
  TextField,
  IconButton,
  Box,
  ButtonGroup,
  Typography,
} from "@mui/material";
import React, { useState, Fragment } from "react";
import { Add, Close, Delete, Edit, Visibility } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import { getRequest, postRequest } from "../../request";
import DeleteModal from "./deleteModal";
import { useEffect } from "react";
import { useTheme } from "@mui/material";

const styles = {
  box2: {
    boxShadow: "0px 0px 6px 2px #ddd",
    height: "70vh",
    width: "45rem",
    display: "flex",
    flexDirection: "column",
  },
  box3: {
    boxShadow: "0px 0px 6px 2px #ddd",
    height: "70vh",
    width: "38rem",
    display: "flex",
    flexDirection: "column",
  },
  productBox3: {
    boxShadow: "0px 0px 6px 2px #ddd",
    height: "70vh",
    width: "32rem",
    display: "flex",
    flexDirection: "column",
  },
  unitBox: {
    boxShadow: "0px 0px 6px 2px #ddd",
    height: "70vh",
    padding: "0.5rem",
  },
  unit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  innerBox: {
    display: "grid",
    width: "100%",
    padding: "1rem",
    gridTemplateColumns: "auto auto",
    justifyContent: "space-around",
  },
  table2: {
    margin: "0rem 5rem",
    width: "auto",
    maxHeight: "23rem",
    overflow: "scrollY",
  },
  table3: {
    margin: "0rem 1rem",
    width: "auto",
    maxHeight: "23rem",
    overflow: "scrollY",
  },
  newclass1: {
    position: "absolute",
    width: "unset",
    background: "none",
    left: "35%",
    top: "34.5%",
  },
  newclass2: {
    position: "absolute",
    width: "unset",
    background: "none",
    left: "50%",
    top: "34.5%",
  },
  myclass: {
    position: "absolute",
    width: "unset",
    background: "none",
    left: "75%",
    top: "34.5%",
  },
};
function DefineProductSubCategory({
  productSubCategoryList,
  setProductSubCategoryList,
  selectedProductCategory,
  isShowProduct,
  setIsShowProduct,
}) {
  const code = (Math.random() * 1000000).toFixed(0);
  const theme = useTheme();
  const [products, setProducts] = useState([]);
  const [selectedSubCat, setselectedSubCat] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteSuccessful, setDeleteSuccessful] = useState(false);
  const [productSubCategory, setProductSubCategory] = useState({
    productSubCategoryCode: `product-subcategory-${code}`,
    productSubCategoryName: "",
    productSubCategoryLocalName: "",
  });
  const [deletedSubCatId, setDeletedSubCatId] = useState(-1);
  const handleChange = (event) => {
    setProductSubCategory({
      ...productSubCategory,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    if (deleteSuccessful) {
      setProductSubCategoryList((prevstate) => [
        ...prevstate.filter((x) => x.productSubCategoryId !== deletedSubCatId),
      ]);
      setDeleteSuccessful(false);
      setDeletedSubCatId(-1);
    }
  }, [deleteSuccessful]);

  const handleRemoveSubCategory = async (id) => {
    setDeletedSubCatId(id);
    setOpenModal(true);
  };
  const routeToProduct = (id) => {
    window.open(`${process.env.REACT_APP_WEBSIte_URL}/productProperties/${id}`);
  };

  const handleEditSubCategory = async () => {
    const currentProductSubCategory = {
      ...productSubCategory,
      productSubCategoryId: selectedSubCat,
      productCategoryId: selectedProductCategory,
    };
    const { data, message } = await postRequest(
      `${process.env.REACT_APP_BASE_URL}/ProductSubCategory/edit`,
      currentProductSubCategory
    );
    if (message === "ok") {
      productSubCategoryList[
        productSubCategoryList.findIndex(
          (x) => x.productSubCategoryCode === selectedSubCat
        )
      ] = productSubCategory;
      setProductSubCategoryList(productSubCategoryList);
      setselectedSubCat("");
      setProductSubCategory({
        productSubCategoryCode: `product-subcategory-${code}`,
        productSubCategoryName: "",
        productSubCategoryLocalName: "",
      });
      toast.success("SubCategory edited successfully!");
    }
  };

  const handleShowProducts = async (id, row) => {
    const { data, error } = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/ProductSubCategory/products/${id}`
    );
    if (data && Object.keys(data).length) {
      setProducts(data);
      if (data.length) {
        setIsShowProduct(true);
      }
    } else {
      setIsShowProduct(false);
    }
  };
  const addProductSubCategory = async () => {
    const currentProductSubCategory = {
      ...productSubCategory,
      productCategoryId: selectedProductCategory,
    };
    const { data, message } = await postRequest(
      `${process.env.REACT_APP_BASE_URL}/ProductSubCategory`,
      currentProductSubCategory
    );
    if (message === "ok") {
      currentProductSubCategory.productSubCategoryId = data;
      setProductSubCategoryList((prevstate) => [
        ...prevstate,
        currentProductSubCategory,
      ]);
      setProductSubCategory({
        productSubCategoryCode: `product-subcategory-${(
          Math.random() * 1000000
        ).toFixed(0)}`,
        productSubCategoryName: "",
        productSubCategoryLocalName: "",
      });
    }
  };
  return (
    <Fragment>
      {selectedProductCategory ? (
        <Box>
          <Box
            sx={{
              backgroundColor: "#ccc",
              borderRadius: "0.5rem 0.5rem 0rem 0rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h5"
              color="initial"
              sx={isShowProduct ? styles.newclass1 : styles.newclass2}
            >
              <span>Product Sub Categories</span>
            </Typography>
          </Box>
          <Box sx={selectedSubCat ? styles.box3 : styles.box2}>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="Code"
                name="productSubCategoryCode"
                value={productSubCategory.productSubCategoryCode}
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                label="Name"
                name="productSubCategoryName"
                value={productSubCategory.productSubCategoryName}
                onChange={handleChange}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="localName"
                name="productSubCategoryLocalName"
                value={productSubCategory.productSubCategoryLocalName}
                onChange={handleChange}
              />
              <div style={{ width: "211px" }}></div>
            </div>
            <div style={styles.innerBox}>
              <ButtonGroup>
                {selectedSubCat ? null : (
                  <Button
                    variant="contained"
                    disabled={
                      !productSubCategory.productSubCategoryLocalName ||
                      !productSubCategory.productSubCategoryName ||
                      !productSubCategory.productSubCategoryCode
                    }
                    color="primary"
                    sx={{ color: "#fff" }}
                    onClick={addProductSubCategory}
                  >
                    add <Add />
                  </Button>
                )}
                {selectedSubCat ? (
                  <Button
                    variant="contained"
                    color="edit"
                    sx={{ color: "#fff" }}
                    onClick={handleEditSubCategory}
                  >
                    edit <Edit />
                  </Button>
                ) : null}
                {selectedSubCat ? (
                  <Button
                    color="danger"
                    variant="contained"
                    sx={{ color: "#fff" }}
                    onClick={() => {
                      setselectedSubCat("");
                      setProductSubCategory({
                        productSubCategoryCode: `product-subcategory-${code}`,
                        productSubCategoryName: "",
                        productSubCategoryLocalName: "",
                      });
                    }}
                  >
                    cancel <Close />
                  </Button>
                ) : null}
              </ButtonGroup>
              <div style={{ width: "211px" }}></div>
            </div>

            <div style={styles.innerBox}>
              <div
                style={{
                  width: "211px",
                  float: "right",
                  display: "flex",
                  justifyContent: "end",
                }}
              ></div>
            </div>
            {productSubCategoryList.length ? (
              <TableContainer
                sx={selectedProductCategory ? styles.table3 : styles.table2}
                component={Paper}
              >
                <Table
                  sx={{ width: "95%", margin: "0.5rem" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>code</TableCell>
                      <TableCell align="right">name</TableCell>
                      <TableCell align="right">edit</TableCell>
                      <TableCell align="right">remove</TableCell>
                      <TableCell align="right">show products</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productSubCategoryList.map((row) => (
                      <TableRow
                        key={row.productSubCategoryId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.productSubCategoryCode}
                        </TableCell>
                        <TableCell align="right">
                          {row.productSubCategoryName}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => {
                              setselectedSubCat(row.productSubCategoryId);
                              setProductSubCategory(
                                productSubCategoryList.find(
                                  (x) =>
                                    x.productSubCategoryId ===
                                    row.productSubCategoryId
                                )
                              );
                            }}
                          >
                            <Edit color="edit" />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label=""
                            onClick={() =>
                              handleRemoveSubCategory(row.productSubCategoryId)
                            }
                          >
                            <Delete color="danger" />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label=""
                            onClick={() =>
                              handleShowProducts(
                                row.productSubCategoryId,
                                row.productSubCategoryCode
                              )
                            }
                          >
                            <Visibility />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </Box>
        </Box>
      ) : null}
      {isShowProduct && products.length ? (
        <Box>
          <Box
            sx={{
              backgroundColor: "#ccc",
              borderRadius: "0.5rem 0.5rem 0rem 0rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5" color="initial" sx={styles.myclass}>
              <span>products</span>
            </Typography>
          </Box>
          <Box sx={styles.productBox3}>
            <Box sx={styles.unitBox}>
              <Link
                className="site-button btnhover14"
                style={{ margin: "1rem 0rem 0rem 1rem" }}
                to="/productProperties/0"
              >
                Add New Product
              </Link>
              {products.length ? (
                <div style={{ marginTop: "2rem" }}>
                  <TableContainer
                    sx={selectedProductCategory ? styles.table3 : styles.table2}
                    component={Paper}
                  >
                    <Table
                      sx={{ width: "95%", margin: "0.5rem" }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>code</TableCell>
                          <TableCell align="right">name</TableCell>
                          <TableCell align="right">view</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {products.map((row) => (
                          <TableRow
                            key={row.productId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.productCode}
                            </TableCell>
                            <TableCell align="right">
                              {row.productName}
                            </TableCell>

                            <TableCell align="right">
                              <IconButton
                                aria-label=""
                                onClick={() => routeToProduct(row.productId)}
                              >
                                <Visibility />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : null}
            </Box>
          </Box>
        </Box>
      ) : null}
      {openModal ? (
        <DeleteModal
          openDeleteModal={openModal}
          setOpenDeleteModal={setOpenModal}
          parameterName="product sub category"
          deleteURL={`${process.env.REACT_APP_BASE_URL}/ProductSubCategory/delete/${deletedSubCatId}`}
          destination={"NotLoad"}
          setIsSuccessful={setDeleteSuccessful}
        />
      ) : null}
    </Fragment>
  );
}

export default DefineProductSubCategory;
