import React,{useEffect,useState} from 'react'
import { getRequest, postRequest } from '../../request';
import { Box } from "@mui/system";
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Delete, Edit, Visibility } from '@mui/icons-material';
import { toast } from 'react-toastify';
import DeleteModal from './deleteModal';
import CityLocation from './cityLocation';

const styles = {
    containerBox: {
      display: "grid",
      gridTemplateColumns: "2fr 2fr 2fr 2fr",
      gap: "2%",
      alignItems: "center",
      justifyContent: "space-around",
      height: "81vh",
      margin: "0rem 5rem",
    },
    box: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      display: "flex",
      width:'26rem',
      flexDirection: "column",
    },
    box1: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "76vh",
    //   width: "40rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width:'26rem',
      margin:'2rem 1rem'
    },
    innerBox: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "auto auto",
      justifyContent: "space-around",
      marginBottom:'0.5rem'
    },
  };

function StateLocations({selectedCountry,stateList,setStateList, selectedState,setSelectedState,cityList, setCityList}) {
    const [deletedstateId, setDeletedstateId] = useState(-1);
    const [Edited, setEdited] = useState(-1);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteSuccessful, setDeleteSuccessful] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const code = (Math.random() * 1000000).toFixed(0);
    const [state, setstate] = useState({
        stateCode: `state-${code}`,
        stateName: "",
        stateLocalName: ""
    });
    // const fetchstate=async()=>{
    //     const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/state`);
    //     if(data && Object.keys(data).length){
    //         setstateList(data);
    //     }
    // }
    useEffect(() => {
      if(deleteSuccessful){
        setStateList(prevstate=>[...prevstate.filter(x=>x.stateId !== deletedstateId)])
        setDeleteSuccessful(false);
        setSelectedState(-1);
      }
    }, [deleteSuccessful])
    const handleChangeProducer = (event) => {
        setstate({
          ...state,
          [event.target.name]: event.target.value,
        });
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleEditProducer=async(id)=>{
      const currentState= {
        ...state,
        stateId:Edited,
      }
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Continent/edit`,currentState)
      if(message ==='ok'){
        stateList[
          stateList.findIndex(
            (x) => x.stateId === Edited
          )
        ] = state;
        setStateList(stateList);
        setSelectedState(-1);
        setstate({
          stateCode: `state-${code}`,
          stateName: "",
          stateLocalName: "",
        });
        toast.success('State edited successfully!');
      }
    }
    const handleAddstate=async()=>{
      const tempData = {...state,countryId:selectedCountry}
      const {message}= await postRequest(`${process.env.REACT_APP_BASE_URL}/State`,tempData);
        if(message === 'ok'){
            toast.success('State Added Successfully!');
            setStateList((prevState)=>[...prevState,state ]);
            setstate({
              stateCode: `state-${code}`,
              stateName: "",
              stateLocalName: ""
          })
        }
    }
    const handleRemovestate = async(id) => {
      setOpenModal(true);
      setDeletedstateId(id);        
    };
    const handleShowState=async(id)=>{
      setSelectedState(id);
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/State/cities/${id}`);
      if(data && Object.keys(data).length){
        setCityList(data);
      }else{
        setCityList([])
      }
    }
  return (
    <div>
        <Box sx={styles.box1}>
            <Typography
              sx={{
                marginRight: "20rem",
                marginTop: "-1.5rem",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              state
            </Typography>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="Code"
                name="stateCode"
                value={state.stateCode}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
              <TextField
                required
                size="small"
                variant="outlined"
                label="Name"
                name="stateName"
                value={state.stateName}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="localName"
                name="stateLocalName"
                sx={{width:'170px'}}
                value={state.stateLocalName}
                onChange={handleChangeProducer}
              />
              <Box sx={{width:'170px'}}></Box>
            </div>
            {Edited !==-1?(
              <div style={styles.innerBox}>
              <Box sx={{ minWidth: 211 }}>
              <ButtonGroup variant="contained">
                <Button color="danger" sx={{ color: "#efefef" }} 
                  onClick={()=>{
                      setstate({
                          stateCode: "",
                          stateName: "",
                          stateLocalName: ""
                      });
                      setEdited(-1);
                  }}
                >
                  cancel <Close />
                </Button>
                <Button
                  color="edit"
                  sx={{ color: "#efefef" }}
                  onClick={handleEditProducer}
                >
                  edit <Edit />
                </Button>
              </ButtonGroup>
              </Box>
              <Box sx={{ minWidth: 211 }}></Box>

            </div>
            ):(
              <div style={styles.innerBox}>
                <Box sx={{ minWidth: 170 }}>
                  <ButtonGroup variant="contained">
                    <Button
                      color="primary"
                      sx={{ color: "#efefef" }}
                      onClick={handleAddstate}
                      disabled={
                        !state.stateCode ||
                        !state.stateLocalName ||
                        !state.stateName
                      }
                    >
                      add <Add />
                    </Button>
                  </ButtonGroup>
                </Box>
                <Box sx={{ minWidth: 170 }}></Box>
              </div>
            )}
          {stateList.length?
            <TableContainer
              sx={{width:'96%'}}
              component={Paper}
            >
              <Table aria-label="simple table"  sx={{maxHeight:"50rem",}}>
                <TableHead>
                  <TableRow>
                    <TableCell>state Code</TableCell>
                    <TableCell>state Name</TableCell>
                    <TableCell align="right">Edit</TableCell>
                    <TableCell align="right">Remove</TableCell>
                    <TableCell align="right">City</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stateList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.stateCode}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.stateName}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="edit"
                          onClick={()=>{
                            setEdited(row.stateId);
                            setstate(stateList.find(
                              (x) =>
                                x.stateId ===
                                row.stateId
                            ))
                          }
                          }
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                            color='danger'
                            onClick={()=>
                                handleRemovestate(row.stateId)
                            }
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={()=>
                            handleShowState(row.stateId)
                          }
                        >
                          <Visibility color={selectedState === row.stateId?'primary':'inherit'}/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {stateList.length>5?
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={stateList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />:null}
            </TableContainer>
          :null}
          </Box>
          {openModal?
          <DeleteModal openDeleteModal={openModal} setOpenDeleteModal={setOpenModal} parameterName="state" deleteURL={`${process.env.REACT_APP_BASE_URL}/state/delete/${deletedstateId}`} destination={'NotLoad'} setIsSuccessful={setDeleteSuccessful}/>
            :null}
    </div>
  )
}

export default StateLocations