import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {Header12} from '../layout/header12'
import Footer from '../layout/footer5'
import ProductGallerySlider from '../element/productGallerySlider';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Fragment } from 'react';
import { useState } from 'react';
import jwtDecode from 'jwt-decode';
import { getRequest } from '../../request';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Loading from "../components/loading";
import { Box, Button, ButtonGroup, Fade, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { AddShoppingCart, Close, Download, Edit } from '@mui/icons-material';
import ProductSubCatSlider from '../components/productSubCatSlider';
import { useTheme } from '@emotion/react';
import axios from 'axios';
// import 'react-tabs/style/react-tabs.css';

const bg = require("./../../images/banner/bnr5.jpg");
const style={
    modalStyle :{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      p: 4,
      bgcolor: 'background.paper',
    },
    // imgModal:{
    //     display:'flex',
    //     justifyContent:'center',
    //     alignItems:'center'
    // }
  }
function ShowProduct(){
  const history = useHistory();
  const theme = useTheme();
  const params = useParams();
  const [imageList, setimageList] = useState([]);
  const [product, setProduct] = useState({});
  const [applicationId, setApplicationId] = useState(0);
  const [specs, setSpecs] = useState([]);
  const [valueData, setValueData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productProducers, setProductProducers] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImg, setSelectedImg] = useState('');
  const token = localStorage.getItem("token");
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }

  const fetchData = async () => {
    const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/${params.productId}`)
      if(data && Object.keys(data).length){
        setProduct(data);
        setTimeout(() => {
            setIsLoading(true);
            getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSubCategory/products/${data.productSubCategoryId}`).then(relatedRes=>{
                if(relatedRes.data && Object.keys(relatedRes.data).length){
                    setTimeout(() => {
                        if(relatedRes.data.length>10){
                            setRelatedProducts(relatedRes.data.slice(0,10));
                        }else{
                            setRelatedProducts(relatedRes.data); 
                        }
                    }, 4000);
                }else{
                    toast.error(relatedRes.error);
                }
            })
        }, 1500);
      }else{
        toast.error(error);
      }
  };
  const fetchSpecs = async () => {
    const unitList = [];
    const response = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/specs/${params.productId}`)
      if(response.data && Object.keys(response.data).length){
      setSpecs(response.data);
      setApplicationId(response.data.filter(x=>x.productSpecName === 'application' || x.productSpecName === 'applications')[0]?.productSpecId);
      const res = [];
      for (let i = 0; i < response.data.length; i++) {
        res[i] = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec/values/${response.data[i].productSpecId}`)
          res[i].data.map((x) => {
            unitList.push(x);
          });
      }
      }else{
        toast.error(response.error);
      }
    setValueData(unitList);
    setimageList(unitList.filter((x) => x.unit.unitName === "image"));
  };
  const fetchProductsProducers = async () => {
    const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/producers/${params.productId}`)
      if(data && Object.keys(data).length){
        setProductProducers(data);
      }else{
        toast.error(error);
      }
    
  };
  const handleCloseModal=()=>{
    setOpenModal(false);
  }

  const handleDownload=()=>{
    axios({
        url:selectedImg,
        method:'GET',
        responseType:'blob'
    }).then(response=>{
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href=url;
        link.setAttribute('download','image.jpg');
        document.body.appendChild(link);
        link.click();
    })
  }

  useEffect(() => {
    fetchData();
    fetchSpecs();
    fetchProductsProducers();
  }, []);

    return (
        <Fragment>
        <Header12/>
            <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="container">
                <div className="dlab-bnr-inr-entry">
                    <h1 className="text-white">Product</h1>
                    <div className="breadcrumb-row">
                        <ul className="list-inline">
                            <li><Link to="/">Home</Link></li>
                            <li>Product</li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
            <div className="page-content bg-white">
                {isLoading?
                <div className="section-full content-inner bg-white" style={{paddingTop:'5rem'}}>
                    <div className="container woo-entry">
                        <div className="row m-b30">
                            <div className="col-md-5 col-lg-5 col-sm-12 m-b30">
                                <ProductGallerySlider imageList={imageList} setSelectedImg={setSelectedImg} setOpenModal={setOpenModal}/>
                            </div>
                            <div className="col-md-7 col-lg-7 col-sm-12" style={{zIndex:0}}>
                                <form method="post" className="cart sticky-top">
                                    <div className="dlab-post-title">
                                        <h4 className="post-title"><Link >{product.productName}</Link></h4>
                                        <p className="m-b10" style={{textAlign:'justify'}}>
                                            {product.productDescription}
                                        </p>
                                        <div className="dlab-divider bg-gray tb15">
                                            <i className="icon-dot c-square"></i>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        {valueData.find((x) => x.unit.unitName === "price")
                                        ?
                                        <div>
                                            <h3 className="m-tb10"> {valueData.find((x) => x.unit.unitName === "price")
                                            .productSpecValueAmount + " $"}</h3>
                                            <div className="dlab-divider bg-gray tb15">
                                                <i className="icon-dot c-square"></i>
                                            </div>
                                        </div>
                                        : null} 
                                        
                                        {/* <div className="shop-item-rating">
                                            <span className="rating-bx"> 
                                                <i className="fa fa-star"></i> 
                                                <i className="fa fa-star"></i> 
                                                <i className="fa fa-star"></i> 
                                                <i className="fa fa-star-o"></i> 
                                                <i className="fa fa-star-o"></i> 
                                            </span>
                                            <span>4.5 Rating</span>
                                        </div> */}
                                    </div>
                                    {/* <div className="shop-item-tage">
                                        <span>Tags :- </span>
                                        <Link to="#">Shoes,</Link>
                                        <Link to="#">Clothing</Link>
                                        <Link to="#">T-shirts</Link>
                                    </div> */}
                                    
                                    <div className="row">
                                        {/* <div className="m-b30 col-md-7 col-sm-8">
                                            <h6>Product Size</h6>
                                            <div className="btn-group product-item-size" data-toggle="buttons">
                                                <label className="btn active">
                                                    <input type="radio" name="options" id="option1" checked/>XS
                                                </label>
                                                <label className="btn">
                                                    <input type="radio" name="options" id="option2"/> LG
                                                </label>
                                                <label className="btn">
                                                    <input type="radio" name="options" id="option3"/> MD
                                                </label>
                                                <label className="btn">
                                                    <input type="radio" name="options" id="option4"/> SM
                                                </label>
                                                <label className="btn">
                                                    <input type="radio" name="options" id="option5"/> Xl
                                                </label>
                                            </div>
                                        </div> */}
                                        {/* <div className="m-b30 col-md-5 col-sm-4">
                                            <h6>Select quantity</h6>
                                            <div className="quantity btn-quantity style-1">
                                                <input id="demo_vertical2" type="text" value="1" className="form-control" name="demo_vertical2"/>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="m-b30">
                                        <h6>Select the color</h6>
                                        <div className="btn-group product-item-color" data-toggle="buttons">
                                            <label className="btn bg-red active">
                                                <input type="radio" name="options" id="option6" checked/>
                                            </label>
                                            <label className="btn bg-pink">
                                                <input type="radio" name="options" id="option7"/>
                                            </label>
                                            <label className="btn bg-yellow">
                                                <input type="radio" name="options" id="option8"/>
                                            </label>
                                            <label className="btn bg-blue">
                                                <input type="radio" name="options" id="option9"/>
                                            </label>
                                            <label className="btn bg-green">
                                                <input type="radio" name="options" id="option10"/>
                                            </label>
                                        </div>
                                    </div> */}
                                    <ButtonGroup>
                                        <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                            history.push(`/registerOrder/:${product.productId}`)
                                        }
                                        >
                                        inquiry
                                        <AddShoppingCart />
                                        </Button>
                                        {decode.role === "SuperAdmin" ? (
                                        <Button
                                            variant="contained"
                                            color='edit'
                                            sx={{color:'#fff'}}
                                            onClick={() =>
                                            history.push(`/productProperties/:${product.productId}`)
                                            }
                                        >
                                            Edit
                                            <Edit />
                                        </Button>
                                        ) : null}
                                    </ButtonGroup>
                                </form>
                            </div>
                        </div>
                        <div className="row m-b30">
                            <div className="col-lg-12">
                                <div className="dlab-tabs  product-description tabs-site-button">
                                    <Tabs>
                                        <TabList className="nav nav-tabs ">
                                            <Tab selectedClassName="tab-active">
                                                <Link style={{width:'20rem'}} >
                                                <i className="fa fa-cog"></i> Product Details
                                                </Link>
                                            </Tab>
                                            <Tab selectedClassName="tab-active">
                                                <Link style={{width:'20rem'}}>
                                                <i className="fa fa-globe"></i> product Producers
                                                </Link>
                                            </Tab>
                                            <Tab selectedClassName="tab-active">
                                                <Link style={{width:'20rem'}}>
                                                <i className="fa fa-photo"></i> Product Applications
                                                </Link>
                                            </Tab>
                                        </TabList>
                                    
                                        <TabPanel className="tab-pane ">
                                            {specs?.filter(
                                                    (x) =>
                                                        x.productSpecName !== "image" &&
                                                        x.productSpecName !== "price" &&
                                                        x.productSpecName.toLowerCase() !== "application" &&
                                                        x.productSpecName.toLowerCase() !== "applications" 
                                                    ).length ? (
                                            <TableContainer
                                            
                                            component={Paper}
                                            >
                                            <Table aria-label="simple table">
                                                <TableHead sx={{backgroundColor:theme.palette.primary.main,}}>
                                                <TableRow>
                                                    <TableCell sx={{color:'#fff'}}>spec</TableCell>
                                                    <TableCell sx={{color:'#fff'}}>amount</TableCell>
                                                    <TableCell sx={{color:'#fff'}}>unit</TableCell>
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {specs
                                                    ?.filter(
                                                    (x) =>
                                                        x.productSpecName !== "image" &&
                                                        x.productSpecName !== "price" &&
                                                        x.productSpecName.toLowerCase() !== "application" &&
                                                        x.productSpecName.toLowerCase() !== "applications" 
                                                    )
                                                    ?.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{
                                                        "&:last-child td, &:last-child th": {
                                                            border: 0,
                                                        },
                                                        }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                        {row.productSpecName}
                                                        </TableCell>
                                                        <TableCell>
                                                        {valueData.length &&
                                                            valueData?.filter(
                                                            (x) =>
                                                                x.productSpecId === row.productSpecId
                                                            )[0]?.productSpecValueAmount}
                                                        </TableCell>
                                                        <TableCell>
                                                        {valueData.length &&
                                                            valueData?.filter(
                                                            (x) =>
                                                                x.productSpecId === row.productSpecId
                                                            )[0]?.unit.unitName}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                            </TableContainer>
                                            ) : null}
                                        </TabPanel>
                                        <TabPanel className="tab-pane">
                                            {productProducers.length?
                                                <TableContainer
                                                
                                                component={Paper}
                                                >
                                                    <Table aria-label="simple table">
                                                        <TableHead sx={{backgroundColor:theme.palette.primary.main,}}>
                                                            <TableRow>
                                                                <TableCell sx={{color:'#fff'}}>Producer Code</TableCell>
                                                                <TableCell sx={{color:'#fff'}}>Producer Name</TableCell>
                                                                <TableCell sx={{color:'#fff'}}>producer Address</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                    {productProducers.map((producer,index) =>
                                                        <TableRow
                                                        key={index}
                                                        sx={{
                                                        "&:last-child td, &:last-child th": {
                                                            border: 0,
                                                        },
                                                        }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                        {producer.producerCode}
                                                        </TableCell>
                                                        <TableCell >
                                                        {producer.producerName}
                                                        </TableCell>
                                                        <TableCell >
                                                        {producer.addressLine1}
                                                        </TableCell>
                                                    </TableRow>)}
                                                    </Table>
                                                </TableContainer>
                                            :null}
                                            {/* <p className="m-b10">Suspendisse et justo. Praesent mattis commyolk augue Aliquam ornare hendrerit augue Cras tellus In pulvinar lectus a est Curabitur eget orci Cras laoreet. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo. Praesent mattis  commyolk augue aliquam ornare augue.</p>
                                            <p>"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences</p>
                                            <ul className="list-check primary">
                                                <li>"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and </li>
                                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </li>
                                            </ul> */}
                                        </TabPanel>
                                        <TabPanel className="tab-pane ">
                                            <ul className="list-check primary">
                                                {valueData.filter(x=>x.productSpecId === applicationId).map((spec,index)=>
                                                    <li key={index}>{spec.productSpecValueAmount}</li>
                                                )}
                                            </ul>
                                            
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                               {relatedProducts.length ? <h5 className="m-b20">Related Products</h5>:null}
                                <ProductSubCatSlider products={relatedProducts}/>
                            </div>
                        </div>
                    </div>
                </div>:
                <Box
                sx={{
                  height: "100vh",
                  width: "100vw",
                  bgcolor: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading type="cylon" color="#000" />
              </Box>
            }
                
                
            </div>  
            <Footer hideContactInfo />      
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                >
                <Fade in={openModal}>
                    <Box sx={style.modalStyle}>
                    <IconButton 
                        color='default' 
                        onClick={()=>setOpenModal(false)} 
                        sx={{position:"absolute",}}>
                        <Close sx={{color:'#c5c5c5',fontSize:'3rem'}}/>
                    </IconButton>
                    {/* <Box sx={style.imgModal}> */}
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            borderRadius:'50%',
                            backgroundColor:'rgb(205 205 205 / 61%)',
                            padding:'1rem',
                            cursor:'pointer'
                        }}
                        href={selectedImg}
                        onClick={handleDownload}
                    >
                        <Tooltip title="download image" placement="top">
                            <Download sx={{color:'#545454',fontSize:'3rem'}}/>
                        </Tooltip>
                    </div>
                        <img 
                        src={selectedImg} 
                        alt=''
                        style={{width:'100%'}} />
                    {/* </Box> */}
                    </Box>
                </Fade>
            </Modal>        
        </Fragment>
    )
        
}
export default ShowProduct;