import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
  } from "@mui/material";
import { Box } from "@mui/system";
import { useMediaQuery } from "react-responsive";
import { useState } from 'react';
import { getRequest } from '../../request';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';



function PriceAtHome({rows}) { 
    const history = useHistory();
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [categoryList, setCategoryList] = useState([]);
    const [page, setPage] = useState(0);
    useEffect(() => {
      setCategoryList(rows.map((x,index)=>({
        id:index,
        type:x[0].type
      })))
    }, [])
    
    const isDesktop = useMediaQuery({
      query: "(min-width: 1224px)"
    });
    const isTablet = useMediaQuery({
        query: "((min-width:650px) and (max-width: 900px))"
    });

    const isMobile = useMediaQuery({
      query: "((min-width:390px) and (max-width: 650px))"
    });
    const isLittleMobile = useMediaQuery({
      query: "(max-width: 390px)"
    });
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const styles={
        paper:{ width: "100%",backgroundColor:'transparent',boxShadow:'none', display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'6rem'},
        tableContainerDesktop:{width:'75rem',borderRadius:'1.5rem 1.5rem 0rem 0rem'},
        headerDesktop:{borderTop:'none',borderRadius: '1.5rem 1.5rem 0rem 0rem',marginBottom:'0px',width:'75rem',height:'10rem',border:'1px solid #fff',borderBottom:'none'},
        tableContainerTablet:{width:'40rem',borderRadius:'1.5rem 1.5rem 0rem 0rem'},
        headerTablet:{borderTop:'none',borderRadius: '1.5rem 1.5rem 0rem 0rem',marginBottom:'0px',width:'40rem'},
        tableContainerPhone:{width:'100%',borderRadius:'1.5rem 1.5rem 0rem 0rem'},
        headerPhone:{borderTop:'none',borderRadius: '1.5rem 1.5rem 0rem 0rem',marginBottom:'0px',width:'100%'},
        tableHeaderTitle:{width:'fit-content',bgcolor:'#7fa5e2',padding:'0.7rem 6rem',borderRadius:'1.5rem 1.5rem 0rem 0rem'},
        tableHeader:{display:'flex',justifyContent:'center',width:'100%',alignItems:'center'},
        tableBody:{maxHeight:'440px',overflowY: "scroll"},
        tableBodyPhone:{maxHeight:'440px',overflowY: "scroll",width:'24rem'},
        footerDesktop:{borderTop:'none',width:'75rem',bgcolor:'rgba(0, 50, 130, 0.26)', color:'#fff',border:'1px solid #fff'},
        footerTablet:{borderTop:'none',width:'40rem',bgcolor:'rgba(0, 50, 130, 0.26)', color:'#fff',border:'1px solid #fff'},
        footerPhone:{borderTop:'none',width:'100%',bgcolor:'rgba(0, 50, 130, 0.26)', color:'#fff',border:'1px solid #fff'},
        myTitle:{marginBottom: "5px",fontSize: '35px',lineHeight: '45px',fontWeight: '600px',cursor: 'pointer',
            '&:hover':{
                color:'red'
            }
        }
      }
    const routeToProductPage = async(productDetail)=>{
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/ProductByName/${productDetail}`)
        if(data && Object.keys(data).length){
          history.push(`/singleProduct/${data.productId}`)
        }else{
          toast.error('data not found');
        }
    }
    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };
    const handleRightBtn =()=>{
      const category = categoryList.findIndex(x=>x.type === rows[selectedCategory][0]?.type);
      if(category>=categoryList.length-1){
          setSelectedCategory(0);
      }else{
          setSelectedCategory(category+1)
      }
    }
    const handleLeftBtn =()=>{
      const category = categoryList.findIndex(x=>x.type === rows[selectedCategory][0]?.type);
      if(category===0){
          setSelectedCategory(categoryList.length-1);
      }else{
          setSelectedCategory(category-1);
      }
    }
  return (

    <div className={isDesktop?'priceTableBackground':isTablet?'priceTableBackgroundTablet':isMobile?'priceTableBackgroundMobile':'priceTableBackgroundLittleMobile'}>
        <Box>
            <div className="section-head text-center ">
                {/* <p 
                // style={styles.myTitle}
                    onClick={()=>history.push('/prices')}
                    onMouseEnter={(e) => {
                        e.target.style.color = theme.palette.primary.main;
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = '#000';
                    }}
                >Price Tables</p> */}
                <div style={{display:'flex',justifyContent:'center'}}>
                    <h2 style={{marginTop:'5rem',color:'#fff',borderBottom:'4px solid #fff',width:'fit-content',textAlign:'center',cursor:'pointer'}}
                    onClick={()=>history.push('/prices')}
                    onMouseEnter={(e) => {
                        e.target.style.color = '#000';
                        e.target.style.borderBottom = '4px solid #000';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = '#fff';
                        e.target.style.borderBottom = '4px solid #fff';
                    }}>PRICE TABLES</h2>
                </div>
                <p style={{color:'#fff'}}>You Can Visit Our Price Data To Found Our Product Details & Updated Prices</p>
            </div>
            <div style ={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
                <TableContainer sx={isDesktop? styles.tableContainerDesktop:isTablet? styles.tableContainerTablet:styles.tableContainerPhone}>
                {/* <Box sx={styles.tableHeader}><Typography sx={styles.tableHeaderTitle} component='h4'>{console.log(rows[selectedCategory][0]?.type) }</Typography></Box> */}
                <span class={isDesktop?"hex":isTablet?'hexTablet':'hexMobile'}>
                    <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',color:'#fff'}}>
                        <div  style={{backgroundColor:'#0B82C6',marginRight:'0.2rem',cursor:'pointer'}}
                        onClick={handleLeftBtn}>
                        <ChevronLeft/>
                        </div>
                        {rows[selectedCategory][0]?.type}
                        <div style={{backgroundColor:'#0B82C6',marginLeft:'0.2rem',cursor:'pointer'}}
                        onClick={handleRightBtn}>
                            <ChevronRight/>
                        </div>
                    </div>
                </span>
                <Table stickyHeader aria-label="sticky table"  sx={isDesktop? styles.headerDesktop:isTablet? styles.headerTablet:styles.headerPhone}>
                    <TableHead >
                    <TableRow >               
                        <TableCell sx={{width:'10%',fontSize:'1rem',verticalAlign:'bottom !important'}}
                            align='center'
                            className="priceHeaderHome"
                        >
                            Product
                        </TableCell>
                        {isDesktop?
                            <TableCell
                            sx={{
                                width:'10%',
                                fontSize:'1.2rem',
                                verticalAlign:'bottom !important'
                            }}
                                align='center'
                                className="priceHeaderHome"
                            >
                                Type
                            </TableCell>
                        :null}
                        <TableCell
                        sx={{width:'10%',fontSize:'1rem',verticalAlign:'bottom !important'}}
                            align='center'
                            className="priceHeaderHome"
                        >
                            Min Quantity
                        </TableCell>
                        {isDesktop?<TableCell
                        sx={{width:'28%',fontSize:'1rem',verticalAlign:'bottom !important'}}
                            align='center'
                            className="priceHeaderHome"
                        >
                            Packing
                        </TableCell>:null}
                        <TableCell
                        sx={{width:'10%',fontSize:'1rem',verticalAlign:'bottom !important'}}
                            align='center'
                            className="priceHeaderHome"
                        >
                            DeliveryTerm
                        </TableCell>
                        {isDesktop?
                        <TableCell
                        sx={{width:'20%',fontSize:'1rem',verticalAlign:'bottom !important'}}
                            align='center'
                            className="priceHeaderHome"
                        >
                            Market
                        </TableCell>:null}
                        {isDesktop?
                        <TableCell
                        sx={{width:'12%',fontSize:'1rem',verticalAlign:'bottom !important'}}
                            align='center'
                            className="priceHeaderHome"
                        >
                            Price
                        </TableCell>:null}
                    
                    </TableRow>
                    </TableHead>
                </Table>
                    <div style={isMobile?styles.tableBodyPhone:styles.tableBody}>
                        <Table sx={{marginBottom:0}}>
                            <TableBody sx={{borderLeft:'1px solid #fff'}}>
                            {rows[selectedCategory]?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(row => (
                                <TableRow
                                    className="tableBodyHome"
                                    role="checkbox"
                                    key={row.id}
                                    tabIndex={-1}
                                >
                                    
                                    <TableCell align='center' 
                                    sx={{width:'8%' ,cursor:'pointer',textDecoration:'underline',color:'#ddd'}} 
                                    onClick={()=>routeToProductPage(row.product)}>
                                        {row.product}
                                    </TableCell>
                                    {isDesktop?<TableCell align='center' sx={{width:'10%',color:'#fff'}}>{row.type?row.grade:'-'}</TableCell>:null}
                                    <TableCell align='center' sx={{width:'10%',color:'#fff'}}>{row.mInQuantity?row.mInQuantity:'-'}</TableCell>
                                    {isDesktop?<TableCell align='center' sx={{width:'30%',color:'#fff'}}>{row.packing?row.packing:'-'}</TableCell>:null}
                                    <TableCell align='center' sx={{width:'10%',color:'#fff'}}>{row.deliveryTerm?row.deliveryTerm:'-'}</TableCell>
                                    {isDesktop?<TableCell align='center' sx={{width:'20%',color:'#fff'}}>{row.market}</TableCell>:null}
                                    {isDesktop?<TableCell align='center' sx={{width:'12%',color:'#fff'}}>{row.offeredPrice==='0'|| !row.offeredPrice?<span style={{fontSize:'18px'}}>-</span>:Math.round(row.offeredPrice)}</TableCell>:null}
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
                {rows[selectedCategory].length>rowsPerPage?
                <TablePagination
                    sx={isDesktop?styles.footerDesktop:isTablet?styles.footerTablet:styles.footerPhone}
                    rowsPerPageOptions={[5,10,20,40]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />:null}
            </div>
        </Box>
    </div>
  )
}

export default PriceAtHome