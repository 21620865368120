import React from 'react';
import { Link } from 'react-router-dom';
import { Accordion, AccordionItem } from 'react-accessible-accordion';

import ProjectSlider5 from '../element/projectSlider5';
import { Typography } from '@mui/material';
import { useState } from 'react';

const Sidebar = ({blogLists,setSearchWord,keyWords}) => {
    const [searchBox, setSearchBox] = useState('');
    const handleSearch=(e)=>{
        e.preventDefault();
        setSearchWord(searchBox);
    }
    return ( 
        <div className="col-lg-3" style={{zIndex:0}}>
        <aside  className="side-bar sticky-top">
            <div className="widget">
                <h5 className="widget-title style-1">Search</h5>
                <div className="search-bx style-1">
                    <form role="search" method="post" onSubmit={handleSearch}>
                        <div className="input-group">
                            <input name="text" className="form-control" placeholder="Enter your keywords..." value={searchBox} onChange={(e)=>setSearchBox(e.target.value)} type="text"/>
                            <span className="input-group-btn">
                                <button type="submit" className="fa fa-search text-primary"></button>
                            </span> 
                        </div>
                    </form>
                </div>
            </div>
            <div className="widget recent-posts-entry">
                <h5 className="widget-title style-1">Recent Posts</h5>
                <div className="widget-post-bx">
                    {
                        blogLists.slice(0,3).map(x=>
                            <div className="widget-post clearfix" key={x.id}>
                            <div className="dlab-post-media"> 
                                <img src={`${process.env.REACT_APP_IMG_URL}`+x.photo} width="200" height="143" alt=""/> 
                            </div>
                            <div className="dlab-post-info">
                                <div className="dlab-post-meta">
                                    <ul>
                                        <li className="post-date"> <strong>{x.date.split(' ')[2]} {x.date.split(' ')[1]}</strong> <span> {x.date.split(' ')[3]}</span> </li>
                                        <li className="post-author"> By <Link >{x.user}</Link> </li>
                                    </ul>
                                </div>
                                <div className="dlab-post-header">
                                    <h6 className="post-title" ><Link to={`/blog/:${x.id}`} >{x.title}</Link></h6>
                                </div>
                            </div>
                        </div>
                        )
                    }
                    
                </div>
            </div>
            {/* <div className="widget widget-newslatter">
                <h5 className="widget-title style-1">Newsletter</h5>
                <div className="news-box">
                    <p>Enter your e-mail and subscribe to our newsletter.</p>
                    <form className="dzSubscribe" action="script/mailchamp.php" method="post">
                        <div className="dzSubscribeMsg"></div>
                        <div className="input-group">
                            <input name="dzEmail" required="required" type="email" className="form-control" placeholder="Your Email"/>
                            <button name="submit" value="Submit" type="submit" className="site-button btn-block radius-no">Subscribe Now</button>
                        </div>
                    </form>
                </div>
            </div> */}
            {/* <div className="widget widget_gallery gallery-grid-4">
                <h5 className="widget-title style-1">Our Gallery</h5>
                <ul id="lightgallery" className="lightgallery">
                    <li>
                        <div className="dlab-post-thum dlab-img-effect">
                            <span data-exthumbimage="images/gallery/pic1.jpg" data-src="images/gallery/pic1.jpg" className="check-km" title="Image 1 Title will come here">		
                                <img src={require("../../images/gallery/pic1.jpg")} alt=""/> 
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className="dlab-post-thum dlab-img-effect">
                            <span data-exthumbimage="images/gallery/pic2.jpg" data-src="images/gallery/pic2.jpg" className="check-km" title="Image 2 Title will come here">		
                                <img src={require("../../images/gallery/pic2.jpg")} alt=""/> 
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className="dlab-post-thum dlab-img-effect">
                            <span data-exthumbimage="images/gallery/pic3.jpg" data-src="images/gallery/pic3.jpg" className="check-km" title="Image 3 Title will come here">		
                                <img src={require("../../images/gallery/pic3.jpg")} alt=""/> 
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className="dlab-post-thum dlab-img-effect">
                            <span data-exthumbimage="images/gallery/pic4.jpg" data-src="images/gallery/pic4.jpg" className="check-km" title="Image 4 Title will come here">		
                                <img src={require("../../images/gallery/pic4.jpg")} alt=""/> 
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className="dlab-post-thum dlab-img-effect">
                            <span data-exthumbimage="images/gallery/pic5.jpg" data-src="images/gallery/pic5.jpg" className="check-km" title="Image 5 Title will come here">		
                                <img src={require("../../images/gallery/pic5.jpg")} alt=""/> 
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className="dlab-post-thum dlab-img-effect">
                            <span data-exthumbimage="images/gallery/pic6.jpg" data-src="images/gallery/pic6.jpg" className="check-km" title="Image 6 Title will come here">		
                                <img src={require("../../images/gallery/pic6.jpg")} alt=""/> 
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className="dlab-post-thum dlab-img-effect">
                            <span data-exthumbimage="images/gallery/pic7.jpg" data-src="images/gallery/pic7.jpg" className="check-km" title="Image 7 Title will come here">		
                                <img src={require("../../images/gallery/pic7.jpg")} alt=""/> 
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className="dlab-post-thum dlab-img-effect">
                            <span data-exthumbimage="images/gallery/pic8.jpg" data-src="images/gallery/pic8.jpg" className="check-km" title="Image 8 Title will come here">		
                                <img src={require("../../images/gallery/pic8.jpg")} alt=""/> 
                            </span>
                        </div>
                    </li>
                </ul>
            </div> */}
            {/* <div className="widget widget_archive">
                <h5 className="widget-title style-1">Categories List</h5>
                <ul>
                    <li><Link to={"#"}>Electronic Materials</Link></li>
					<li><Link to={"#"}>Auto Parts</Link></li>
					<li><Link to={"#"}>Building Management</Link></li>
					<li><Link to={"#"}>Power Systems</Link></li>
					<li><Link to={"#"}>Power & Energy</Link></li>
                </ul>
            </div>
            <div className="widget widget-project">
                <h5 className="widget-title style-1">Our Project</h5>
                <ProjectSlider5 slideToShow={1}/>
            </div> */}
            <div className="widget widget_tag_cloud radius">
                <h5 className="widget-title style-1">Tags</h5>
                <div className="tagcloud"> 
                {keyWords.length? keyWords.map(keys=>
                    <Link >{keys}</Link> 
                    ):<Typography variant='h6' sx={{fontWeight:'bold'}}>NO TAG FOUND!</Typography>
                }
                    
                </div>
            </div>
        </aside>
    </div>
    );
}
 
export default Sidebar;