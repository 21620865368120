import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React,{useState} from "react";
import { toast } from "react-toastify";
import { getRequest } from "../../request";
import {useHistory} from 'react-router-dom'; 
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "react-responsive";

function StyledTable({ rows }) {
  const history = useHistory();
  const theme = useTheme(); 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)"
  });

  const isTablet = useMediaQuery({
    query: "((min-width:650px) and (max-width: 900px))"
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 400px)"
  });


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const styles={
    paper:{ width: "100%",backgroundColor:'transparent',boxShadow:'none', display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'11rem'},
    tableContainerDesktop:{width:'75rem',borderRadius:'1.5rem 1.5rem 0rem 0rem'},
    headerDesktop:{borderTop:'none',borderRadius: '1.5rem 1.5rem 0rem 0rem',marginBottom:'0px',width:'75rem'},
    tableContainerTablet:{width:'40rem',borderRadius:'1.5rem 1.5rem 0rem 0rem'},
    headerTablet:{borderTop:'none',borderRadius: '1.5rem 1.5rem 0rem 0rem',marginBottom:'0px',width:'40rem'},
    tableContainerPhone:{width:'25rem',borderRadius:'1.5rem 1.5rem 0rem 0rem'},
    headerPhone:{borderTop:'none',borderRadius: '1.5rem 1.5rem 0rem 0rem',marginBottom:'0px',width:'25rem'},
    tableHeaderTitle:{width:'fit-content',bgcolor:'#7fa5e2',padding:'0.7rem 2rem',borderRadius:'1.5rem 1.5rem 0rem 0rem'},
    tableHeader:{display:'flex',justifyContent:'center',width:'100%',alignItems:'center'},
    tableBody:{maxHeight:'440px',overflowY: "scroll"},
    tableBodyPhone:{maxHeight:'440px',overflowY: "scroll",width:'36rem'}
  }

  const routeToProductPage = async(productDetail)=>{
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/ProductByName/${productDetail}`)
      if(data && Object.keys(data).length){
        history.push(`/singleProduct/${data.productId}`)
      }else{
        toast.error('data not found');
      }
  }
  
  return (
    <Paper sx={styles.paper}>
      <Box >
        <TableContainer sx={isDesktop? styles.tableContainerDesktop:isMobile? styles.tableContainerPhone:styles.tableContainerTablet}>
        <Box sx={styles.tableHeader}><Typography sx={styles.tableHeaderTitle} component='h4'>{rows[0]?.type}</Typography></Box>
        
          <Table stickyHeader aria-label="sticky table"  sx={isDesktop? styles.headerDesktop:isMobile? styles.headerPhone:styles.headerTablet}>
            <TableHead >
              <TableRow >               
                  <TableCell sx={{width:'8%'}}
                    align='center'
                    className="priceHeader"
                  >
                    Product
                  </TableCell>
                  <TableCell
                  sx={{width:'10%'}}
                    align='center'
                    className="priceHeader"
                  >
                    Type
                  </TableCell>
                  <TableCell
                  sx={{width:'10%'}}
                    align='center'
                    className="priceHeader"
                  >
                    Min Quantity
                  </TableCell>
                  <TableCell
                  sx={{width:'30%'}}
                    align='center'
                    className="priceHeader"
                  >
                    Packing
                  </TableCell>
                  <TableCell
                  sx={{width:'10%'}}
                    align='center'
                    className="priceHeader"
                  >
                    DeliveryTerm
                  </TableCell>
                  <TableCell
                  sx={{width:'20%'}}
                    align='center'
                    className="priceHeader"
                  >
                    Market
                  </TableCell>
                  <TableCell
                  sx={{width:'12%'}}
                    align='center'
                    className="priceHeader"
                  >
                    Price
                  </TableCell>
                  
              </TableRow>
            </TableHead>
          </Table>
            <div style={isMobile?styles.tableBodyPhone:styles.tableBody}>
              <Table sx={{marginBottom:0}}>
                <TableBody >
                  {rows
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row,index) => (
                      <TableRow
                      className="tableBody"
                        role="checkbox"
                        key={index}
                        tabIndex={-1}
                      >
                        <TableCell align='center' sx={{width:'8%', cursor:'pointer',textDecoration:'underline',color:theme.palette.primary.main}} onClick={()=>routeToProductPage(row.product)}>{row.product}</TableCell>
                        <TableCell align='center' sx={{width:'10%'}}>{row.type?row.grade:'-'}</TableCell>
                        <TableCell align='center' sx={{width:'10%'}}>{row.mInQuantity?row.mInQuantity:'-'}</TableCell>
                        <TableCell align='center' sx={{width:'30%'}}>{row.packing?row.packing:'-'}</TableCell>
                        <TableCell align='center' sx={{width:'10%'}}>{row.deliveryTerm?row.deliveryTerm:'-'}</TableCell>
                        <TableCell align='center' sx={{width:'20%'}}>{row.market}</TableCell>
                        <TableCell align='center' sx={{width:'12%'}}>{row.offeredPrice==='0'|| !row.offeredPrice?<span style={{fontSize:'18px'}}>-</span>:Math.round(row.offeredPrice)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

            </div>
          
          
        </TableContainer>
        {rows.length>rowsPerPage?<TablePagination
          sx={{border:'2px solid #efefef',borderTop:'none',bgcolor:'#fff'}}
          rowsPerPageOptions={[5,10,20,40]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />:null}
      </Box>
    </Paper>
  );
}

export default StyledTable;
