import React, { useState } from "react";
import "../../css/login.css";
import { Home, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Fab,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { postRequest } from "../../request";
import { useMediaQuery } from "react-responsive";

function Login() {
  const [signInValues, setSignInValues] = useState({
    userName: "",
    password: "",
    showPassword: false,
  });
  const [signUpValues, setSignUpValues] = useState({
    userName: "",
    email: "",
    password: "",
    showPassword: false,
  });
  const [isSignIn, setIsSignIn] = useState(false);
  const container = document.getElementsByClassName("container1");
  const history = useHistory();
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)"
  });

  const isTablet = useMediaQuery({
    query: "((min-width:650px) and (max-width: 900px))"
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 400px)"
  });
  const token = localStorage.getItem("token");
  const handleChangeSignIn = (prop) => (event) => {
    setSignInValues({ ...signInValues, [prop]: event.target.value });
  };
  const handleChangeSignUp = (prop) => (event) => {
    setSignUpValues({ ...signUpValues, [prop]: event.target.value });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/User/login`,{ username: signInValues.userName, password: signInValues.password })
      if(message==='ok'){
        localStorage.setItem("token", data.token);
        history.push("/");
      }
    } catch (e) {
      toast.error("incorrect username or password");
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      debugger
      const passwordValidation = signUpValues.password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/);
      const emailValidation = signUpValues.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      if(passwordValidation){
        if(emailValidation){
          if(signUpValues.userName){
            const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/User/register`,{
              username: signUpValues.userName,
              email: signUpValues.email,
              password: signUpValues.password,
            })
            if(message === 'ok'){
              toast.success("user registered successfully");
            }
          }else{
            toast.error('user name could not be empty!');
          }
        }else{
          toast.error('incorrect email format!');
        }
      }else{
        toast.error('password must be more than 8 digits & include numbers, characters and special Characters!');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="body">
      <div className="container1 right-panel-active">
        <div className="container__form container--signup">
          <form className={isSignIn ? "form" : "myform"} id="form1">
            <Fab
              size="large"
              color="secondary"
              sx={isDesktop?{
                position: "relative",
                right: "-14rem",
                top: "-10rem",
              }:isTablet?{
                position: "absolute",
                right: "9.5rem",
                top: "0.2rem",
              }:{
                position: "absolute",
                right: "0.5rem",
                top: "0.2rem",
              }}
              onClick={() => history.push("/")}
            >
              <Home color="primary" fontSize="large" />
            </Fab>
            <h2 className="form__title">Sign In</h2>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="8rem"
            >
              <FormControl sx={isDesktop?{ m: 1, width: "35ch" }:{ m: 1, width: "17ch" }} variant="outlined">
                <TextField
                  label="user name"
                  id="userName"
                  variant="outlined"
                  size={isDesktop?'medium':'small'}
                  onChange={handleChangeSignIn("userName")}
                  value={signInValues.userName}
                ></TextField>
              </FormControl>
              <FormControl sx={isDesktop?{ m: 1, width: "35ch" }:{ m: 1, width: "17ch" }} variant="outlined">
                <InputLabel htmlFor="signIn-password">Password</InputLabel>
                <OutlinedInput
                  id="signIn-password"
                  type={signInValues.showPassword ? "text" : "password"}
                  value={signInValues.password}
                  onChange={handleChangeSignIn("password")}
                  size={isDesktop?'medium':'small'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setSignInValues({
                            ...signInValues,
                            showPassword: !signInValues.showPassword,
                          })
                        }
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {signInValues.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Box>
            <a className="link">
              Forgot your password?
            </a>
            <button className="btn" onClick={handleSignIn}>
              Sign In
            </button>
          </form>
        </div>

        <div className="container__form container--signin">
          <form action="#" className="form" id="form2">
            <Fab
              size="large"
              color="secondary"
              sx={{
                position: "relative",
                top: "-8.5rem",
                left: "-14rem",
              }}
              onClick={() => history.push("/")}
            >
              <Home color="primary" fontSize="large" />
            </Fab>
            <h2 className="form__title">Sign Up</h2>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "13rem",
              }}
            >
              <FormControl sx={isDesktop?{ m: 1, width: "35ch" }:{ m: 1, width: "17ch" }} variant="outlined">
                <TextField
                  label="user name"
                  id="userName"
                  variant="outlined"
                  size={isDesktop?'medium':'small'}
                  onChange={handleChangeSignUp("userName")}
                  value={signUpValues.userName}
                ></TextField>
              </FormControl>
              <FormControl sx={isDesktop?{ m: 1, width: "35ch" }:{ m: 1, width: "17ch" }} variant="outlined">
                <TextField
                  label="email"
                  type="email"
                  id="email"
                  size={isDesktop?'medium':'small'}
                  variant="outlined"
                  onChange={handleChangeSignUp("email")}
                  value={signUpValues.email}
                ></TextField>
              </FormControl>
              <FormControl sx={isDesktop?{ m: 1, width: "35ch" }:{ m: 1, width: "17ch" }} variant="outlined">
                <InputLabel htmlFor="signUp-password">Password</InputLabel>
                <OutlinedInput
                  id="signUp-password"
                  type={signUpValues.showPassword ? "text" : "password"}
                  value={signUpValues.password}
                  size={isDesktop?'medium':'small'}
                  onChange={handleChangeSignUp("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setSignUpValues({
                            ...signUpValues,
                            showPassword: !signUpValues.showPassword,
                          })
                        }
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {signUpValues.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Box>
            <button className="btn" onClick={handleSignUp}>
              Sign Up
            </button>
          </form>
        </div>

        <div
          className={
            isSignIn ? "container__overlay_signin" : "container__overlay_signup"
          }
        >
          <div className="overlay">
            <div className="overlay__panel overlay--left">
              <button
                className="btn"
                id="signIn"
                onClick={() => {
                  setIsSignIn(!isSignIn);
                  container[0].classList.remove("right-panel-active");
                }}
              >
                Sign Up
              </button>
            </div>
            <div className="overlay__panel overlay--right">
              <button
                className="btn"
                id="signUp"
                onClick={() => {
                  setIsSignIn(!isSignIn);
                  container[0].classList.add("right-panel-active");
                }}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
