import { Add,  Delete, } from '@mui/icons-material';
import { TextField, Typography, ButtonGroup, Button } from '@mui/material'
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { getRequest, postRequest } from '../../request';
const style={
  modalBody:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    height:'14rem'
  }
}

function AddProducerGroupComponent({childSelected}) {
    const [group, setGroup] = useState({
        producerGroupCode:'',
        producerGroupName:'',
        producerGroupLocalName:''
    });
    const handleChange = (event) => {
        setGroup({
          ...group,
          [event.target.name]: event.target.value,
        });
      };
      const handleAddGroup=async()=>{
        const tempData = {
            ...group,
            producerGroupParentId: childSelected.id?childSelected.id:0,
        };
        const {message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProducerGroup`,tempData);
        if(message==='ok'){
            toast.success('group added successfully!');
            window.location.reload();
        }
      }

      const handleRemoveGroup=async()=>{
        const {error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProducerGroup/delete/${childSelected.id}`);
        if(error){
            toast.error(error);
        }else{
            toast.success('group deleted successfully!');
            window.location.reload();
        }
      }
  return (
    <div>
        <Box sx={{display:'flex',justifyContent:'space-between'}}>
        <Typography variant='h5'>Add Group</Typography>
        <Typography variant="body1" >group:{childSelected.name?childSelected.name:'root'}</Typography>
        </Box>
        <Box sx={style.modalBody}>
          <TextField variant='outlined' label='groupCode'  name='producerGroupCode' size='small' value={group.producerGroupCode} onChange={handleChange}/>
          <TextField variant='outlined' label='groupName' name='producerGroupName' size='small' value={group.producerGroupName} onChange={handleChange}/>
          <TextField variant='outlined' label='groupLocalName' name='producerGroupLocalName' size='small' value={group.producerGroupLocalName} onChange={handleChange}/>
          <ButtonGroup variant="contained" sx={{width:'fit-content'}}>
            <Button variant='contained' color='primary' sx={{color:'#fff'}} onClick={handleAddGroup}>Add<Add/></Button>
            {childSelected.id?
              <Button variant='contained' color='danger' sx={{color:'#fff'}} onClick={handleRemoveGroup}>Remove<Delete/></Button>
            :null}
          </ButtonGroup>
        </Box>
    </div>
  )
}

export default AddProducerGroupComponent