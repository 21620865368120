import React, { useState, useEffect } from "react";
import {Header12} from "../layout/header12";
import Footer from "../layout/footer5";
import { Link, useHistory, useParams } from "react-router-dom";
import { Stack } from "@mui/system";
import { Autocomplete, Box, Pagination, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem,  } from "@mui/material";
import { Add, Search } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
import Loading from "../components/loading";
import {getRequest} from '../../request';
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive"; 
import { useTheme } from "@emotion/react";

const bnr = require("./../../images/banner/bnr5.jpg");
const NoProductImg = require("../../images/our-work/chemicals/No_Image_Available.jpg");
// const productModal = require('../../images/our-work/chemicals/productImageModel.png');

function AllProducts() {
  const params = useParams();
  const theme = useTheme();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const history = useHistory();
  const [productsData, setProductsData] = useState([]);
  const [page, setPage] = useState(1);
  const [filteredItem, setFilteredItem] = useState({name:''});
  const [pageCount, setPageCount] = useState(0);
  const token = localStorage.getItem("token");
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)"
  });

  const isTablet = useMediaQuery({
    query:"((min-width:650px) and (max-width: 900px))"
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 400px)"
  });
  const fetchProducts = async(pPage) => {
    setProductsData([]);
    const response = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product`);
      response.data.sort((a,b)=>a.productSubCategoryId - b.productSubCategoryId).map((x) => {
        setProductsData((prevstate) => [
          ...prevstate,
          {
            id: x.productId,
            name: x.productLocalName,
            description: x.productDescription,
            subCategory: x.subCategory.productSubCategoryName,
            industry: x.industry.industryName,
            productSubCategoryName:x.subCategory.productSubCategoryName,
            image: x.images[0] ? `${process.env.REACT_APP_IMG_URL}`+x.images[0].replaceAll('\\','/') : NoProductImg,
          },
        ]);
        setPageCount(Math.floor((response.data.length - 1) / 12) + 1);
      });
  };
  const handleFilterProducts=(value)=>{
    setFilteredItem({name: value });
    if(value === null){
      setProductsData([]);
      fetchProducts(page);
    }else{
      setProductsData(productsData.filter(x=>x.name === value));
    }
    // setFilteredItem({name:''});
  }
  useEffect(() => {    
    const pageNumber = params.page.split('=')[1];
    setPage(+pageNumber);
    fetchProducts(+pageNumber);
  }, [params]);
  
  useEffect(() => {
    fetchSubCategories();
  }, []);

  const fetchSubCategories =async()=>{
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSubCategory`);
    if(data && Object.keys(data).length){
      setSubCategoryList(data);
    }else{
      toast.error(error);
    }
  }

  const handleClearFilter=()=>{
    history.push('/products/page=1');
    setSelectedCategory('');
  }
  return (
    <div>
      <Header12 />
      <div className="page-content bg-white">
        {productsData.length ? (
          <div>
            <div
              className="dlab-bnr-inr overlay-black-middle bg-pt"
              style={{ backgroundImage: "url(" + bnr + ")" }}
            >
              <div className="container">
                <div className="dlab-bnr-inr-entry">
                  <h1 className="text-white">All Products</h1>
                  <div className="breadcrumb-row">
                    <ul className="list-inline">
                      <li>
                        <Link to={"/"}>Home</Link>
                      </li>
                      <li>All Products</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-full  bg-gray">
              <div className="container">
                <Box sx={{display:'flex',justifyContent:'center',padding:'1rem 0rem'}}>
                  <Box sx={isDesktop?{display:'flex',flexDirection:'column', justifyContent:'center', backgroundColor:'#ebebeb',width:'600px',boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'}:
                      {display:'flex',flexDirection:'column', justifyContent:'space-between',height:'13rem', backgroundColor:'#ebebeb',width:'600px',boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'}}>
                    <Box sx={{display:'flex',justifyContent:'center'}}>
                    <Typography variant="h6" sx={{color:'#6a6a6a',borderBottom:`2px solid ${theme.palette.primary.main}` }}><Search/> Search</Typography>
                      
                    </Box>
                    
                    <Box sx={isDesktop?{display:'flex',flexDirection:'row', justifyContent:'space-around',height:'4rem', width:'100%',alignItems:'center'}:
                              {display:'flex',flexDirection:'column', justifyContent:'space-around',height:'7rem', width:'100%',alignItems:'center'}}>
                        <Autocomplete
                          title="Search Product"
                          size="small"
                          disablePortal
                          value={filteredItem.name}
                          // getOptionSelected={(option, value) => option.name === value.name}
                          onChange={(e,value) =>
                            handleFilterProducts(value)
                          }
                          options={productsData.map((x) => x.name)}
                          sx={{ width:'17rem' }}
                          renderInput={(params) => (
                            <TextField {...params} label={`Search Product...`}  />
                          )}
                        />
                        <Box  sx={{ width:'17rem',display:'flex' }}>
                          <FormControl size="small" sx={{width:'17rem',display:'flex'}}>
                            <InputLabel>Category</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              value={selectedCategory}
                              label="Age"
                              onChange={(e)=> {
                                setSelectedCategory(e.target.value);
                                history.push(`/product/ProductSubCategory=${e.target.value}&&page=1`)}
                              }
                            >
                              {subCategoryList.map((x,index)=>
                                <MenuItem key={index} value={x.productSubCategoryId}>
                                  {x.productSubCategoryName}
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                          {selectedCategory?
                            <Button size="small" sx={{height :'2.5rem'}} variant="contained" color="secondary" onClick={handleClearFilter}>
                              clearFilter
                            </Button>
                          :null}
                        </Box>
                    </Box>
                    <Box sx={{display:'flex',justifyContent:'center'}}>
                      {decode.role === "SuperAdmin" ? (
                      <Button
                        variant="contained" color="primary" size="small"
                        style={{ marginBottom: "1rem",height:'3rem',width:'17rem' }}
                        onClick={()=>history.push(`/productProperties/0`) }
                      >
                        New Product
                        <Add />
                      </Button>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
                <div className="row">
                  {productsData.slice(12*(page-1),12*(page-1)+12)
                    .map((product, index) => (
                      <div
                        key={index}
                        className="col-lg-3 col-md-6 col-sm-6 m-b30 wow fadeInUp"
                        data-wow-duration="2s"
                        data-wow-delay="0.3s"
                      >
                        <div className="dlab-box service-box-2">
                          <div className="dlab-media radius-sm dlab-img-overlay1 dlab-img-effect zoom">
                            <Link>
                              {/* <img
                                src={product.image}
                                alt=""
                                style={{ height: "300px" }}
                              /> */}
                              <div style={{backgroundImage:`url(${product.image})`,backgroundSize:'cover',height:'32vh'}}>

                              </div>
                              {/* <LazyLoadImage
                                src={productModal}
                                alt=""
                                style={{ 
                                  height: "220px",
                                  bottom: 0,
                                  position: 'absolute' 
                                }}
                              /> */}
                            </Link>
                          </div>
                          <div className="dlab-info">
                            <h4 className="title" 
                              style={{
                                width: '200px',
                                padding: 0,
                                overflow: 'hidden',
                                position: 'relative',
                                display: 'inlineBlock',
                                margin: '0 5px 0 5px',
                                // textAlign: 'center',
                                textDecoration: 'none',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: '#000',
                                }}>
                              <Link to={`/singleProduct/${product.id}`} 
                              >
                                {product.name}
                              </Link>
                            </h4>
                            <Typography variant="caption" sx={{backgroundColor:'#bdf3e7',padding:'0.2rem',borderRadius:'3px', color:'#349d9c'}}>{product.productSubCategoryName}</Typography>
                            {product.description ? (
                              <p
                                style={{
                                  height: "6.6rem",
                                  overflowY: "hidden",
                                }}
                              >
                                {product.description?.slice(0, 100)}
                                <b>{"..."}</b>
                              </p>
                            ) : (
                              <p
                                style={{
                                  height: "6.6rem",
                                  overflowY: "hidden",
                                }}
                              >
                                <b>No Description Found!</b>
                              </p>
                            )}
                            <Link
                              to={`/singleProduct/${product.id}`}
                              className="site-button btnhover14"
                            >
                              Show Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <Box sx={{ display: "flex", justifyContent: "center", }}>
                  <Stack spacing={2}>
                    <Pagination
                      count={pageCount}
                      variant="outlined"
                      color="primary"
                      sx={{marginBottom:'1rem'}}
                      page={page}
                      onChange={(e) => {
                        if (e.target.innerText) {
                          setPage(+e.target.innerText);
                          history.push(`/products/page=${e.target.innerText}`)
                        } else 
                          if (
                            e.target.innerHTML ===
                            '<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>'
                          ) {
                            setPage(parseInt(page) - 1);
                            history.push(`/products/page=${parseInt(page) - 1}`);
                          } else {
                            setPage(parseInt(page) + 1);
                            history.push(`/products/page=${parseInt(page) + 1}`);
                          }
                      }}
                    />
                  </Stack>
                </Box>
              </div>
            </div>
          </div>
        ) : (
          <Box
            sx={{
              height: "100vh",
              width: "100vw",
              bgcolor: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="cylon" color="#000" />
          </Box>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default AllProducts;
