import React, { useState, useEffect } from "react";
import {
  AccountCircle,
  Close,
  Delete,
  Edit,
  Save,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Footer5 from "../layout/footer5";
import {Header12} from "../layout/header12";
import { toast } from "react-toastify";
import DefineIndustrySubCategory from "../components/defineIndustrySubCategory";
import { getRequest, postRequest } from "../../request";
import DeleteModal from "../components/deleteModal";
import { useTheme } from "@mui/material";
import SideBar from "../components/sideBar";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";

const styles = {
  containerBox2: {
    display: "grid",
    gridTemplateColumns: "auto ",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  containerBox3: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  box3: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "37rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "space-around",
  },
  box2: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "45rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // justifyContent: "space-around",
  },
  innerBox: {
    display: "grid",
    width: "100%",
    padding: "1rem",
    gridTemplateColumns: "auto auto ",
    justifyContent: "space-around",
  },
  types: {
    backgroundColor: "#ccc",
    borderRadius: "0.5rem 0.5rem 0rem 0rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  newclass1: {
    position: "relative",
    width: "unset",
    background: "none",
    top:'-1.2rem',
    left:'1rem',
  },
  newclass2: {
    position: "relative",
    width: "unset",
    background: "none",
    top:'-1.2rem',
    left:'1rem',
  },
};
const bnr = require("./../../images/banner/bnr5.jpg");

function Industries() {
  const code = (Math.random() * 1000000).toFixed(0);
  const theme = useTheme();
  const [industryCategory, setIndustryCategory] = useState({
    industryCategoryCode: `industry-category-${code}`,
    industryCategoryName: "",
    industryCategoryLocalName: "",
  });
  const [editedCategory, setEditedCategory] = useState("");
  const [industryCategoryList, setIndustryCategoryList] = useState([]);
  const [selectedIndustryCategory, setSelectedIndustryCategory] = useState("");
  const [industrySubCategoryList, setIndustrySubCategoryList] = useState([]);
  const [isShowIndustry, setIsShowIndustry] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deletedIndustryId, setDeletedIndustryId] = useState(-1);
  const [deleteSuccessful, setDeleteSuccessful] = useState(false);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }
  useEffect(() => {
    fetchCategories();
  }, []);
  useEffect(() => {
    if (deleteSuccessful) {
      setIndustryCategoryList((prevstate) => [
        ...prevstate.filter((x) => x.industryCategoryId !== deletedIndustryId),
      ]);
      setDeleteSuccessful(false);
      setDeletedIndustryId(-1);
    }
  }, [deleteSuccessful]);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };
  const fetchCategories = async () => {
    const { data, error } = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/IndustryCategory`
    );
    if (data && Object.keys(data).length) {
      setIndustryCategoryList(data);
    } else {
      toast.error(error);
    }
  };
  const handleChange = (event) => {
    setIndustryCategory({
      ...industryCategory,
      [event.target.name]: event.target.value,
    });
  };
  const handleRemoveCategory = async (id) => {
    setDeletedIndustryId(id);
    setOpenModal(true);
  };

  const handleShowCategory = async (id) => {
    const { data, error } = await getRequest(
      `${process.env.REACT_APP_BASE_URL}/IndustryCategory/subcategories/${id}`
    );
    if (data) {
      setIndustrySubCategoryList(data);
      setSelectedIndustryCategory(id);
      setIsShowIndustry(false);
    } else {
      toast.error(error);
    }
  };
  const handleAddIndustryCategory = async () => {
    const { message } = await postRequest(
      `${process.env.REACT_APP_BASE_URL}/IndustryCategory`,
      industryCategory
    );

    if (message === "ok") {
      setIndustryCategoryList((prevstate) => [...prevstate, industryCategory]);
      toast.success("industry category added successfully!");
    }
  };
  const editCategory = async () => {
    const categoryData = {
      ...industryCategory,
      industryCategoryId: selectedIndustryCategory,
    };
    const { message } = await postRequest(
      `${process.env.REACT_APP_BASE_URL}/IndustryCategory/edit`,
      categoryData
    );
    if (message === "ok") {
      industryCategoryList[
        industryCategoryList.findIndex(
          (x) => x.industryCategoryId === selectedIndustryCategory
        )
      ] = industryCategory;
      setIndustryCategoryList(industryCategoryList);
      setSelectedIndustryCategory("");
      setIndustryCategory({
        industryCategoryCode: `industry-category-${code}`,
        industryCategoryName: "",
        industryCategoryLocalName: "",
      });
      toast.success("category edited successfully!");
    }
  };
  return (
    <div>
      <Header12 />
      <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
        <div className="container">
            <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Industries</h1>
                <div className="breadcrumb-row">
                    <ul className="list-inline">
                        <li><Link to="/">Home</Link></li>
                        <li>Industries</li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
      {decode.role === "SuperAdmin" ? (
        <Button
          color="primary"
          variant="contained"
          onClick={toggleDrawer(true)}
          sx={{ margin: "1rem 3rem", }}
        >
          user options <AccountCircle />
        </Button>
      ) : null}
      <Box
        component="form"
        sx={
          selectedIndustryCategory ? styles.containerBox3 : styles.containerBox2
        }
      >
        <Box>
          <Box sx={selectedIndustryCategory ? styles.box3 : styles.box2}>
            <Box sx={{width:'100%',display:'flex',flexDirection:'initial'}}>
              <Typography
                variant="h5"
                color="initial"
                sx={
                  selectedIndustryCategory ? styles.newclass2 : styles.newclass1
                }
              >
                <span>Industry Category</span>
              </Typography>
            </Box>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="Code"
                sx={{ width: "250px" }}
                name="industryCategoryCode"
                value={industryCategory.industryCategoryCode}
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                label="Name"
                sx={{ width: "250px" }}
                name="industryCategoryName"
                value={industryCategory.industryCategoryName}
                onChange={handleChange}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="localName"
                sx={{ width: "250px" }}
                name="industryCategoryLocalName"
                value={industryCategory.industryCategoryLocalName}
                onChange={handleChange}
              />
              <div style={{ width: "250px" }}></div>
            </div>
            <div style={styles.innerBox}>
              <div style={{ width: "250px" }}>
                {editedCategory ? (
                  <ButtonGroup>
                    <Button
                      variant="contained"
                      color="danger"
                      sx={{ color: "#efefef" }}
                      onClick={() => {
                        // setSelectedIndustryCategory("");
                        setEditedCategory("");
                        setIndustryCategory({
                          industryCategoryCode: `industry-category-${code}`,
                          industryCategoryName: "",
                          industryCategoryLocalName: "",
                        });
                      }}
                    >
                      cancel
                      <Close />
                    </Button>
                    <Button
                      variant="contained"
                      color="edit"
                      sx={{ color: "#efefef" }}
                      onClick={editCategory}
                    >
                      edit
                      <Edit />
                    </Button>
                  </ButtonGroup>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !industryCategory.industryCategoryCode ||
                      !industryCategory.industryCategoryName ||
                      !industryCategory.industryCategoryLocalName
                    }
                    sx={{ color: "white" }}
                    onClick={handleAddIndustryCategory}
                  >
                    save
                    <Save />
                  </Button>
                )}
              </div>
              <div style={{ width: "211px" }}></div>
            </div>
            <Box sx={{ width: "100%", padding: "0rem 3rem" }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{ width: "95%", margin: "0.5rem" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>code</TableCell>
                      <TableCell align="right">name</TableCell>
                      <TableCell align="right">edit</TableCell>
                      <TableCell align="right">remove</TableCell>
                      <TableCell align="right">sub categories</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {industryCategoryList.length
                      ? industryCategoryList.map((row) => (
                          <TableRow
                            key={row.code}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.industryCategoryCode}
                            </TableCell>
                            <TableCell align="right">
                              {row.industryCategoryName}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={() => {
                                  setEditedCategory(row.industryCategoryId);
                                  setIndustryCategory(
                                    industryCategoryList.find(
                                      (x) =>
                                        x.industryCategoryId ===
                                        row.industryCategoryId
                                    )
                                  );
                                }}
                              >
                                <Edit
                                  style={{ color: theme.palette.edit.main }}
                                />
                              </IconButton>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label=""
                                onClick={() =>
                                  handleRemoveCategory(row.industryCategoryId)
                                }
                              >
                                <Delete
                                  style={{ color: theme.palette.danger.main }}
                                />
                              </IconButton>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label=""
                                onClick={() =>
                                  handleShowCategory(row.industryCategoryId)
                                }
                              >
                                <Visibility
                                  sx={
                                    selectedIndustryCategory ===
                                    row.industryCategoryId
                                      ? { color: theme.palette.primary.main }
                                      : { color: "inherit" }
                                  }
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
        <DefineIndustrySubCategory
          isShowIndustry={isShowIndustry}
          setIsShowIndustry={setIsShowIndustry}
          industrySubCategoryList={industrySubCategoryList}
          setIndustrySubCategoryList={setIndustrySubCategoryList}
          selectedIndustryCategory={selectedIndustryCategory}
        />
      </Box>
      <Footer5 />
      <SideBar open={open} toggleDrawer={toggleDrawer} />
      {openModal ? (
        <DeleteModal
          openDeleteModal={openModal}
          setOpenDeleteModal={setOpenModal}
          parameterName="industry category"
          deleteURL={`${process.env.REACT_APP_BASE_URL}/IndustryCategory/delete/${deletedIndustryId}`}
          destination={"NotLoad"}
          setIsSuccessful={setDeleteSuccessful}
        />
      ) : null}
    </div>
  );
}

export default Industries;
