import axios from "axios";
import React, { useState, useEffect } from "react";
import {Header12} from "../layout/header12";
import Footer from "../layout/footer5";
import { Link, useParams, useLocation } from "react-router-dom";
import { Stack } from "@mui/system";
import { Autocomplete, Box, Button, IconButton, InputLabel, MenuItem, Pagination, Select, TextField, Typography } from "@mui/material";
import Loading from "../components/loading";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest } from "../../request";
import FormControl from '@mui/material/FormControl';
import { Add, Cancel, Search } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
import { useMediaQuery } from "react-responsive"; 
import { useTheme } from "@emotion/react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const bnr = require("./../../images/banner/bnr5.jpg");
const NoProductImg = require("../../images/our-work/chemicals/No_Image_Available.jpg");
const productModal = require('../../images/our-work/chemicals/productImageModel.png')
function Product() {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const params = useParams();
  const fetchId = params.productId.split("&&")[0].split('=')[1];
  const notFoundProduct = require("../../images/our-work/chemicals/no-result.png");
  const [productsData, setProductsData] = useState([]);
  const [page, setPage] = useState(0);
  const [header, setHeader] = useState([]);
  const [productSubCat, setProductSubCat] = useState('')
  const [filteredItem, setFilteredItem] = useState({ name: "" });
  const [pageCount, setPageCount] = useState(0);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('');
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)"
  });

  const isTablet = useMediaQuery({
    query: "((min-width:650px) and (max-width: 900px))"
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 400px)"
  });

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const token = localStorage.getItem("token");
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }

  const fetchProductsByCategory = async (pPage) => {
    const { error, data } = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory/products/${fetchId}`)
      if(data && Object.keys(data).length){
        
        data.slice(12*(pPage-1),12*(pPage-1)+12).map((x) => {
          setProductsData((prevstate) => [
            ...prevstate,
            {
              id: x.productId,
              name: x.productName,
              description: x.productDescription,
              productSubCategoryName:x.subCategory.productSubCategoryName,
              image: x.images[0]
                ? `${process.env.REACT_APP_IMG_URL}` + x.images[0]
                : NoProductImg,
            },
          ]);
        });
        setPageCount(Math.floor(data.length / 12) + 1);
      }else{
        toast.error(error);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    const res = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory/${fetchId}`)
      if(res.data && Object.keys(res.data).length){        
        setHeader([{data:res.data.productCategoryName,id:res.data.productCategoryId,type:'productCategory'}]);
      }else{
        toast.error(res.error);
      }
  };
  const fetchProductsBySubCategory = async (pPage) => {
    const { error, data } = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSubCategory/products/${fetchId}`)
      if(data && Object.keys(data).length){
        
        data.slice(12*(pPage-1),12*(pPage-1)+12).map((x) => {
          setProductsData((prevstate) => [
            ...prevstate,
            {
              id: x.productId,
              name: x.productName,
              description: x.productDescription,
              image: x.images[0]
                ? `${process.env.REACT_APP_IMG_URL}` + x.images[0]
                : NoProductImg,
            },
          ]);
        });
        setPageCount(Math.floor(data.length / 12) + 1);
      }else{
        toast.error(error);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      const res = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSubCategory/${fetchId}`)
      if(res.data && Object.keys(res.data).length){
        setProductSubCat(res.data.productSubCategoryName);
        setHeader([
          {
          data:res.data.productCategory.productCategoryName ,
          id:res.data.productCategory.productCategoryId,
          type:'productCategory'
          },
          {
          data: res.data.productSubCategoryName,
          id:res.data.productSubCategoryId,
          type:'productSubCategory'
          }
          ]);
      }else{
        toast.error(res.error);
      }
  };
  const handleFilterProducts = async (value) => {
    setFilteredItem({ name: value });
    if (value === null) {
      setProductsData([]);
      setIsLoading(true);
      if (params.productId.split("=")[0].indexOf("ProductSubCategory") !== -1) {
        fetchProductsBySubCategory(page);
      } else if (
        params.productId.split("=")[0].indexOf("ProductCategory") !== -1
      ) {
        fetchProductsByCategory(page);
      }
    } else {
      setProductsData(productsData.filter((x) => x.name === value));
    }
    // setFilteredItem({name:''});
  };
  useEffect(() => {
    setSelectedCategory(+fetchId);
    if (params.productId.split("=")[0].indexOf("ProductSubCategory") !== -1) {
      const pageNumber = params.productId.split('&&')[1].split('=')[1];
      setPage(+pageNumber);
      // fetchProducts(+pageNumber);
      fetchProductsBySubCategory(+pageNumber);
      setProductsData([]);
    } else if (
      params.productId.split("=")[0].indexOf("ProductCategory") !== -1
    ) {
      const pageNumber = params.productId.split('&&')[1].split('=')[1];
      setPage(+pageNumber);
      fetchProductsByCategory(+pageNumber);
      setProductsData([]);
    } else {
      setProductsData([]);
    }
    setIsLoading(true);
  }, [location]);
  // useEffect(() => {    
  //   const pageNumber = params.page.split('=')[1];
  //   setPage(+pageNumber);
  //   fetchProducts(+pageNumber);
  // }, [params]);
  useEffect(() => {
    fetchSubCategories();
  }, [])
  
  const fetchSubCategories =async()=>{
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSubCategory`);
    if(data && Object.keys(data).length){
      setSubCategoryList(data);
    }else{
      toast.error(error);
    }
  }
  const handleClearFilter=()=>{
    history.push('/products/page=1');
    setSelectedCategory('');
  }
  return (
    <div>
      <Header12/>
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{ backgroundImage: "url(" + bnr + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">{header[0]?.data}</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    {header.map((x, ind) => (
                      <li key={ind} style={{cursor:'pointer'}} onClick={x.type==='productCategory'?()=>history.push(`/product/:ProductCategory=${x.id}&&page=1`):()=>history.push(`/product/:ProductSubCategory=${x.id}&&page=1`)}>{x.data}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
      {!isLoading ? (
        <div className="page-content bg-white">
          <div className="section-full  bg-gray">
            <div className="container">
            <Box sx={{display:'flex',justifyContent:'center',padding:'1rem 0rem'}}> 
              <Box sx={isDesktop?{display:'flex',flexDirection:'column', justifyContent:'center', backgroundColor:'#ebebeb',width:'600px',boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'}:
                      {display:'flex',flexDirection:'column', justifyContent:'space-between',height:'13rem', backgroundColor:'#ebebeb',width:'600px',boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px'}}>
              <Box sx={{display:'flex',justifyContent:'center'}}>
                <Typography variant="h6" sx={{color:'#6a6a6a',borderBottom:`2px solid ${theme.palette.primary.main}`}}><Search/> Search</Typography>
              </Box>
              
              <Box sx={isDesktop?{display:'flex',flexDirection:'row', justifyContent:'space-around',height:'4rem', width:'100%',alignItems:'center'}:
              {display:'flex',flexDirection:'column', justifyContent:'space-around',height:'7rem', width:'100%',alignItems:'center'}}>
                {productsData.length ? (
                  <Autocomplete
                    title="Search Product"
                    disablePortal
                    size="small"
                    value={filteredItem.name}
                    // getOptionSelected={(option, value) => option.name === value.name}
                    onChange={(e, value) => handleFilterProducts(value)}
                    options={productsData.map((x) => x.name)}
                    sx={{ width: "17rem", marginBottom: "0.5rem",height:'2rem' }}
                    renderInput={(params) => (
                      <TextField {...params} label={`Search Product...`} />
                    )}
                  />
                ) : null}
                <Box sx={{ width:'17rem',display:'flex',position:'relative' }}>
                  <FormControl size="small" sx={{width :'17rem'}}>
                    <InputLabel >SubCategory</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCategory}
                      label="subCategory"
                      onChange={(e)=> {
                        setSelectedCategory(e.target.value);
                        history.push(`/product/ProductSubCategory=${e.target.value}&&page=1`)}}
                    >
                      {subCategoryList.map((x,index)=>
                        <MenuItem key={index} value={x.productSubCategoryId}>{x.productSubCategoryName}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  {selectedCategory?
                    <IconButton size="small" sx={{height:'2.5rem',position:'absolute',right:'1.5rem'}} onClick={handleClearFilter}>
                      <Cancel/>
                    </IconButton>
                  :null
                  }
                </Box>
              </Box>
              <Box sx={{display:'flex',justifyContent:'center'}}>
                {decode.role === "SuperAdmin" ? (
                  <Button
                    variant="contained" color="primary" size="small"
                    style={{ marginBottom: "1rem",height:'3rem',width:'17rem' }}
                    onClick={()=>history.push(`/productProperties/0`) }
                  >
                    New Product
                    <Add />
                  </Button>
                ) : null}
              </Box>
              </Box>
              </Box>
              <div className="row">
                {productsData.length ? (
                  productsData
                    // .slice(
                    //   0 +
                    //     (parseInt(page) ? parseInt(page) - 1 : parseInt(page)) *
                    //       12,
                    //   12 +
                    //     (parseInt(page) ? parseInt(page) - 1 : parseInt(page)) *
                    //       12
                    // )
                    .map((product, index) => (
                      <div
                        key={index}
                        className="col-lg-3 col-md-6 col-sm-6 m-b30 wow fadeInUp"
                        data-wow-duration="2s"
                        data-wow-delay="0.3s"
                      >
                        <div className="dlab-box service-box-2">
                          <div className="dlab-media radius-sm dlab-img-overlay1 dlab-img-effect zoom">
                            <Link to="/services-details">
                              <LazyLoadImage
                                src={product.image}
                                alt=""
                                style={{ height: "300px" }}
                              />
                              {/* <LazyLoadImage
                                src={productModal}
                                alt=""
                                style={{
                                  height: "300px",
                                  top: 0,
                                  position: "absolute",
                                }}
                              /> */}
                            </Link>
                          </div>
                          <div className="dlab-info">
                            <h4 className="title">
                              <Link to="/services-details">{product.name}</Link>
                            </h4>
                            <Typography variant="caption" sx={{backgroundColor:'#bdf3e7',padding:'0.2rem',borderRadius:'3px', color:'#349d9c'}}>{productSubCat?productSubCat: product.productSubCategoryName}</Typography>
                            {product.description ? (
                              <p
                                style={{
                                  height: "6.6rem",
                                  overflowY: "hidden",
                                }}
                              >
                                {product.description?.slice(0, 100)}
                                <b>{"   ..."}</b>
                              </p>
                            ) : (
                              <p
                                style={{
                                  height: "6.6rem",
                                  overflowY: "hidden",
                                }}
                              >
                                <b>No Description Found!</b>
                              </p>
                            )}
                            <Link
                              to={`/singleProduct/${product.id}`}
                              className="site-button btnhover14"
                            >
                              Show Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <LazyLoadImage src={notFoundProduct} alt="no item found" />
                  </Box>
                )}
              </div>
              {productsData.length && pageCount > 1 ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Stack spacing={2}>
                    <Pagination
                      sx={{marginBottom:'1rem'}}
                      count={pageCount}
                      variant="outlined"
                      page={page}
                      color="primary"
                      onChange={(e) => {
                        if (e.target.innerText) {
                          setPage(+e.target.innerText);
                          if(params.productId.split("=")[0].indexOf("ProductSubCategory") !== -1){

                            history.push(`/product/ProductSubCategory=${fetchId}&&page=${+e.target.innerText}`);
                          }if (
                                params.productId.split("=")[0].indexOf("ProductCategory") !== -1
                              ){
                                history.push(`/product/ProductCategory=${fetchId}&&page=${+e.target.innerText}`);
                              }
                        } else {
                          if (
                            e.target.innerHTML ===
                            '<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>' 
                          ) {
                            if(params.productId.split("=")[0].indexOf("ProductSubCategory") !== -1){
                              history.push(`/product/ProductSubCategory=${fetchId}&&page=${page-1}`);
                              setPage(parseInt(page) - 1);
                            }if (
                                  params.productId.split("=")[0].indexOf("ProductCategory") !== -1
                                ){
                                  history.push(`/product/ProductCategory=${fetchId}&&page=${page-1}`);
                                  setPage(parseInt(page) - 1);
                                }
                          } else {
                            if(params.productId.split("=")[0].indexOf("ProductSubCategory") !== -1){

                              history.push(`/product/ProductSubCategory=${fetchId}&&page=${page+1}`);
                              setPage(parseInt(page) + 1);
                            }if (
                                  params.productId.split("=")[0].indexOf("ProductCategory") !== -1
                                ){
                                  history.push(`/product/ProductCategory=${fetchId}&&page=${page+1}`);
                                  setPage(parseInt(page) + 1);
                                }
                            // setPage(parseInt(page) + 1);
                          }
                        }
                      }}
                    />
                  </Stack>
                </Box>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading type="cylon" color="#000" />
        </Box>
      )}
      <Footer />
    </div>
  );
}

export default Product;
