import { Add,  Delete, } from '@mui/icons-material';
import { TextField, Typography, ButtonGroup, Button, Autocomplete } from '@mui/material'
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { getRequest, postRequest } from '../../request';
const style={
  modalBody:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around',
    height:'14rem'
  }
}

function AssignToGroupComponent({assignSelected}) {
    const [productsData, setProductsData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(0);
    const handleAddGroup=async()=>{
      const {error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductGroup/addToGroup/${selectedProduct}/${assignSelected.id}`);
      if(error){
        toast.error(error);
      }
      else{
        toast.success('group added successfully!');
        window.location.reload();
      }
    }

    useEffect(() => {
        fetchProducts();
    }, [])
    const fetchProducts = async() => {
        const response = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product`);
          response.data.sort((a,b)=>a.productSubCategoryId - b.productSubCategoryId).map((x) => {
            setProductsData((prevstate) => [
              ...prevstate,
              {
                id: x.productId,
                name: x.productLocalName,
              },
            ]);
        });
    };

  return (
    <div>
        <Box sx={{display:'flex',justifyContent:'space-between'}}>
        <Typography variant='h5'>Assign Product</Typography>
        <Typography variant="body1" >group:{assignSelected.name}</Typography>
        </Box>
        <Box sx={style.modalBody}>
        <Autocomplete
            title="Search Product"
            size="small"
            disablePortal
            value={selectedProduct.name}
            // getOptionSelected={(option, value) => option.name === value.name}
            onChange={(e,value) =>{
                setSelectedProduct(productsData.find(x=>x.name === value).id);
            }}
            options={productsData.map((x) => x.name)}
            sx={{ width:'17rem' }}
            renderInput={(params) => (
              <TextField {...params} label={`Search Product...`}  />
            )}
        />
        <Button variant='contained' color='primary' sx={{color:'#fff',width:'fit-content'}} onClick={handleAddGroup}>Add<Add/></Button>
        </Box>
    </div>
  )
}

export default AssignToGroupComponent