import { ChevronRight, ExpandMore, Login, Logout, Search, } from '@mui/icons-material';
import { Autocomplete, ClickAwayListener, Collapse, IconButton, List, ListItemButton, ListItemText, Popover, TextField, Tooltip, Typography } from '@mui/material';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { toast } from 'react-toastify';
import { getRequest } from '../../request';
import { useMediaQuery } from "react-responsive"; 
import WhatsAppIcon from './whatsAppIcon';
import PhoneSvg from '../../images/newStyle/phone.svg';
import WhatsAppSvg from '../../images/newStyle/whatsapp.svg';
import MemberSvg from '../../images/newStyle/member.svg';
import profileImg from '../../images/newStyle/Artboard.png';
import '../../css/newUi/newUI.css';

    var token ='';
function Header12 () {
    const history = useHistory();
    const location = useLocation();
    const activeHeader = window.location.href.split('/').at(-1);
    const [openHeaderProducts, setOpenHeaderProducts] = useState(false);
    const [selectedTab, setSelectedTab] = useState (-1);
    const [stickyState, setStickyState] = useState(false);
    const [rootProducts, setRootProducts] = useState([]);
    const [childrenProducts, setChildrenProducts] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [filteredItem, setFilteredItem] = useState({name:''});
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [navBarClicked, setNavBarClicked] = useState(false);
    token = localStorage.getItem('token');
    let decode={};
    useEffect(() => {   
        fetchProducts();
        if (token) {
            decode = jwtDecode(token);
        }
    }, [token])
    if(token){
        decode = jwtDecode(token);
    }
    const clickAway =()=>{
        if(!navBarClicked){
            setNavBarClicked(!navBarClicked);
            const element = document.getElementById('navbarNavDropdown');
            const navIcon = document.getElementById('navicon');
            if(element.classList.contains('show')){
                element.classList.remove('show');
                navIcon.classList.remove('open');
            }

        }else{
            setNavBarClicked(false);
        }

    }
    useEffect(() => {
        ProductCategories();
        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
            } else{
                current.classList.add('open');
            }
        }

    }, [])
    const ProductCategories = async ()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory`)
          if(data && Object.keys(data).length){
            setRootProducts(data);
          }else{
            toast.error(error);
          }
      }
    
      const fetchChildProducts = async (id, level) => {
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductGroup/Children/${id}/${level}`)
          if(data && Object.keys(data).length){
            if (!childrenProducts.find((x) => x.parent == id)) {
              setChildrenProducts([{ parent: id, products: data }]);
            } else {
              setSelectedTab(-1);
              setChildrenProducts([]);
            }
          }else{
            toast.error(error);
          }
      };

      const handleClickUserIcon = (event) => {
        // setAnchorEl(event.currentTarget);
        localStorage.removeItem("token");
      };
    
      const fetchChildren = async (id)=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory/subcategories/${id}`)
          if(data && Object.keys(data).length){
            setChildrenProducts(data);
          }else{
            toast.error(error);
          }
      }
    
      const handleMouseMove = () => {
        setOpenHeaderProducts(true);
      };
      const handleClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpenHeaderProducts(!openHeaderProducts);
      };
      const handleClose = () => {
        setTimeout(() => {
          setOpenHeaderProducts(false);
        }, 200);
        setChildrenProducts([]);
        setSelectedTab(-1);
      };
    
      const handleClickRootProduct = (event, index, id) => {
        event.stopPropagation();
        setChildrenProducts([]);
        if(index === selectedTab){
          setSelectedTab(-1);
        }else{
          fetchChildProducts(id, 0);
          setSelectedTab(index);
        }
      };
      const handleShowChilds=(event, index, id)=>{
        event.stopPropagation();
        fetchChildren(id)
        setSelectedTab(index);
      }
      const handleStateChange = (status)=> {
        const fixed = status.status === Sticky.STATUS_FIXED ? true : false
        setStickyState(fixed);
    }
    const isDesktop = useMediaQuery({
        query: "(min-width: 1224px)"
      });
    
      const isTablet = useMediaQuery({
        query: "((min-width:650px) and (max-width: 900px))"
      });
    
      const isMobile = useMediaQuery({
        query: "(max-width: 480px)"
      });
      const fetchProducts = async() => {
        const response = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product`);
          response.data.sort((a,b)=>a.productSubCategoryId - b.productSubCategoryId).map((x) => {
            setProductsData((prevstate) => [
              ...prevstate,
              {
                id: x.productId,
                name: x.productLocalName,
              },
            ]);
          });
      };
      const handleChooseProduct = (value)=>{
        if (location.pathname.includes('singleProduct')){
            setFilteredItem({name: value });
            window.open(`https://pronixae.com/singleProduct/${productsData.find(x=>x.name === value).id}`)
        }else{
            setFilteredItem({name: value });
            history.push(`/singleProduct/${productsData.find(x=>x.name === value).id}`);
        }
      }
      const handleBtnSelect=(e)=>{
        if(e.key === 'Enter'){
            handleSearch();
        }
    }

    const handleSearch=async()=>{
        const searchText = document.querySelector('#searchInput').value;
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product`);
        if(data.length){
            const id = data.find(x=>x.productName.toLowerCase() === searchText.toLowerCase()).productId;
            if(id){
                history.push(`/singleProduct/${id}`);
            }else{
                toast.error('No Matching Product Found With Your Search Request');
            }
        }else{
            toast.error('something went wrong');
        }
    }
    const handleLoginLogout=()=>{
        if(decode.uname){
            localStorage.removeItem('token');
        }
        history.push('/login')
    }
     
    const active = stickyState ? "is-fixed" : ""
        return (
            <>
            <Sticky 
            innerZ={999} 
            enabled={true}
            onStateChange={handleStateChange}
            >
                <header className="site-header mo-left header header-transparent navstyle2">
                    <div className={`sticky-header main-bar-wraper ${active} navbar-expand-lg`}>
                        <div className="main-bar clearfix " style={activeHeader === 'prices'||activeHeader==='treeview'?{backgroundColor:'#000'}:null}> 
                            <div 
                                className={isDesktop?'container clearfix':'clearfix'}
                                // className="container clearfix" 
                                // style={{    
                                //     margin: '0',
                                //     width: '100vw',
                                //     display: 'flex',
                                // }}
                            >
                                <div className='innerContainer'>

                                
                                {
                                !isDesktop && isSearchClicked?
                                null:
                                <div className="logo-header mostion imgHeader">
                                    <Link to="/"><img src={require("../../images/pronix-transformed.png")} alt=""/></Link>
                                </div>
                                }

                                
                                
                                <button id="navicon" className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                
                                <div className="extra-nav">
                                    <div className="extra-cell">
                                        <button id="quik-search-btn" type="button" className="site-button-link"><i className="la la-search"></i></button>
                                    </div>
                                </div>
                                
                                <div className="dlab-quik-search ">
                                    <form action="#">
                                        <input name="search" value="" type="text" className="form-control" placeholder="Type to search"/>
                                        <span id="quik-search-remove"><i className="ti-close"></i></span>
                                    </form>
                                </div>
                                <ClickAwayListener onClickAway={clickAway}>
                                    <div className="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                                        <div className="logo-header d-md-block d-lg-none">
                                            <Link to="/"><img src={require("../../images/logo-pronix.png")} alt=""/></Link>
                                        </div>
                                        <div className='searchBoxDiv'>
                                            <div className='searchBoxHeader'>
                                                <input type="text" placeholder='Search...' className='searchInput' id='searchInput' onKeyDown={(e)=>handleBtnSelect(e)}/>
                                                <Search style={{color:'#fff',cursor:'pointer'}} onClick={handleSearch}/>
                                            </div>
                                            <div style={{display:'grid',gridTemplateColumns:'1fr 1fr 4fr',justifyItems:'center',paddingTop:'5px' }}> 
                                                
                                                <img src={WhatsAppSvg}  style={{width:'26px',height:'26px'}} alt="" />
                                                <img src={PhoneSvg} style={{width:'24px',height:'24px'}} alt="" />
                                                <div className='phoneNumber'>(+971)566.879.135</div>
                                            </div>
                                        </div>
                                        <ul className="nav navbar-nav">	
                                            <li className={`${activeHeader === ''?'selectedHeader':''} has-mega-menu homedemo headerItem`} > <Link to='/'>Home</Link>
                                                {/* <ul className="mega-menu">
                                                    <li><Link to="/"><img src={require("../../demo/pic1.jpg")} alt=""/><span>Home - Default</span></Link></li>
                                                    <li><Link to="/index-2"><img src={require("../../demo/pic2.jpg")} alt=""/><span>Home - Oil/Gas Plant</span></Link></li>
                                                    <li><Link to="/index-3"><img src={require("../../demo/pic3.jpg")} alt=""/><span>Home - Steel Plant</span></Link></li>
                                                    <li><Link to="/index-4"><img src={require("../../demo/pic4.jpg")} alt=""/><span>Home - Factory</span></Link></li>
                                                    <li><Link to="/index-5"><img src={require("../../demo/pic5.jpg")} alt=""/><span>Home - Construct</span></Link></li>
                                                    <li><Link to="/index-6"><img src={require("../../demo/pic6.jpg")} alt=""/><span>Home - Solar Plant</span></Link></li>
                                                    <li><Link to="/index-7"><img src={require("../../demo/pic7.jpg")} alt=""/><span>Home - Food Industry</span></Link></li>
                                                    <li><Link to="/index-8"><img src={require("../../demo/pic8.jpg")} alt=""/><span>Home - Agriculture</span></Link></li>
                                                    <li><Link to="/index-9"><img src={require("../../demo/pic9.jpg")} alt=""/><span>Home - Ship Industry</span></Link></li>
                                                    <li><Link to="/index-10"><img src={require("../../demo/pic10.jpg")} alt=""/><span>Home - Leather Industry</span></Link></li>
                                                    <li><Link to="/index-11"><img src={require("../../demo/pic11.jpg")} alt=""/><span>Home - Nuclear Plant</span></Link></li>
                                                    <li><Link to="/index-12"><img src={require("../../demo/pic12.jpg")} alt=""/><span>Home - Beer Factory</span></Link></li>
                                                    <li><Link to="/index-13"><img src={require("../../demo/pic13.jpg")} alt=""/><span>Home - Mining Industry</span></Link></li>
                                                    <li><Link to="/index-14"><img src={require("../../demo/pic14.jpg")} alt=""/><span>Home - Car Industry</span></Link></li>
                                                    <li><Link to="/index-15"><img src={require("../../demo/pic15.jpg")} alt=""/><span>Home - Plastic Industry</span></Link></li>
                                                </ul> */}
                                            </li>
                                            <li className={`${activeHeader === 'aboutUs'?'selectedHeader':''}  `}> <Link to={'/aboutUs'}>About Us</Link>
                                                {/* <ul className="mega-menu">
                                                    <li>
                                                        <Link to={'#'}>Pages</Link>
                                                        <ul>
                                                            <li><Link to={'/about-1'}>About us 1</Link></li>
                                                            <li><Link to={'/about-2'}>About us 2</Link></li>
                                                            <li><Link to={'/services-1'}>Services 1</Link></li>
                                                            <li><Link to={'/services-2'}>Services 2</Link></li>
                                                            <li><Link to={'/services-details'}>Services Details</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <Link to={'#'}>Pages</Link>
                                                        <ul>
                                                            <li><Link to={'/team-1'}>Team 1</Link></li>
                                                            <li><Link to={'/team-2'}>Team 2</Link></li>
                                                            <li><Link to={'/faq-1'}>Faqs 1</Link></li>
                                                            <li><Link to={'/faq-2'}>Faqs 2</Link></li>
                                                            <li><Link to={'/portfolio-grid-2'}>portfolio Grid 2</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <Link to={'#'}>Pages</Link>
                                                        <ul>
                                                            <li><Link to={'/portfolio-grid-3'}>Portfolio Grid 3</Link></li>
                                                            <li><Link to={'/portfolio-grid-4'}>Portfolio Grid 4</Link></li>
                                                            <li><Link to={'/portfolio-details'}>Portfolio Details</Link></li>
                                                            <li><Link to={'/error-403'}>Error 403</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <Link to={'#'}>Pages</Link>
                                                        <ul>
                                                            <li><Link to={'/error-404'}>Error 404</Link></li>
                                                            <li><Link to={'/error-405'}>Error 405</Link></li>
                                                            <li><Link to={'/help-desk'}>Help Desk</Link></li>
                                                            <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
                                                        </ul>
                                                    </li>
                                                </ul> */}
                                            </li>
                                            <li className={`${activeHeader === 'contactUs'?'selectedHeader':''}`}>
                                                <Link to={`/contactUs`}>Contact Us</Link>
                                            </li>
                                            {/* <li className={`${activeHeader === 'blogList'?'active':''}`}>
                                                <Link to={`/blogList`}>News</Link>
                                            </li> */}
                                            <li className={`${activeHeader === 'prices'?'selectedHeader':''}`}>
                                                <Link to={`/prices`}>Prices</Link>
                                            </li>
                                            <li className={`${activeHeader === 'blogList'?'selectedHeader':''}`}>
                                                <Link to={`/blogList`}>News</Link>
                                            </li>
                                            {/* <li className={`${activeHeader === 'blogList'  || activeHeader==='treeview'?'active':''} sub-menu right`} > 
                                                <Link to='#' >Options<i className="fa fa-chevron-down"></i></Link>
                                                <ul className="sub-menu right">
                                                    <li><Link to="/blogList">News</Link></li>
                                                    <li><Link to="/treeview">Tree View</Link></li>
                                                </ul>
                                            </li> */}

                                            {/* <li className="has-mega-menu"> <Link to={'#'}>Element<i className="fa fa-chevron-down"></i></Link>
                                                <ul className="mega-menu">
                                                    <li><Link to={'#'}>Element</Link>
                                                        <ul>
                                                            <li><Link to={'shortcode-buttons'}><i className="ti-mouse"></i> Buttons</Link></li>
                                                            <li><Link to={'shortcode-icon-box-styles'}><i className="ti-layout-grid2"></i> Icon box styles</Link></li>
                                                            <li><Link to={'shortcode-pricing-table'}><i className="ti-layout-grid2-thumb"></i> Pricing table</Link></li>
                                                            <li><Link to={'shortcode-team'}><i className="ti-user"></i> Team</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li> <Link to={'#'}>Element</Link>
                                                        <ul>
                                                            <li><Link to={'shortcode-image-box-content'}><i className="ti-image"></i> Image box content</Link></li>
                                                            <li><Link to={'shortcode-counters'}><i className="ti-timer"></i> Counters</Link></li>
                                                            <li><Link to={'shortcode-shop-widgets'}><i className="ti-shopping-cart"></i> Shop Widgets</Link></li>
                                                            <li><Link to={'shortcode-dividers'}><i className="ti-layout-list-post"></i> Dividers</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li> <Link to={'#'}>Element</Link>
                                                        <ul>
                                                            <li><Link to={'shortcode-images-effects'}><i className="ti-layout-media-overlay"></i> Images effects</Link></li>
                                                            <li><Link to={'shortcode-testimonials'}><i className="ti-comments"></i> Testimonials</Link></li>
                                                            <li><Link to={'shortcode-pagination'}><i className="ti-more"></i> Pagination</Link></li>
                                                            <li><Link to={'shortcode-alert-box'}><i className="ti-alert"></i> Alert box</Link></li>
                                                        </ul>
                                                    </li>
                                                    <li> <Link to={'#'}>Element</Link>
                                                        <ul>
                                                            <li><Link to={'shortcode-icon-box'}><i className="ti-layout-media-left-alt"></i> Icon Box</Link></li>
                                                            <li><Link to={'shortcode-list-group'}><i className="ti-list"></i> List group</Link></li>
                                                            <li><Link to={'shortcode-title-separators'}><i className="ti-layout-line-solid"></i> Title Separators</Link></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="#">Contact Us<i className="fa fa-chevron-down"></i></Link>
                                                <ul className="sub-menu right">
                                                    <li><Link to="/contact-1">Contact us 1</Link></li>
                                                    <li><Link to="/contact-2">Contact us 2</Link></li>
                                                    <li><Link to="/contact-3">Contact us 3</Link></li>
                                                    <li><Link to="/contact-4">Contact us 4</Link></li>
                                                </ul>
                                            </li> */}
                                            <li
                                                onMouseMove={isDesktop?handleMouseMove: null }
                                                onMouseLeave={handleClose}
                                                className={`${activeHeader.includes('product') ?'selectedHeader':''}`}
                                                style={{ width: "17rem", maxHeight: "10rem" }}
                                                >
                                                <Link to="/products/page=1">
                                                <div style={isMobile?{display:'flex',justifyContent:'space-between'}:null}>
                                                        Products 
                                                    <i className="fa fa-chevron-down" onClick={(e)=>handleClick(e)}></i>
                                                </div>
                                                </Link>
                                                {openHeaderProducts ? (
                                                    <div>
                                                    <List
                                                        sx={{
                                                        width: "100%",
                                                        maxWidth: 360,
                                                        bgcolor: "#efefef",
                                                        zIndex:1000,
                                                        }}
                                                        component="nav"
                                                        aria-labelledby="nested-list-subheader"
                                                    >
                                                        {rootProducts.map((product, index) => (
                                                        <div key={product.productCategoryId}>
                                                            <ListItemButton onClick={()=>history.push(`/product/:ProductCategory=${product.productCategoryId}&&page=1`)}>
                                                            <ListItemText
                                                                primary={product.productCategoryName}
                                                                
                                                            />
                                                            {selectedTab === index + 1 ? (
                                                                <IconButton
                                                                color="primary"
                                                                // className="productTreeButtonSelected"
                                                                component="label"
                                                                sx={{
                                                                    bgcolor: "none",
                                                                    borderRadius: "0",
                                                                    borderLeft: "1px solid silver",
                                                                    paddingLeft: "7%"
                                                                }}
                                                                onClick={(event) =>
                                                                    handleClickRootProduct(
                                                                    event,
                                                                    index + 1,
                                                                    product.productCategoryId
                                                                    )
                                                                }
                                                                >
                                                                <ExpandMore />
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton
                                                                color="primary"
                                                                // className="productTreeButton"
                                                                sx={{
                                                                    bgcolor: "none",
                                                                    borderRadius: "0",
                                                                    borderLeft: "1px solid silver",
                                                                    paddingLeft: "7%"
                                                                }}
                                                                component="label"
                                                                onClick={(event) =>
                                                                    handleShowChilds(event,
                                                                    index + 1,
                                                                    product.productCategoryId)
                                                                    // handleClickRootProduct(
                                                                    //   event,
                                                                    //   index + 1,
                                                                    //   product.id
                                                                    // )
                                                                }
                                                                >
                                                                <ChevronRight />
                                                                </IconButton>
                                                            )}
                                                            </ListItemButton>
                                                            {selectedTab === index + 1 ? (
                                                            <Collapse
                                                                in={selectedTab}
                                                                timeout="auto"
                                                                unmountOnExit
                                                            >
                                                                {
                                                                childrenProducts.map(x=>
                                                                    <List
                                                                    key={x.productSubCategoryId}
                                                                    component="div"
                                                                    disablePadding
                                                                    style={{ paddingLeft: "2rem" }}
                                                                    >
                                                                    <ListItemButton
                                                                        onClick={()=>history.push(`/product/:ProductSubCategory=${x.productSubCategoryId}&&page=1`)}
                                                                        // onClick={(event) => handleClickChildProduct(event,prod.id, 0, index+1)}
                                                                    >
                                                                        <ListItemText primary={x.productSubCategoryName} />
                                                                    </ListItemButton>
                                                                    </List>
                                                                    )
                                                                }
                                                            </Collapse>
                                                            ) : null}
                                                        </div>
                                                        ))}
                                                    </List>
                                                    </div>
                                                ) : null}
                                                
                                                </li>
                                                {/* {decode.uname ? (
                                                    <li style={{display:'flex'}}>
                                                        <div
                                                        style={{
                                                            color: "#efefef",
                                                            width: "10rem",
                                                            textAlign: "center",
                                                            display:'flex',
                                                            flexDirection:'column'
                                                        }}
                                                        onClick={handleClickUserIcon}
                                                        >
                                                        <span 
                                                            style={isDesktop?{color:'#fff',cursor:'pointer'}:{color:'#000',cursor:'pointer'}}
                                                            onClick={(e)=>{
                                                            e.stopPropagation();
                                                            history.push(`/userProfile/${decode.uid}`);
                                                            }} 
                                                            > welcome {decode.uname}</span>
                                                        <Link to="/login">
                                                            <b>logout</b> <Logout/>
                                                        </Link>
                                                        </div>
                                                        
                                                    </li>
                                                
                                                ) : (
                                                    <div
                                                        style={{
                                                        color: "#efefef",
                                                        width: "10rem",
                                                        textAlign: "center",
                                                        }}
                                                    >
                                                        have an account?{" "}
                                                        <Link to="/login">
                                                        <b>LogIn</b> <Login/>
                                                        </Link>
                                                    </div>
                                                )} */}
                                                <div className={isMobile?null:decode.uname?'loginContainer':'logoutContainer'}>
                                                    {decode.uname ?
                                                    <Tooltip title='Your Profile' placement='left'>
                                                        <img 
                                                            src={MemberSvg} alt="" 
                                                            style={{width:'4rem',cursor:'pointer'}} 
                                                            onClick={(e)=>{
                                                                e.stopPropagation();
                                                                history.push(`/userProfile/${decode.uid}`);
                                                            }}
                                                        />
                                                    </Tooltip>:null
                                                    }
                                                    <div className={isMobile?null:'loginBox'}  onClick={handleLoginLogout}>
                                                        {decode.uname?
                                                        <div className='loginSection'>
                                                        <Logout style={{color:'#fff'}}/>
                                                            <div>LOGOUT</div> 
                                                        </div>:
                                                            <div className='loginSection'>
                                                            <Login style={{color:'#fff'}}/>
                                                                <div>LOGIN</div> 
                                                            </div>
                                                        }
                                                        <div className='loginLine'></div>
                                                        <div className='signUpSection'>
                                                        <div>SIGN UP</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    
                                        </ul>
                                        
                                        <div className="dlab-social-icon">
                                            <div 
                                                style={{
                                                    position: 'absolute',
                                                    top: '10.25rem',
                                                    left: '3.6rem',
                                                    borderRadius: '0.3rem',
                                                    padding: '0.5rem',
                                                    height: '1.8rem',
                                                    backgroundColor: 'rgb(52, 117, 238)'
                                                }}>
                                                <img 
                                                    src={profileImg} alt="" 
                                                    style={{width:'17px',cursor:'pointer',paddingBottom:'10px'}} 
                                                    onClick={(e)=>{
                                                        e.stopPropagation();
                                                        history.push(`/userProfile/${decode.uid}`);
                                                    }}
                                                />
                                            </div>
                                            <ul>
                                                <li><a className="site-button sharp-sm fa fa-instagram" style={{color:'#fff'}} href="https://www.instagram.com/accounts/login/?next=%2Fpronix_international%2F" target="_blank"></a></li>
                                                <li><a className="site-button sharp-sm fa fa-linkedin" style={{color:'#fff'}} href='https://www.linkedin.com/company/pronix-international-trading/' target="_blank"></a></li>
                                                <li>
                                                    <a 
                                                        href='https://wa.me/971566879135'
                                                        className="site-button sharp-sm"
                                                        target="_blank"><WhatsAppIcon color={'#fff'}
                                                    /></a> 
                                                </li>
                                            </ul>
                                        </div>		
                                    
                                    </div>
                                </ClickAwayListener>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* <div className='searchHeader'>
                        <Tooltip title={isSearchClicked?"Close Search Box":"Open Search Box"}>
                            <IconButton style={{color:'white'}} aria-label="" onClick={handleClickSearch}>
                                {isSearchClicked?<SearchOff/>:<Search/>}
                            </IconButton>
                        </Tooltip>
                        <Autocomplete
                            title="Search Product"
                            size="small"
                            disablePortal
                            value={filteredItem.name}
                            // getOptionSelected={(option, value) => option.name === value.name}
                            onChange={(e,value) =>
                                handleChooseProduct(value)
                            }
                            options={productsData.map((x) => x.name)}
                            sx={isSearchClicked?{ width:'12rem',}:{display:'none'}}
                            renderInput={(params) => (
                                <TextField {...params}  
                                sx={{
                                    "& .MuiInputBase-root": {
                                        color: '#efefef'
                                    },
                                    "& .MuiFormLabel-root": {
                                        color: '#efefef'
                                    },
                                    "& .MuiFormLabel-root.Mui-focused": {
                                        color: '#efefef'
                                    },
                                    
                                    '& .MuiOutlinedInput-root': {
                                        "& .MuiAutocomplete-endAdornment":{
                                            color: '#efefef'
                                        },
                                        '& fieldset': {
                                          borderColor: '#efefef',
                                        },
                                        '&:hover fieldset': {
                                          borderColor: '#efefef',
                                        },
                                        '&.Mui-focused fieldset': {
                                          borderColor: '#efefef',
                                        },
                                    },
                                }}
                            label={`Search Product...`}  />
                            )}
                        />
                    </div>	  */}
                    <div className={isDesktop?'headerLineFatherDesktop':'headerLineFather'}>
                        <div className='headerLine'></div>
                    </div>
                </header>
            </Sticky>                
            </>
        )
}
export  {Header12,token};