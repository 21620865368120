import React,{useEffect,useState} from 'react'
import Footer5 from '../layout/footer5'
import {Header12} from '../layout/header12'
import jwtDecode from 'jwt-decode';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/system';
import Loading from "../components/loading";
import { getRequest } from '../../request';
import { toast } from 'react-toastify';
import { AccountCircle } from '@mui/icons-material';
import SideBar from '../components/sideBar';
import { Link } from 'react-router-dom';

const styles = {
    container:{
      margin:'4rem 15rem 0rem 15rem',
      height:'85vh'
    },
  }
const bg = require("./../../images/banner/bnr5.jpg");
function Orders() {
  const [orderList, setOrderList] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const theme = useTheme();
  const token = localStorage.getItem("token");
  let decode = {};
  if (token) {
      decode = jwtDecode(token);
  }
  useEffect(() => {
    fetchOrders()
  }, []);
  const fetchOrders=async()=>{
    if(decode.role === "SuperAdmin" || decode.role === 'Admin'){
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Order/all`);
      if(data && Object.keys(data).length){
        setOrderList(data);
       
      }else{
        toast.error(error);
      }
    }
  }
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div>
        <Header12/>
        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="container">
                <div className="dlab-bnr-inr-entry">
                    <h1 className="text-white">Orders</h1>
                    <div className="breadcrumb-row">
                        <ul className="list-inline">
                            <li><Link to="/">Home</Link></li>
                            <li>Orders</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {decode.role === 'SuperAdmin'?
        <Button color='primary' variant='contained' onClick={toggleDrawer( true)} sx={{margin:'1rem 3rem',position:'absolute'}}>user options<AccountCircle/></Button>
        :null}
        {orderList?.length? 
        <Box sx={styles.container}>
          <TableContainer
          sx={{maxHeight:'78vh'}}
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead sx={{backgroundColor:theme.palette.primary.main}}>
              <TableRow>
                <TableCell sx={{color:'#fff'}} align='center'>Product Code</TableCell>
                <TableCell sx={{color:'#fff'}} align='center'>Product Name</TableCell>
                <TableCell sx={{color:'#fff'}} align='center'>Product Amount</TableCell>
                <TableCell sx={{color:'#fff'}} align='center'>Costumer</TableCell>
                <TableCell sx={{color:'#fff'}} align='center'>producer Name</TableCell>
                <TableCell sx={{color:'#fff'}} align='center'>Producer Mobile</TableCell>
                <TableCell sx={{color:'#fff'}} align='center'>Producer Email</TableCell>
                <TableCell sx={{color:'#fff'}} align='center'>City</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderList?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell align='center' component="th" scope="row">
                    {row.product.productCode}
                  </TableCell>
                  <TableCell align='center' component="th" scope="row">
                    {row.product.productName}
                  </TableCell>
                  <TableCell align='center' component="th" scope="row">
                    {row.amount? row.amount:row.qty}
                  </TableCell>
                  <TableCell align='center' component="th" scope="row">
                    {row.Costumer? row.Costumer:''}
                  </TableCell>
                  <TableCell align='center' component="th" scope="row">
                    {row.producer.producerName}
                  </TableCell>
                  <TableCell align='center' component="th" scope="row">
                    {row.producer.mobileNumebr}
                  </TableCell>
                  <TableCell align='center' component="th" scope="row">
                    {row.producer.email}
                  </TableCell>
                  <TableCell align='center' component="th" scope="row">
                    {row.city.cityName}
                  </TableCell>
                  
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {orderList.length>5?
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={orderList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />:null}
        </TableContainer>

        </Box>
        :
            <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "82.3vh",
          }}
        >
          <Loading type="cylon" color="#343a40" />
        </Box>
        }
        <SideBar open={open} toggleDrawer={toggleDrawer}/>
        <Footer5/>
    </div>
  )
}

export default Orders