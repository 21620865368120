import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: '#fff',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    padding:'3rem'
  };
function InfoModal() {
  return (
    <div style={modalStyle}>
        <Typography variant="h6" color="initial">The Price is based on quantity as per below table:</Typography>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Price (USD)</TableCell>
                    <TableCell align="right">QTY (MT)</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row">
                -10
                </TableCell>
                <TableCell align="right">Over 220 MT</TableCell>
                
                </TableRow>
                <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row">
                -5
                </TableCell>
                <TableCell align="right">132-220</TableCell>
                
                </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <Typography variant="body1" color="initial"><span style={{fontWeight:'bold'}}>Note1:</span> 20% in advanced, balance payment against Shipping documents</Typography>
        <Typography variant="body1" color="initial"><span style={{fontWeight:'bold'}}>Note2:</span> Payment term is negotiable based on the market/Delivery term</Typography>
        <Typography variant="body1" color="initial"><span style={{fontWeight:'bold'}}>Note3:</span> Currency Payment  should be in USD, AED or EURO</Typography>
    </div>
  )
}

export default InfoModal