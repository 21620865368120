import React,{useEffect,useState} from 'react'
import { getRequest, postRequest } from '../../request';
import { Box } from "@mui/system";
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Delete, Edit, Visibility } from '@mui/icons-material';
import { toast } from 'react-toastify';
import DeleteModal from './deleteModal';

const styles = {
    containerBox: {
      display: "grid",
      gridTemplateColumns: "2fr 2fr 2fr 2fr",
      gap: "2%",
      alignItems: "center",
      justifyContent: "space-around",
      height: "81vh",
      margin: "0rem 5rem",
    },
    box: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      display: "flex",
      width:'26rem',
      flexDirection: "column",
    },
    box1: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "76vh",
      // width: "40rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width:'26rem',
      margin:'2rem 1rem'
    },
    innerBox: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "auto auto",
      marginBottom:'0.5rem',
      justifyContent: "space-around",
    },
  };

function CountriesLocations({selectedContinent,setSelectedState, countryList,setCountryList, setStateList,setSelectedCountry,selectedCountry}) {
    const [deletedCountryId, setDeletedCountryId] = useState(-1);
    const [deleteSuccessfull, setDeleteSuccessfull] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openModal, setOpenModal] = useState(false);
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const [editedId, setEditedId] = useState(-1);
    const code = (Math.random() * 1000000).toFixed(0);
    const [country, setcountry] = useState({
        countryCode: `country-${code}`,
        countryName: "",
        countryLocalName: ""
    });
    // const fetchcountry=async()=>{
    //     const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/country`);
    //     if(data && Object.keys(data).length){
    //         setcountryList(data);
    //     }
    // }
    useEffect(() => {
      if(deleteSuccessfull){
        setCountryList(prevstate=>[...prevstate.filter(x=>x.countryId !== deletedCountryId)])
        setDeleteSuccessfull(false);
        setDeletedCountryId(-1);
      }
    }, [deleteSuccessfull]);
    const handleChangeProducer = (event) => {
        setcountry({
          ...country,
          [event.target.name]: event.target.value,
        });
    };
    const handleEditProducer=async()=>{
      const currentCountry= {
        ...country,
        countryId:editedId,
      }
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Country/edit`,currentCountry)
      if(message ==='ok'){
        countryList[
          countryList.findIndex(
            (x) => x.countryId === editedId
          )
        ] = country;
        setCountryList(countryList);
        setSelectedCountry(-1);
        setcountry({
          countryCode: "",
          countryName: "",
          countryLocalName: "",
        });
        toast.success('Country edited successfully!');
      }
    }
    const handleAddCountry=async()=>{
      const tempData = {...country,continentId:selectedContinent}
      const {message}= await postRequest(`${process.env.REACT_APP_BASE_URL}/Country`,tempData);
        if(message === 'ok'){
            toast.success('Country Added Successfully!');
            setCountryList((prevState)=>[...prevState,country ]);
            setcountry({
              countryCode: `country-${code}`,
              countryName: "",
              countryLocalName: ""
            });
        }
    }
    const handleRemoveCountry = async(id) => {
      setOpenModal(true);
      setDeletedCountryId(id);        
    };
    const handleShowState=async(id)=>{
      setSelectedCountry(id);
      setSelectedState(-1);
      
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Country/states/${id}`);
      if(data && Object.keys(data).length){
        setStateList(data);
      }else{
        setStateList([]);
      }
    }
  return (
    <div>
        <Box sx={styles.box1}>
            <Typography
              sx={{
                marginRight: "20rem",
                marginTop: "-1.5rem",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              country
            </Typography>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="Code"
                name="countryCode"
                value={country.countryCode}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
              <TextField
                required
                size="small"
                variant="outlined"
                label="Name"
                name="countryName"
                value={country.countryName}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="localName"
                name="countryLocalName"
                sx={{width:'170px'}}
                value={country.countryLocalName}
                onChange={handleChangeProducer}
              />
              <Box sx={{width:'170px'}}></Box>
            </div>
            {editedId !==-1?(
              <div style={styles.innerBox}>
              <Box sx={{ minWidth: 170 }}>
                <ButtonGroup variant="contained">
                  <Button color="danger" sx={{ color: "#efefef" }} 
                    onClick={()=>{
                        setcountry({
                            countryCode: `country-${code}`,
                            countryName: "",
                            countryLocalName: ""
                        });
                        setEditedId(-1);
                    }}
                  >
                    cancel <Close />
                  </Button>
                  <Button
                    color="edit"
                    sx={{ color: "#efefef" }}
                    onClick={handleEditProducer}
                  >
                    edit <Edit />
                  </Button>
                </ButtonGroup>
              </Box>
              <Box sx={{ minWidth: 170 }}></Box>
            </div>
            ):(
              <div style={styles.innerBox}>
                <Box sx={{ minWidth: 170 }}>
                  <ButtonGroup variant="contained">
                    <Button
                      color="primary"
                      sx={{ color: "#efefef" }}
                      onClick={handleAddCountry}
                      disabled={
                        !country.countryCode ||
                        !country.countryLocalName ||
                        !country.countryName
                      }
                    >
                      add <Add />
                    </Button>
                  </ButtonGroup>
                </Box>
                <Box sx={{ minWidth: 170 }}>
                </Box>
              </div>
            )}
          {countryList.length ?
            <TableContainer
              sx={{width:'96%'}}
              component={Paper}
            >
              <Table aria-label="simple table"  sx={{maxHeight:"50rem",}}>
                <TableHead>
                  <TableRow>
                    <TableCell>Country Code</TableCell>
                    <TableCell>Country Name</TableCell>
                    <TableCell align="right">Edit</TableCell>
                    <TableCell align="right">Remove</TableCell>
                    <TableCell align="right">State</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {countryList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.countryCode}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.countryName}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="edit"
                          onClick={()=>{
                            setEditedId(row.countryId);
                            setcountry(countryList.find(
                              (x) =>
                                x.countryId ===
                                row.countryId
                            ))
                          }
                          }
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="danger"
                          onClick={()=>
                            handleRemoveCountry(row.countryId)
                          }
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={()=>
                            handleShowState(row.countryId)
                          }
                        >
                          <Visibility color={selectedCountry === row.countryId?'primary':'inherit'}/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {countryList.length>5?
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={countryList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />:null}
            </TableContainer>
          :null}
        </Box>
          
          {openModal?
          <DeleteModal openDeleteModal={openModal} setOpenDeleteModal={setOpenModal} parameterName="Country" deleteURL={`${process.env.REACT_APP_BASE_URL}/Country/delete/${deletedCountryId}`} destination={'NotLoad'} setIsSuccessful={setDeleteSuccessfull}/>
            :null}
    </div>
  )
}

export default CountriesLocations