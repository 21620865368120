import React,{useEffect,useState} from 'react'
import { getRequest, postRequest } from '../../request';
import { Box } from "@mui/system";
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {Header12} from "../layout/header12";
import Footer from "../layout/footer5";
import { AccountCircle, Add, Close, Delete, Edit, Visibility } from '@mui/icons-material';
import { toast } from 'react-toastify';
import CountriesLocations from '../components/countriesLocations';
import StateLocations from '../components/stateLocations';
import CityLocation from '../components/cityLocation';
import DeleteModal from '../components/deleteModal';
import jwtDecode from 'jwt-decode';
import SideBar from '../components/sideBar';
import { Link } from 'react-router-dom';

const styles = {
    containerBox: {
      display: "grid",
      gridTemplateColumns: "2fr 2fr 2fr 2fr",
      // gap: "2%",
      alignItems: "center",
      justifyContent: "space-around",
      height: "82vh",
      margin: "0rem 5rem",
    },
    box: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      display: "flex",
      width:'33rem',
      flexDirection: "column",
    },
    box1: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "76vh",
      width:'33rem',
      // width: "40rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      margin:'2rem 1rem'
    },
    innerBox: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "auto auto",
      justifyContent: "space-around",
      marginBottom:'0.5rem'
    },
    btn:{
      margin:'1rem 1rem'
    }
  };
  const bg = require("./../../images/banner/bnr5.jpg");
function ContinentLocations() {
    const [continentList, setContinentList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deletedContinent, setDeletedContinent] = useState(-1);
    const [openModal, setOpenModal] = useState(false);
    const [deleteSuccessfull, setDeleteSuccessfull] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [editedContinent, setEditedContinent] = useState(-1);
    const [selectedContinent, setSelectedContinent] = useState(-1);
    const [selectedCountry, setSelectedCountry] = useState(-1);
    const [selectedState, setSelectedState] = useState(-1);
    const [selectedCity, setSelectedCity] = useState(-1);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [openUserModal, setOpenUserModal] = useState(false)
    const code = (Math.random() * 1000000).toFixed(0);
    const [continent, setContinent] = useState({
        continentCode: `continent-${code}`,
        continentName: "",
        continentLocalName: ""
    });
    const token = localStorage.getItem('token');
    let decode={};
    if(token){
      decode = jwtDecode(token);
    }
    const fetchContinents=async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Continent`);
        if(data && Object.keys(data).length){
            setContinentList(data);
        }
    }
    const handleChangeProducer = (event) => {
        setContinent({
          ...continent,
          [event.target.name]: event.target.value,
        });
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    const handleEditProducer=async()=>{
      const currentContinent= {
        ...continent,
        continentId:selectedContinent,
      }
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Continent/edit`,currentContinent)
      if(message ==='ok'){
        continentList[
          continentList.findIndex(
            (x) => x.continentId === selectedContinent
          )
        ] = continent;
        setContinentList(continentList);
        setSelectedContinent(-1);
        setContinent({
          continentCode: `continent-${code}`,
          continentName: "",
          continentLocalName: "",
        });
        toast.success('Continent edited successfully!');
      }
    }
    useEffect(() => {
      if(deleteSuccessfull){
        setContinentList(prevstate=>[...prevstate.filter(x=>x.continentId !== deletedContinent)])
        setDeleteSuccessfull(false);
        setDeletedContinent(-1);
      }
    }, [deleteSuccessfull])
    const handleAddProducer=async()=>{
        const {message}= await postRequest(`${process.env.REACT_APP_BASE_URL}/Continent`,continent);
        if(message === 'ok'){
            toast.success('Continent Added Successfully!');
            setContinentList((prevState)=>[...prevState,continent]);
            setContinent({
              continentCode: `continent-${code}`,
              continentName: "",
              continentLocalName: ""
            })
        }
    }
    const handleDeleteContinent = async(id) => {
      setOpenModal(true);
      setDeletedContinent(id);        
    };
    const showCountries= async(continentId)=>{
      setSelectedContinent(continentId);
      setSelectedCity(-1);
      setSelectedCountry(-1);
      setSelectedState(-1);
      const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Continent/countries/${continentId}`);
      if(data && Object.keys(data).length){
        setCountryList(data);
      }else{
        setCountryList([]);
      }
    }
    useEffect(() => {
      fetchContinents();
    }, []);
    const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setOpenUserModal(open);
    };
    
  return (
    <div>
        <Header12/>
        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="container">
                <div className="dlab-bnr-inr-entry">
                    <h1 className="text-white">Locations</h1>
                    <div className="breadcrumb-row">
                        <ul className="list-inline">
                            <li><Link to="/">Home</Link></li>
                            <li>Locations</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {decode.role === 'SuperAdmin'?
            <Button color='primary' sx={styles.btn}  variant='contained' onClick={toggleDrawer( true)} >user options <AccountCircle/></Button>
        :null}
        <Box sx={styles.containerBox}>
          <Box sx={styles.box1}>
            <Typography
              sx={{
                marginRight: "20rem",
                marginTop: "-1.5rem",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              Continent
            </Typography>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="Code"
                name="continentCode"
                value={continent.continentCode}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
              <TextField
                required
                size="small"
                variant="outlined"
                label="Name"
                name="continentName"
                value={continent.continentName}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="localName"
                name="continentLocalName"
                sx={{width:'170px'}}
                value={continent.continentLocalName}
                onChange={handleChangeProducer}
              />
              <Box sx={{width:'170px'}}></Box>
            </div>
            {editedContinent !==-1?(
              <div style={styles.innerBox}>
              <Box sx={{ minWidth: 170 }}>
              <ButtonGroup variant="contained">
                <Button color="danger" sx={{ color: "#efefef" }} 
                  onClick={()=>{
                      setContinent({
                          continentCode: "",
                          continentName: "",
                          continentLocalName: ""
                      });
                      setSelectedContinent(-1);
                  }}
                >
                  cancel <Close />
                </Button>
                <Button
                  color="edit"
                  sx={{ color: "#efefef" }}
                  onClick={handleEditProducer}
                >
                  edit <Edit />
                </Button>
              </ButtonGroup>
              </Box>
              <Box sx={{ minWidth: 170 }}></Box>
            </div>
            ):(
              <div style={styles.innerBox}>
                <Box sx={{ minWidth:170 }}>
                <ButtonGroup variant="contained">
                  <Button
                    color="primary"
                    sx={{ color: "#efefef" }}
                    onClick={handleAddProducer}
                    disabled={
                      !continent.continentCode ||
                      !continent.continentLocalName ||
                      !continent.continentName
                    }
                  >
                    add <Add />
                  </Button>
                </ButtonGroup>
                </Box>
                <Box sx={{ minWidth: 170 }}></Box>
              </div>
            )}

            <TableContainer
              sx={{width:'96%'}}
              component={Paper}
            >
            <Table aria-label="simple table"  sx={{maxHeight:"48rem",}}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Continent Code</TableCell>
                  <TableCell align="center">Continent Name</TableCell>
                  <TableCell align="center" >Edit</TableCell>
                  <TableCell align="center">remove</TableCell>
                  <TableCell align="center">countries</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {continentList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {row.continentCode}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.continentName}
                    </TableCell>
                    <TableCell  align="center">
                      <IconButton
                        color="edit"
                        onClick={() =>{
                          setEditedContinent(row.continentId)
                          setContinent(continentList.find(
                            (x) =>
                              x.continentId ===
                              row.continentId
                          ))
                        }
                        }
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="danger"
                        onClick={()=>
                          handleDeleteContinent(row.continentId)
                        }
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={()=>
                          showCountries(row.continentId)
                        }
                      >
                        <Visibility  color={selectedContinent === row.continentId?'primary':'inherit'}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {continentList.length>5?
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={continentList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />:null}
          </TableContainer>
          </Box>
          {selectedContinent !==-1  ?
          <CountriesLocations selectedContinent={selectedContinent}setSelectedState={setSelectedState} setSelectedCity={setSelectedCity} countryList={countryList} setCountryList={setCountryList} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} stateList={stateList} setStateList={setStateList}/>
          :null}
          {
            selectedCountry!==-1 ?
            <StateLocations selectedCountry={selectedCountry} stateList={stateList} setStateList={setStateList} selectedState={selectedState} setSelectedState={setSelectedState} cityList={cityList} setCityList={setCityList}/>
            :null 
          }
          {
            selectedState!==-1 ?
            <CityLocation selectedState={selectedState} cityList={cityList}  setCityList={setCityList} selectedCity={selectedCity} setSelectedCity={setSelectedCity}/>
            :null
          }
        </Box>
        {openModal?
          <DeleteModal openDeleteModal={openModal} setOpenDeleteModal={setOpenModal} parameterName="Country" deleteURL={`${process.env.REACT_APP_BASE_URL}/Continent/delete/${deletedContinent}`} destination={'NotLoad'} setIsSuccessful={setDeleteSuccessfull}/>
        :null}
        <SideBar open={openUserModal} toggleDrawer={toggleDrawer} />
        <Footer/>
    </div>
  )
}

export default ContinentLocations