import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getRequest } from '../../request';
import { toast } from 'react-toastify';
import { Box, Button, IconButton, Modal } from '@mui/material';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import AddGroupComponent from './addGroupComponent';
import EditGroupComponent from './editGroupComponent';
import { useHistory } from 'react-router-dom';
import DeleteModal from './deleteModal';
import AssignToGroupComponent from './assignToGroupComponent';
import { useTheme } from '@emotion/react';
const style = {
    modalStyle:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '3px',
        boxShadow: 24,
        p: 4,
    },
    rightSideContainer:{
        display:'flex',
        flexDirection:'column',
    },
}

function ProductGroupChild({parentId, hasGroupChilderen, haschildren}) {
    const history = useHistory();
    const theme = useTheme();
    const [childData, setChildData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(0);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [deleteProductSuccessFully, setDeleteProductSuccessFully] = useState(false);
    const [assignSelected, setAssignSelected] = useState({
        id:0,
        name:''
    });
    const [childSelected, setChildSelected] = useState({
        id:0,
        name:''
    });
    const [editSelected, setEditSelected] = useState({
        id:0,
        name:''
    });
    const [childProduct, setChildProduct] = useState({
        productId:'',
        parentId:''
    });
    
    useEffect(() => {
        fetchChildData();
    }, [])
    useEffect(() => {
        if(deleteProductSuccessFully){
            window.location.reload();
        }
    }, [deleteProductSuccessFully])
    const fetchChildData=async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductGroup/children/${parentId}/0`);
        if(data && Object.keys(data).length){
            setChildData(data)
        }else{
            toast.error(error);
        }
    }
    const handleClick=(id)=>{
        if(id === selectedItem){
            setSelectedItem(0);
        }else{
            setSelectedItem(id);
        }
    }
    const addChildHandler=(e,id,name)=>{
        e.stopPropagation();
        setOpenModal(true);
        setChildSelected({
            id,name
        });
    }   
    const assignGroupHandler=(e,id,name)=>{
        e.stopPropagation();
        setOpenModal(true);
        setAssignSelected({
            id,name
        });
    }   
    const editHandler=(e,id,name)=>{
        e.stopPropagation();
        setOpenModal(true);
        setEditSelected({
            id,name
        });
    }   
    const handleDeleteProductFromGroup=async(e,id,parent)=>{
        e.stopPropagation();
        setChildProduct({
            parentId:parent,
            productId:id
        })
        setOpenDeleteModal(true);
    }
    const handleCloseModal = ()=>{
        setOpenModal(false);
        setChildSelected({
            id:0,
            name:''
        });
        setEditSelected({
            id:0,
            name:''
        });
        setAssignSelected({
            id:0,
            name:''
        });
    }
  return (
    <div>
        <Box >
            {childData.map(x=>
                <div key={x.id} style={{paddingLeft:`3rem`}}>
                    <ListItemButton onClick={()=>handleClick(x.id)} >
                        {x.haschildren?
                            x.id === selectedItem  ? <ExpandLess /> : <ExpandMore />
                        :null}
                        <ListItemText primary={x.name} sx={x.type === 'group'?{}:{color:theme.palette.primary.main}}/>
                        {
                        x.type === 'group'?
                        <Box sx={{display:'flex',justifyContent:'space-around',width:'25rem'}}>
                            <Button size='small' variant="outlined" color="primary" onClick={(e)=>addChildHandler(e,x.id,x.name)}>
                            add child
                            </Button>
                            <Button size='small' variant="outlined" color="secondary" onClick={(e)=>assignGroupHandler(e,x.id,x.name)}>
                            assign product
                            </Button>
                            <IconButton size='small' onClick={(e)=>editHandler(e,x.id,x.name)}>
                                <Edit color='edit'/>
                            </IconButton>
                        </Box>:
                        <Box sx={{display:'flex',width:'25rem',justifyContent:'space-around'}}>
                            <div style={{width:'12rem'}}></div>
                            <IconButton size='small' onClick={()=>history.push(`/productProperties/${x.id}`)}>
                                <Visibility/>
                            </IconButton>
                            <IconButton size='small' onClick={(e)=>handleDeleteProductFromGroup(e,x.id,x.parent)}>
                                <Delete color='danger'/>
                            </IconButton>
                        </Box>
                        }
                    </ListItemButton>
                    {x.haschildren?
                    <div>
                        <Collapse in={x.id === selectedItem} timeout="auto" unmountOnExit></Collapse>
                        {x.id === selectedItem?<ProductGroupChild hasGroupChilderen={x.hasGroupChilderen} parentId={x.id}  haschildren={x.haschildren} />:null}
                    </div>
                :null}
                </div>
            )}
            <Box sx={style.rightSideContainer}>
                
            </Box>
        </Box>
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style.modalStyle}>
                {
                childSelected.id?
                    <AddGroupComponent childSelected={childSelected}/>
                :null
                }
                {
                editSelected.id?
                    <EditGroupComponent editSelected={editSelected}/>
                :null
                }
                {
                assignSelected.id?
                    <AssignToGroupComponent assignSelected={assignSelected}/>
                :null
                }
                
            </Box>
        </Modal>
        <DeleteModal 
            deleteURL={`${process.env.REACT_APP_BASE_URL}/ProductGroup/removeFromGroup/${childProduct.productId}/${childProduct.parentId}`}
            openDeleteModal={openDeleteModal}
            parameterName='product from group'
            setOpenDeleteModal={setOpenDeleteModal}
            setIsSuccessful={setDeleteProductSuccessFully}
        />
    </div>
  )
}

export default ProductGroupChild