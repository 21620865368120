import React, { useEffect, useState,Suspense } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {Header12} from '../layout/header12';
import Footer from '../layout/footer5';
import {homeSliderContent2Mobile, pronixProducts} from '../element/SliderContent'
//import HomeSlider from '../element/homeslider1'
import jwtDecode from 'jwt-decode';
import '../../css/plugins.css';
import '../../css/style.css';
import '../../css/skin/skin-1.css';
import '../../css/templete.min.css';
import ScrollToTop from '../element/scrollToTop';
import HomeSlider1 from '../element/homeslider1';
import {homeSliderContent2} from '../element/SliderContent';
// import ProjectSlider1 from '../element/projectSlider1';
// import MainProducts from '../layout/mainProducts';
import { getRequest } from '../../request';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from "react-responsive"; 
import PriceAtHome from '../element/priceAtHome';

// const bg = require('../../images/background/bg2.jpg' )
const bg2 = require('../../images/background/bg10.jpg' )
// const bg4 = require('../../images/main-slider/slide8.jpg' )
// const bg5 = require('../../images/background/PRONIX-Stationery-Desktop-Wallpaper-HD-01-4-scaled.jpg');
const style ={
    myTitle:{
        marginBottom: "5px",
        fontSize: '35px',
        lineHeight: '45px',
        fontWeight: '600px',
        cursor: 'pointer',
        '&:hover':{
            color:'red'
        }
    }
}

function Home() {
    const history = useHistory();
    const theme = useTheme(); 
    const [products, setProducts] = useState([]);
    const [rootProducts, setRootProducts] = useState([]);
    const [magazineList, setMagazineList] = useState([]);
    const [categories, setcategories] = useState([]);
    const [blogList, setBlogList] = useState([]);
    const [priceData, setPriceData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetchAllProducts();
        // fetchRootProducts();
        fetchCategories();
        fetchPriceTable();
        // fetchMagazines();
        fetchBlogs();
    }, []);
    const HomeBusiness = React.lazy(()=>import('./home-business-introduction'));
    const TopProducts = React.lazy(()=>import('../element/topProducts'));
    const CounterSection = React.lazy(()=>import('../element/counterSection'));
    const Team = React.lazy(()=>import('../element/team1'));
    const BlogSlider = React.lazy(()=>import('../element/blogSlider1'));

    const isDesktop = useMediaQuery({
        query: "(min-width: 1224px)"
    });

    const isTablet = useMediaQuery({
      query: "((min-width:650px) and (max-width: 900px))"
    });

    const isMobile = useMediaQuery({
        query: "((min-width:390px) and (max-width: 650px))"
    });
    const isLittleMobile = useMediaQuery({
      query: "(max-width: 390px)"
    });

    const token = localStorage.getItem("token");
    let decode = {};
    if (token) {
        decode = jwtDecode(token);
    }
    const fetchAllProducts = async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product`)
        if(data && Object.keys(data).length){
            setProducts(data);
        }else{
            toast.error(error);
        }
    }
    const fetchRootProducts = async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Industry`)
        if(data && Object.keys(data).length){
            data.map((x,index)=>x.image=pronixProducts[index].image);
            setRootProducts(data);
        }else{
            toast.error(error);
        }
    }
    const fetchCategories=async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductCategory`)
        const x=[]
        if(data && Object.keys(data).length){
            for(let i=0;i<data.length;i++){
                x.push({
                    id:data[i].productCategoryId,
                    title:data[i].productCategoryName,
                    image:pronixProducts[i].image
                })
            }
            setcategories(x);
        }else{
            toast.error(error);
        }
    }

    const fetchMagazines=async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Article`);
        if(data && Object.keys(data).length){
            setMagazineList(data);
        }else{
            toast.error(error);
        }
    }
    const fetchBlogs=async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/BlogPost`);
        if(data && Object.keys(data).length){
            const list = data;
            let d;
            for(let i=0;i<list.length;i++){
                d = new Date (list[i].updateDateTime);
                list[i].date = d.toString();
            }
            setBlogList(list.reverse());
        }else{
            toast.error(error);
        }
    }
    const fetchPriceTable = async ()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/prices`);
        if(data && Object.keys(data).length){
            for(let i=0;i<data.length;i++){
                if(data[i][0].type){
                    for(let j=0;j<data[i].length;j++){
                        data[i][j].customerPrice=data[i][j].customerPrice?.toLocaleString();
                    }
                }
            }
        setPriceData(data.filter(x=>x[0].type));
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        }else{
            toast.error(error);
        }
    }
    return (
        <div className="skin-1">
            <Header12/>
            <HomeSlider1 
                data={homeSliderContent2}
                mobileData={homeSliderContent2Mobile}
                contentWrapperCls="sliderStyle2"
                btnText="Get a Quote"
                hideBtn={false}
            />
            <div className="page-content bg-white">
                <div >
                    {/* <div className="section-full content-inner bg-gray mobileHeight content-block">
                        <div className="container">
                            <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                                <h1 
                                    style={style.myTitle}
                                    onClick={()=>history.push('/allProducts/page=1')}
                                    onMouseEnter={(e) => {
                                        e.target.style.color = theme.palette.primary.main;
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.color = '#000';
                                    }}
                                >Products</h1>
                            </div>
                            <div className="row">
                            {categories.map((product,index)=>
                                <div key={index} className="col-lg-3 col-md-6 col-sm-6 m-b30 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="dlab-box service-box-1">
                                        <div className="dlab-media radius-sm dlab-img-overlay1 dlab-img-effect zoom">
                                            <Link ><LazyLoadImage src={product.image} alt="" style={{height:'300px'}}/></Link>
                                        </div>
                                        <div className="dlab-info">
                                            <h4 className="title"><Link>{product.title}</Link></h4>
                                            <Link to={`/product/ProductCategory=${product.id}&&page=1`} className="site-button btnhover14">show products</Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>
                    </div> */}
                    <Suspense fallback={<div></div>}>
                        <TopProducts products={products}/>
                    </Suspense>
                    {/* <div style={{marginTop:'5rem',display:'none',justifyContent:'center'}}>
                        <div className='spinner-container'>
                            <img className='loading-spinner' style={{width:'6rem',height:'5rem'}} src={require('../../React-icon.png')} alt="" />
                        </div>
                    </div> */}
                    {priceData.length?
                        <Suspense fallback={<div></div>}>
                            <PriceAtHome rows={priceData}/>
                        </Suspense>:
                        null
                    }
                    <div className={isMobile?'aboutStyleMobile':isLittleMobile?'aboutStyleLittleMobile':'aboutStyle'}>
                        <button className={isDesktop?'aboutBtnStyle':isTablet?'aboutBtnStyleTablet':'aboutBtnStyleMobile'} onClick={()=>history.push('/aboutUs')}>Show More ...</button>
                    </div> 
                    {/* <Suspense fallback={<div></div>}>
                        <HomeBusiness/>
                    </Suspense> */}
                    {/* <div className="section-full content-inner-2 bg-gray wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="title">Our Project</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry's standard dummy text ever since the been when an unknown printer.</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <ProjectSlider1 data={topProducts}/>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    
                    
                        

                    {/* <div className="section-full content-inner-2 bg-gray wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="title">Our Project</h2>
                                    <p>Petroleum & Petrochemical Products Supply</p>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <ProjectSlider1 data={pronixProducts}/>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    */}
                    {/* <Suspense fallback={<div></div>}>
                        <CounterSection backgroundImage={bg2} productsCount={products.length} magazineList={magazineList.length}/>
                    </Suspense> */}
                    
                    
                    {/* <div id='team' className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-head text-center ">
                                <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
                                    <h2 onClick={()=>history.push('/team')} 
                                    style={style.myTitle}
                                    onMouseEnter={(e) => {
                                        e.target.style.color = theme.palette.primary.main;
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.color = '#000';
                                    }}> Meet The Team</h2>
                                </div>
                                <p>Our sales team is ready to advise and support your requests. 24 hours a day, 7 days a week</p>
                            </div>
                            <Suspense fallback={<div></div>}>
                                <Team />
                            </Suspense>
                        </div>
                    </div> */}
                    
                    {/* <div  className="section-full overlay-black-middle bg-secondry content-inner-2 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s" style={{display:'none !important', backgroundImage: "url(" + bg3 + ")" }}>
                        <div className="container">
                            <div className="section-head text-white text-center">
                                <h2 className="title">What People Are Saying</h2>
                                <p>Users comments about our products quality, services and supports </p>
                            </div>
                            <div className="section-content">
                                <Testimonial12/>
                                
                            </div>
                  
                        </div>
                    </div> */}
                    <div className={isDesktop?'blogBackGround':isTablet? 'blogBackGroundTablet':isMobile?'blogBackGroundMobile':'blogBackGroundLittleMobile'}>
                        <div id='news' className="section-full content-inner wow fadeIn newsSection" data-wow-duration="2s" data-wow-delay="0.4s" >
                                <div className="container" style={isTablet?{marginTop:'-14rem'}:null}>
                                    <div className="section-head text-center">
                                    <div style={{display:'flex',width:'100%',justifyContent:'center'}}>
                                        <h2 
                                        className="myTitle"
                                        style={{color:'#fff',borderBottom:'4px solid #fff'}}
                                        onMouseEnter={(e) => {
                                            e.target.style.color = '#000';
                                            e.target.style.borderBottom = '4px solid #000';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.color = '#fff';
                                            e.target.style.borderBottom = '4px solid #fff';
                                        }}
                                        onClick={()=>history.push('/blogList')}
                                        >Latest News</h2>
                                    </div>
                                    {decode.role === 'SuperAdmin'?<Link className="site-button btnhover14" to={'/blog/0'}> start new blog</Link>:null}
                                    </div>
                                    <div style={{marginTop:'5rem'}}>
                                        <Suspense fallback={<div></div>}>
                                            <BlogSlider blogList={blogList}/>
                                        </Suspense>
                                    </div>
                                </div>
                            </div>
                    </div>
                    {/* <div className="section-full dlab-we-find bg-img-fix p-t20 p-b20 bg-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                        <div className="container">
                            <div className="section-content">
                                <ClientSlider1 hideBorder/>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>          
            <Footer hideContactInfo displayNewsLetter />
            <ScrollToTop className="icon-up"/>
        </div>
    )
}

export default Home;

