import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer5 from "../layout/footer5";
import {Header12} from "../layout/header12";
import { Add } from "@mui/icons-material";
import { getRequest } from "../../request";
import { toast } from "react-toastify";

function ProductList() {
  const [productsData, setProductsData] = useState([]);
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const { data, error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product`)
      if(data && Object.keys(data).length){
        data.map((x) => {
          setProductsData((prevstate) => [
            ...prevstate,
            {
              id: x.productId,
              code: x.productCode,
              name: x.productLocalName,
              description: x.productDescription,
              subCategory: x.subCategory.productSubCategoryName,
              industry: x.industry.industryName,
            },
          ]);
        });
      }else{
        toast.error(error);
      }
  };
  return (
    <div>
      <Header12 />
      {!productsData.length ? (
        <Box
          sx={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#ccc",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <div className="page-content bg-white">
          <div className="section-full content-inner bg-gray">
            <div className="container">
                <Link
                  style={{marginBottom:'1rem'}}
                  to={`/productProperties`}
                  className="site-button btnhover14"
                >
                  New Product
                  <Add />
                </Link>
              <div className="row">
                {productsData.map((product, index) => (
                  <div
                    key={index}
                    className="col-lg-3 col-md-6 col-sm-6 m-b30 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.3s"
                  >
                    <div className="dlab-box service-box-2">
                      <div className="dlab-media radius-sm dlab-img-overlay1 dlab-img-effect rotate">
                        <Link to="/services-details"></Link>
                      </div>
                      <div className="dlab-info">
                        <h4 className="title">
                          <Link to="/services-details">{product.name}</Link>
                        </h4>
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "auto auto",
                          }}
                        >
                          <Typography sx={{ textAlign: "center" }}>
                            code:
                          </Typography>
                          <Typography sx={{ textAlign: "center" }}>
                            {product.code}
                          </Typography>
                          <Typography sx={{ textAlign: "center" }}>
                            subCategory:
                          </Typography>
                          <Typography sx={{ textAlign: "center" }}>
                            {product.subCategory}
                          </Typography>
                          <Typography sx={{ textAlign: "center" }}>
                            industry:
                          </Typography>
                          <Typography sx={{ textAlign: "center" }}>
                            {product.industry}
                          </Typography>
                        </Box>

                        <Link
                          to={`/singleProduct/${product.id}`}
                          className="site-button btnhover14"
                        >
                          Show Details
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer5 />
    </div>
  );
}

export default ProductList;
