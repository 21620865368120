import React,{useEffect,useState} from 'react'
import { toast } from 'react-toastify';
import { getRequest } from '../../request';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ProductGroupChild from '../components/productGroupChild';
import Box from '@mui/material/Box';
import { Button, IconButton, Modal, Typography } from '@mui/material';
import { AccountCircle, Add, Edit } from '@mui/icons-material';
import AddGroupComponent from '../components/addGroupComponent';
import {Header12} from "../layout/header12";
import EditGroupComponent from '../components/editGroupComponent';
import AssignToGroupComponent from '../components/assignToGroupComponent';
import Footer5 from '../layout/footer5';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import SideBar from '../components/sideBar';

const style = {
    modalStyle:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: '3px',
        boxShadow: 24,
        p: 4,
    },
    rightSideContainer:{
        display:'flex',
        flexDirection:'column',
    },
    mainContainer:{
        display:'flex',
        justifyContent:'center',
        bgcolor: 'background.paper',
        height:'82.5vh',
        alignItems:'center'
    },
    btn:{
        marginRight:'1rem'
    },
    btnContainer:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between'
    }
}
const bg = require("./../../images/banner/bnr5.jpg");

function ProductGroups() {
    const [rootGroups, setRootGroups] = useState([]);
    const [selectedItem, setSelectedItem] = useState(0);
    const [newRoot, setNewRoot] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const token = localStorage.getItem('token');
      let decode={};
      if(token){
        decode = jwtDecode(token);
      }
    const [openUserModal, setOpenUserModal] = useState(false);
    const [assignSelected, setAssignSelected] = useState({
        id:0,
        name:''
    });
    const [childSelected, setChildSelected] = useState({
        id:0,
        name:''
    });
    const [editSelected, setEditSelected] = useState({
        id:0,
        name:''
    });
    
    useEffect(() => {
     fetchProductsGroup();
    }, []);
    const fetchProductsGroup =async()=>{
        const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductGroup/roots`);
        if(data && Object.keys(data).length){
            setRootGroups(data);
        }else{
            toast.error(error);
        }
    }
    const handleClick=(id)=>{
        if(id === selectedItem){
            setSelectedItem(0);
        }else{
            setSelectedItem(id);
        }
    }
    const handleCloseModal = ()=>{
        setOpenModal(false);
        setNewRoot(false);
        setChildSelected({
            id:0,
            name:''
        });
        setEditSelected({
            id:0,
            name:''
        });
        setAssignSelected({
            id:0,
            name:''
        });
    }
    const addChildHandler=(e,id,name)=>{
        e.stopPropagation();
        setOpenModal(true);
        setChildSelected({
            id,name
        });
    }   
    const assignGroupHandler=(e,id,name)=>{
        e.stopPropagation();
        setOpenModal(true);
        setAssignSelected({
            id,name
        });
    }   
    const editHandler=(e,id,name)=>{
        e.stopPropagation();
        setOpenModal(true);
        setEditSelected({
            id,name
        });
    }   
    const handleAddNewRoot=()=>{
        setOpenModal(true);
        setNewRoot(true);
    }
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setOpenUserModal(open);
      };
  return (
    <div>
        <Header12/>
        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div className="container">
                <div className="dlab-bnr-inr-entry">
                    <h1 className="text-white">Product Group</h1>
                    <div className="breadcrumb-row">
                        <ul className="list-inline">
                            <li><Link to="/">Home</Link></li>
                            <li>Product Group</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* {decode.role === 'SuperAdmin'?
            <Button color='secondary' sx={style.btn}  variant='contained' onClick={toggleDrawer( true)} >user options<AccountCircle/></Button>
        :null} */}
        <Box sx={style.mainContainer}>
            
            <List
                sx={{ width: '70%',  bgcolor: 'background.paper',height:'90%',boxShadow:'0px 0px 3px 3px #ddd', borderRadius:'0.3rem' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
            <Typography variant="h5" color="initial" sx={{marginLeft:'1rem'}}>Groups Of Products</Typography>
            <div style={style.btnContainer}>
                <Button variant='contained' color='primary' onClick={handleAddNewRoot} sx={{marginLeft:'1rem'}}>add new root <Add/></Button>
                {decode.role === 'SuperAdmin'?
                    <Button color='secondary'  variant='contained' sx={style.btn} onClick={toggleDrawer( true)} >user options <AccountCircle/></Button>
                :null}
            </div>
                {rootGroups.map(x=>
                <div key={x.id}>
                    <ListItemButton onClick={()=>handleClick(x.id)}>
                        {
                        x.haschildren?
                            x.id === selectedItem  ? <ExpandLess /> : <ExpandMore />
                        :null
                        }
                        <ListItemText primary={x.name} />
                        <Box sx={{display:'flex',justifyContent:'space-around',width:'25rem'}}>
                            <Button size='small' variant="outlined" color="primary" onClick={(e)=>addChildHandler(e,x.id,x.name)}>
                                add child
                            </Button>
                            <Button size='small' variant="outlined" color="secondary" onClick={(e)=>assignGroupHandler(e,x.id,x.name)}>
                                assign product
                            </Button>
                            <IconButton size='small' onClick={(e)=>editHandler(e,x.id,x.name)}>
                                <Edit color='edit'/>
                            </IconButton>
                        </Box>
                    </ListItemButton>
                    {x.haschildren?
                        <div>
                            <Collapse in={x.id === selectedItem} timeout="auto" unmountOnExit></Collapse>
                            {x.id === selectedItem?<ProductGroupChild hasGroupChilderen={x.hasGroupChilderen} parentId={x.id} haschildren={x.haschildren}/>:null}
                        </div>
                    :null}

                </div>
                )}
            </List>
        </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style.modalStyle}>
                    {
                    childSelected.id||newRoot?
                        <AddGroupComponent childSelected={childSelected}/>
                    :null
                    }
                    {
                    editSelected.id?
                        <EditGroupComponent editSelected={editSelected}/>
                    :null
                    }
                    {
                    assignSelected.id?
                        <AssignToGroupComponent assignSelected={assignSelected}/>
                    :null
                    }
                    
                </Box>
            </Modal>
        <Footer5/>
        <SideBar open={openUserModal} toggleDrawer={toggleDrawer} />

    </div>
  )
}

export default ProductGroups