import React, { useState, useEffect } from "react";
import {
  AccountCircle,
  Close,
  Delete,
  Edit,
  Save,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Footer5 from "../layout/footer5";
import {Header12} from "../layout/header12";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../request";
import DeleteModal from "../components/deleteModal";
import { Link, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import SideBar from "../components/sideBar";

const styles = {
  containerBox2: {
    display: "grid",
    gridTemplateColumns: "auto ",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  containerBox3: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gap: "2%",
    alignItems: "center",
    justifyContent: "space-around",
    height: "81vh",
  },
  box3: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "37rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  box2: {
    boxShadow: "0px 0px 6px 2px #ddd",
    width: "45rem",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  innerBox: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "auto auto ",
    justifyContent: "space-around",
  },
  types: {
    // backgroundColor: "#ccc",
    // borderRadius: "0.5rem 0.5rem 0rem 0rem",
    width: "100%",
    display: "flex",
    // justifyContent: "center",
  },
  newclass1: {
    position: "relative",
    width: "unset",
    background: "none",
    top:'-1.8rem',
    left:'1rem'
  },
  newclass2: {
    position: "relative",
    width: "unset",
    background: "none",
    top:'-1.8rem',
    left:'1rem'
  },
};
const bg = require("./../../images/banner/bnr5.jpg");
function DefineSpecType() {
  const params = useParams();
  const code = (Math.random() * 1000000).toFixed(0);
  const [productSpecType, setproductSpecType] = useState({
    productSpecTypeCode: `specType-${code}`,
    productSpecTypeName: "",
    productSpecTypeLocalName: "",
  });
  const [editedSpecType, seteditedSpecType] = useState("");
  const [productSpecTypeList, setproductSpecTypeList] = useState([]);
  const [selectedproductSpecType, setSelectedproductSpecType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deletedCategoryId, setDeletedCategoryId] = useState(-1);
  const [deleteSuccessful, setDeleteSuccessful] = useState(false);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem('token');
  let decode={};
  if(token){
    decode = jwtDecode(token);
  }
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };
  useEffect(() => {
    fetchSpecType();
    if(params.specTypeId !== "0"){
        loadSpecType();
    }
  }, []);
  useEffect(() => {
    if(deleteSuccessful){
      setproductSpecTypeList(prevstate=>[...prevstate.filter(x=>x.productSpecTypeId !== deletedCategoryId)]);
      setDeleteSuccessful(false);
      setDeletedCategoryId(-1);
    }
  }, [deleteSuccessful]);
  
  const loadSpecType = async()=>{
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecType/${params.specTypeId}`)
      if(data && Object.keys(data).length){
        setproductSpecType({
            productSpecTypeCode: data.productSpecTypeCode,
            productSpecTypeName: data.productSpecTypeName,
            productSpecTypeLocalName: data.productSpecTypeLocalName,
        });
      }else{
        toast.error(error);
      }
  }
  const fetchSpecType = async () => {
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecType`)
      if(data && Object.keys(data).length){
        setproductSpecTypeList(data);
      }else{
        toast.error(error);
      }
  };
  const handleChange = (event) => {
    setproductSpecType({
      ...productSpecType,
      [event.target.name]: event.target.value,
    });
  };

  const handleRemoveSpecType = async (id) => {
    setOpenModal(true);
    setDeletedCategoryId(id);
  };

  const handleAddproductSpecType = async () => {
    const {message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecType`,productSpecType)
    if (message === 'ok') {
      const code = (Math.random() * 1000000).toFixed(0);
      setproductSpecTypeList((prevstate) => [...prevstate, productSpecType]);
      toast.success("product spec type added successfully!");
      setproductSpecType({
        productSpecTypeCode: `specType-${code}`,
        productSpecTypeName: "",
        productSpecTypeLocalName: "",
      });
    }
  };
  const editCategory = async (id) => {
    const newSpecType = {
      ...productSpecType,
      productSpecTypeId: editedSpecType,
    };
    const {message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecType/edit`,newSpecType);
    if (message === 'ok') {
      productSpecTypeList[
        productSpecTypeList.findIndex(
          (x) => x.productSpecTypeId === editedSpecType
        )
      ] = productSpecType;
      setproductSpecTypeList(productSpecTypeList);
      setSelectedproductSpecType("");
      setproductSpecType({
        productSpecTypeCode: `specType-${code}`,
        productSpecTypeName: "",
        productSpecTypeLocalName: "",
      });
      toast.success("product spec type edited successfully!");
    }
  };
  return (
    <div>
      <Header12/>
      <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
        <div className="container">
            <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Define Spec Type</h1>
                <div className="breadcrumb-row">
                    <ul className="list-inline">
                        <li><Link to="/">Home</Link></li>
                        <li>Define Spec Type</li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
      {decode.role === 'SuperAdmin'?
      <Button color='secondary' variant='contained' onClick={toggleDrawer( true)} sx={{margin:'1rem 3rem',position:"absolute"}}>user options<AccountCircle/></Button>:null}
      <Box
        component="form"
        sx={
          selectedproductSpecType ? styles.containerBox3 : styles.containerBox2
        }
      >
        <Box>
          <Box sx={selectedproductSpecType ? styles.box3 : styles.box2}>
          <Box sx={styles.types}>
            <Typography
              variant="h5"
              color="initial"
              sx={selectedproductSpecType ? styles.newclass2 : styles.newclass1}
            >
              <span>Spec Types</span>
            </Typography>
          </Box>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="Code"
                sx={{ width: "250px" }}
                name="productSpecTypeCode"
                value={productSpecType.productSpecTypeCode}
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                label="Name"
                sx={{ width: "250px" }}
                name="productSpecTypeName"
                value={productSpecType.productSpecTypeName}
                onChange={handleChange}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                variant="outlined"
                label="localName"
                sx={{ width: "250px" }}
                name="productSpecTypeLocalName"
                value={productSpecType.productSpecTypeLocalName}
                onChange={handleChange}
              />
              <div style={{ width: "250px" }}></div>
            </div>
            <div style={styles.innerBox}>
              <div style={{ width: "250px" }}>
                {editedSpecType  || params?.specTypeId !=='0' ? (
                  <ButtonGroup>
                    <Button
                      variant="contained"
                      color="danger"
                      sx={{ color: "#efefef" }}
                      onClick={() => {
                        setSelectedproductSpecType("");
                        seteditedSpecType("");
                        setproductSpecType({
                          productSpecTypeCode: `SpecType-${code}`,
                          productSpecTypeName: "",
                          productSpecTypeLocalName: "",
                        });
                      }}
                    >
                      cancel
                      <Close />
                    </Button>
                    <Button
                      variant="contained"
                      color="edit"
                      sx={{ color: "#efefef" }}
                      onClick={editCategory}
                    >
                      edit
                      <Edit />
                    </Button>
                  </ButtonGroup>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !productSpecType.productSpecTypeCode ||
                      !productSpecType.productSpecTypeName ||
                      !productSpecType.productSpecTypeLocalName
                    }
                    sx={{ color: "white" }}
                    onClick={handleAddproductSpecType}
                  >
                    save
                    <Save />
                  </Button>
                )}
              </div>
              <div style={{ width: "211px" }}></div>
            </div>
            <Box sx={{ width: "100%", padding: "0rem 3rem", }}>
              <TableContainer 
              component={Paper}
              sx={{height:'24rem',overflow:'auto'}}
              >
                <Table
                  sx={{ width: "95%", margin: "0.5rem" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>code</TableCell>
                      <TableCell align="right">name</TableCell>
                      <TableCell align="right">edit</TableCell>
                      <TableCell align="right">remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productSpecTypeList.length
                      ? productSpecTypeList.map((row) => (
                          <TableRow
                            key={row.code}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.productSpecTypeCode}
                            </TableCell>
                            <TableCell align="right">
                              {row.productSpecTypeName}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={() =>{
                                  seteditedSpecType(row.productSpecTypeId);
                                  setproductSpecType(
                                    productSpecTypeList.find(
                                      (x) =>
                                        x.productSpecTypeId ===
                                        row.productSpecTypeId
                                    )
                                  );
                                }}
                              >
                                <Edit color="edit" />
                              </IconButton>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label=""
                                onClick={() =>
                                  handleRemoveSpecType(row.productSpecTypeId)
                                }
                              >
                                <Delete color="danger" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
      <SideBar open={open} toggleDrawer={toggleDrawer}/>
      <Footer5 />
      {openModal?
       <DeleteModal openDeleteModal={openModal} setOpenDeleteModal={setOpenModal} parameterName="product category" deleteURL={`${process.env.REACT_APP_BASE_URL}/productSpecType/delete/${deletedCategoryId}`} destination={'NotLoad'} setIsSuccessful={setDeleteSuccessful}/>
        :null}
    </div>
  );
}

export default DefineSpecType;
