import React, {useState,useEffect} from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {Header12} from '../layout/header12';
import Footer5 from '../layout/footer5';
import ProfileSideBar from '../components/profileSideBar';
import { Box } from '@mui/system';
import { getRequest } from '../../request';
import jwtDecode from 'jwt-decode';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { AccountCircle, Visibility } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import Loading from "../components/loading";
import SideBar from '../components/sideBar';

const bnr = require("./../../images/banner/bnr5.jpg");

export default function TemporaryDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const token = localStorage.getItem('token');
  let decode={};
  if(token){
    decode = jwtDecode(token);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };
  useEffect(() => {
      userProfile();
  }, []);
  
const userProfile = async()=>{
  const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/User/GetUsers`);
  if(data && Object.keys(data).length){
    setUserList(data);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }else{
    toast.error(error)
  }
}

  return (
    <div>
        <Header12/>
        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
        <div className="container">
            <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Profile</h1>
                <div className="breadcrumb-row">
                    <ul className="list-inline">
                        <li><Link to="/">Home</Link></li>
                        <li>Profile</li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
        <Box sx={{height:'82vh'}}>
            {decode.role === 'SuperAdmin'?
            <Button color='primary' variant='contained' onClick={toggleDrawer( true)} sx={{margin:'1rem 3rem'}}>user options <AccountCircle/></Button>
            :null}
            {!isLoading?
            <Box sx={{width:'100%',display:'flex', justifyContent:'center'}}>
              <TableContainer
                component={Paper}
                sx={{width:'70%',maxHeight:'41rem',}}
              >
                <Table
                  // sx={{ margin: "0.5rem" }}
                  aria-label="simple table"
                >
                  <TableHead sx={{backgroundColor:theme.palette.primary.main}}>
                    <TableRow>
                      <TableCell align='center' sx={{color:'#fff'}}>UserName</TableCell>
                      <TableCell align="center" sx={{color:'#fff'}}>Email</TableCell>
                      <TableCell align="center" sx={{color:'#fff'}}>Phone Number</TableCell>
                      <TableCell align="center" sx={{color:'#fff'}}>Role</TableCell>
                      <TableCell align="center" sx={{color:'#fff'}}>Details / Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userList.
                    slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {row.userName}
                        </TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.phoneNumber?row.phoneNumber:'-'}</TableCell>
                        <TableCell align="center">{row.role?row.role:'-'}</TableCell>
                        <TableCell align="center">
                          <IconButton onClick={()=>history.push(`userProfile/${row.id}`)}>
                            <Visibility/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={userList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              </TableContainer>
            </Box>
            :
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "82.3vh",
              }}
            >
              <Loading type="cylon" color="#000" />
            </Box>
            }
            <SideBar open={open} toggleDrawer={toggleDrawer}/>
        </Box>
        <Footer5/>
    </div>
  );
}