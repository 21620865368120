import axios from'axios';
import {token} from './markup/layout/header12';

export const getRequest=async(reqURL)=>{
    try{
        const { status, data } = await axios.get(
            `${reqURL}`,
            token ? { headers: { Authorization: `Bearer ${token}` } } : null
        );
        if(status === 200){
            return({data:data})
        }
    }catch(err){
        if(err.response.status === 401){
            setTimeout(() => {
                window.location.replace('/login');
            }, 0);
            return({error:'you must login again!'})
        }
        else if(err.response.status === 404){
            return({error:'Invalid URL Requested!'})
        }
        else if(err.response.status === 500){
            return({error:'Server Error Occurred!'})
        }
        else if(err.response.status === 400){
            return({error:'This Item Has Some Dependencies'})
        }
    }
} 

export const postRequest = async(reqURL,postData)=>{
    try{
        const { status, data } = await axios.post(
            `${reqURL}`,postData,
            token ? { headers: { Authorization: `Bearer ${token}` } } : null
        );
        if(status === 200){
            return({data:data,message:'ok'})
        }
    }catch(err){
        if(err.response.status === 401){
            setTimeout(() => {
                window.location.replace('/login');
            }, 0);
            return({error:'you must login again!'})
        }
        else if(err.response.status === 404){
            return('invalid url requested')
        }
    }
}