import React,{useEffect,useState} from 'react'
import { getRequest, postRequest } from '../../request';
import { Box } from "@mui/system";
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Delete, Edit, Visibility } from '@mui/icons-material';
import { toast } from 'react-toastify';
import DeleteModal from './deleteModal';

const styles = {
    containerBox: {
      display: "grid",
      gridTemplateColumns: "2fr 2fr 2fr 2fr",
      gap: "2%",
      alignItems: "center",
      justifyContent: "space-around",
      height: "81vh",
      margin: "0rem 5rem",
    },
    box: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "70vh",
      display: "flex",
      flexDirection: "column",
    },
    box1: {
      boxShadow: "0px 0px 6px 2px #ddd",
      height: "76vh",
      width:'34rem',
      // width: "40rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin:'2rem 1rem'
    },
    innerBox: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "auto auto",
      marginBottom:'0.5rem',
      justifyContent: "space-around",
    },
  };

function Units({selectedContinent,selectedUnitSubCategory, unitList,setUnitList,selectedUnit}) {
    const [deletedUnitId, setDeletedUnitId] = useState(-1);
    const [deleteSuccessfull, setDeleteSuccessfull] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [editedId, setEditedId] = useState(-1);
    const code = (Math.random() * 1000000).toFixed(0);
    const [unit, setunit] = useState({
        unitCode: `unit-${code}`,
        unitName: "",
        unitLocalName: ""
    });
    // const fetchunit=async()=>{
    //     const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/unit`);
    //     if(data && Object.keys(data).length){
    //         setunitList(data);
    //     }
    // }
    useEffect(() => {
      if(deleteSuccessfull){
        setUnitList(prevstate=>[...prevstate.filter(x=>x.unitId !== deletedUnitId)])
        setDeleteSuccessfull(false);
        setDeletedUnitId(-1);
      }
    }, [deleteSuccessfull]);
    const handleChangeProducer = (event) => {
        setunit({
          ...unit,
          [event.target.name]: event.target.value,
        });
    };
    const handleEditProducer=async()=>{
      const currentUnit= {
        ...unit,
        unitId:editedId,
      }
      const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/Unit/edit`,currentUnit)
      if(message ==='ok'){
        unitList[
          unitList.findIndex(
            (x) => x.unitId === editedId
          )
        ] = unit;
        setUnitList(unitList);
        setunit({
          unitCode: `unit-${code}`,
          unitName: "",
          unitLocalName: "",
        });
        toast.success('Unit edited successfully!');
      }
    }
    const handleAddUnit=async()=>{
      const tempData = {...unit,unitSubCategoryId:selectedUnitSubCategory}
      const {message}= await postRequest(`${process.env.REACT_APP_BASE_URL}/Unit`,tempData);
        if(message === 'ok'){
            toast.success('Unit Added Successfully!');
            setUnitList((prevState)=>[...prevState,unit ]);
            setunit({
              unitCode: `unit-${code}`,
              unitName: "",
              unitLocalName: ""
            });
        }
    }
    const handleRemoveUnit = async(id) => {
      setOpenModal(true);
      setDeletedUnitId(id);        
    };
    
  return (
    <div>
        <Box sx={styles.box1}>
          <Box sx={{display:'flex',width:'100%',justifyContent:'initial'}}>
            <Typography
              sx={{
                position:'relative',
                fontSize: "1.5rem",
                fontWeight: "bold",
                top:'-1.2rem',
                left:'1rem',
              }}
            >
              Unit
            </Typography>
            </Box>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="Code"
                name="unitCode"
                value={unit.unitCode}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
              <TextField
                required
                size="small"
                variant="outlined"
                label="Name"
                name="unitName"
                value={unit.unitName}
                sx={{width:'170px'}}
                onChange={handleChangeProducer}
              />
            </div>
            <div style={styles.innerBox}>
              <TextField
                size="small"
                variant="outlined"
                label="localName"
                name="unitLocalName"
                sx={{width:'170px'}}
                value={unit.unitLocalName}
                onChange={handleChangeProducer}
              />
              <Box sx={{width:'170px'}}></Box>
            </div>
            {editedId !==-1?(
              <div style={styles.innerBox}>
              <Box sx={{ minWidth: 170 }}>
                <ButtonGroup variant="contained">
                  <Button color="danger" sx={{ color: "#efefef" }} 
                    onClick={()=>{
                        setunit({
                            unitCode: `unit-${code}`,
                            unitName: "",
                            unitLocalName: ""
                        });
                        setEditedId(-1);
                    }}
                  >
                    cancel <Close />
                  </Button>
                  <Button
                    color="edit"
                    sx={{ color: "#efefef" }}
                    onClick={handleEditProducer}
                  >
                    edit <Edit />
                  </Button>
                </ButtonGroup>
              </Box>
              <Box sx={{ minWidth: 170 }}>
                
              </Box>
            </div>
            ):(
              <div style={styles.innerBox}>
                <Box sx={{ minWidth: 170 }}>
                  <ButtonGroup variant="contained">
                    <Button
                      color="primary"
                      sx={{ color: "#efefef" }}
                      onClick={handleAddUnit}
                      disabled={
                        !unit.unitCode ||
                        !unit.unitLocalName ||
                        !unit.unitName
                      }
                    >
                      add <Add />
                    </Button>
                  </ButtonGroup>
                </Box>
                <Box sx={{ minWidth: 170 }}></Box>
              </div>
            )}
          {unitList.length ?
            <TableContainer
              sx={{width:'96%'}}
              component={Paper}
            >
              <Table aria-label="simple table"  sx={{maxHeight:"50rem",}}>
                <TableHead>
                  <TableRow>
                    <TableCell>Unit Code</TableCell>
                    <TableCell>Unit Name</TableCell>
                    <TableCell align="right">Edit</TableCell>
                    <TableCell align="right">Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unitList?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.unitCode}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.unitName}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="edit"
                          onClick={()=>{
                            setEditedId(row.unitId);
                            setunit(unitList.find(
                              (x) =>
                                x.unitId ===
                                row.unitId
                            ))
                          }
                          }
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="danger"
                          onClick={()=>
                            handleRemoveUnit(row.unitId)
                          }
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          :null}
        </Box>
          
        {openModal?
        <DeleteModal openDeleteModal={openModal} setOpenDeleteModal={setOpenModal} parameterName="Unit" deleteURL={`${process.env.REACT_APP_BASE_URL}/Unit/delete/${deletedUnitId}`} destination={'NotLoad'} setIsSuccessful={setDeleteSuccessfull}/>
        :null}
    </div>
  )
}

export default Units