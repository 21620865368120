import { Drawer } from '@mui/material'
import React from 'react'
import ProfileSideBar from './profileSideBar'

function SideBar({open,toggleDrawer}) {
    
  return (
    <div>
        <Drawer
            anchor={'right'}
            open={open}
            onClose={toggleDrawer( false)}
        >
            <ProfileSideBar toggleDrawer={toggleDrawer}/>
        </Drawer>
    </div>
  )
}

export default SideBar