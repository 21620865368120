import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  email:'',
  userName:''
}

export const loginSlice = createSlice({
  name: 'loginUser',
  initialState,
  reducers: {
    setUser:(state,action)=>{
        state.email = action.payload.email;
        state.userName = action.payload.userName
    }
  },
})

export const {setUser} = loginSlice.actions;
export default loginSlice.reducer