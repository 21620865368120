import React, { useState, useEffect } from "react";
import {Header12} from "../layout/header12";
import Footer from "../layout/footer5";
import Button from "@mui/material/Button";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  ButtonGroup,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import jwtDecode from "jwt-decode";
import { Edit } from "@mui/icons-material";
import { getRequest } from "../../request";
import { toast } from "react-toastify";
import Loading from "../components/loading";


const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const styles = {
  tableBox: {
    display: "flex",
    width: "100%",
  },
  container: {
    display: "flex",
    height: "81.3vh",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 0px 6px 2px #bbbbbb",
  },
  productInfo: {
    backgroundColor: "#fff",
    padding: "2rem",
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "column",
  },
  scrollText: {
    height: "130px",
    overflowY: "scroll",
  },
};
function SingleProduct() {
  const history = useHistory();
  const params = useParams();
  const [imageList, setimageList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageSelected, setImageSelected] = useState(0);
  const [product, setProduct] = useState({});
  const [specs, setSpecs] = useState([]);
  const [valueData, setValueData] = useState([]);
  const [productProducers, setProductProducers] = useState([]);
  const [isShowMore, setIsShowMore] = useState(false);
  const token = localStorage.getItem("token");
  const noImgFound = require("../../images/our-work/chemicals/No_Image_Available.jpg");
  let decode = {};
  if (token) {
    decode = jwtDecode(token);
  }

  const fetchData = async () => {
    const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/${params.productId}`)
      if(data && Object.keys(data).length){
        setProduct(data);
        setTimeout(() => {  
          setIsLoading(true);
        }, 1500);
      }else{
        toast.error(error);
      }
  };
  const fetchSpecs = async () => {
    const unitList = [];
    const response = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/specs/${params.productId}`)
      if(response.data && Object.keys(response.data).length){
        
      setSpecs(response.data);
      const res = [];
      for (let i = 0; i < response.data.length; i++) {
        res[i] = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec/values/${response.data[i].productSpecId}`)
          res[i].data.map((x) => {
            unitList.push(x);
          });
      }
      }else{
        toast.error(response.error);
      }
    setValueData(unitList);
    setimageList(unitList.filter((x) => x.unit.unitName === "image"));
  };
  const fetchProductsProducers = async () => {
    const { data, error } = await getRequest(`${process.env.REACT_APP_BASE_URL}/Product/producers/${params.productId}`)
      if(data && Object.keys(data).length){
        setProductProducers(data);
      }else{
        toast.error(error);
      }
    
  };
  const handleChangeImage = (id) => {
    // setMainImage(pronixProducts[id].image);
  };
  useEffect(() => {
    fetchData();
    fetchSpecs();
    fetchProductsProducers();
  }, []);

  return (
    <div>
      <Header12 />
      <Box sx={styles.container}>
        {isLoading?
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            height: "70vh",
            backgroundColor: "#DFE1E3",
            margin: "5rem",
            overflowY: "auto"
          }}
        >
          <Box>
            {imageList.length ? (
              <Box
                sx={{
                  height: "60%",
                  boxShadow: "0px 0px 6px 2px #bbbbbb",
                  margin: "3rem 5rem",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_IMG_URL}`+imageList[imageSelected].productSpecValueAmount}
                  alt="product-image"
                  style={{ height: "100%", width: "100%" }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  height: "60%",
                  boxShadow: "0px 0px 6px 2px #bbbbbb",
                  margin: "3rem",
                }}
              >
                <img
                  src={noImgFound}
                  alt="product-image"
                  style={{ height: "100%", width: "100%" }}
                />
              </Box>
            )}
            <Box
              sx={{ width: "425px", padding: "0.5rem", margin: "0rem 4rem", display:'flex' }}
            >
              {imageList.length
                ? imageList.map((item, id) => (
                    <div
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        padding: "0px 3px",
                      }}
                      key={id}
                    >
                      <div onClick={() => setImageSelected(id)}>
                        {/* <Link to="/portfolio-details"> */}
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}`+item.productSpecValueAmount}
                          alt=""
                          style={{
                            height: "5rem",
                            width: "5rem",
                            borderRadius: "3px",
                            boxShadow: "0px 0px 1px 2px  #ddd",
                            display: "flex",
                          }}
                        />
                        {/* </Link> */}
                      </div>
                    </div>
                  ))
                : null}
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#F1F1F1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "4rem",
            }}
          >
            <Box sx={styles.productInfo}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                {product.productName}
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {valueData.find((x) => x.unit.unitName === "price")
                  ? valueData.find((x) => x.unit.unitName === "price")
                      .productSpecValueAmount + " $"
                  : null}
              </Typography>
              <Typography
                className={
                  product.productDescription?.length < 400
                    ? ""
                    : isShowMore
                    ? "scrollText"
                    : "paragText"
                }
              >
                {product.productDescription}
              </Typography>
              {product.productDescription?.length < 400 ? null : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setIsShowMore(!isShowMore)}
                >
                  {isShowMore ? "show less" : "show more"}
                </Button>
              )}
              {/* <Button variant="contained" color="secondary" onClick={()=>setIsShowMore(!isShowMore)}>
                
              </Button> */}
              {specs?.filter(
                (x) =>
                  x.productSpecName !== "image" && x.productSpecName !== "price"
              ).length ? (
                <Box sx={styles.tableBox}>
                  {specs.length ? (
                    <TableContainer
                      sx={
                        productProducers.length
                          ? { width: "75%", margin: "0.5rem" }
                          : { width: "100%", margin: "0.5rem" }
                      }
                      component={Paper}
                    >
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>spec</TableCell>

                            <TableCell align="right">amount</TableCell>
                            <TableCell align="right">unit</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {specs
                            ?.filter(
                              (x) =>
                                x.productSpecName !== "image" &&
                                x.productSpecName !== "price"
                            )
                            ?.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.productSpecName}
                                </TableCell>
                                <TableCell align="right">
                                  {valueData.length &&
                                    valueData?.filter(
                                      (x) =>
                                        x.productSpecId === row.productSpecId
                                    )[0]?.productSpecValueAmount}
                                </TableCell>
                                <TableCell align="right">
                                  {valueData.length &&
                                    valueData?.filter(
                                      (x) =>
                                        x.productSpecId === row.productSpecId
                                    )[0]?.unit.unitName}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : null}
                  {productProducers.length ? (
                    <List
                      sx={{
                        bgcolor: "background.paper",
                        width: "25%",
                        borderRadius: "3px",
                        boxShadow: "0px 1px 2px 0px",
                      }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      subheader={
                        <ListSubheader
                          component="div"
                          id="nested-list-subheader"
                        >
                          Product Producers
                          <Divider />
                        </ListSubheader>
                      }
                    >
                      {productProducers.map((producer) => (
                        <ListItemButton>
                          <ListItemText primary={producer.producerName} />
                        </ListItemButton>
                      ))}
                    </List>
                  ) : null}
                </Box>
              ) : null}

              <ButtonGroup>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    history.push(`/registerOrder/:${product.productId}`)
                  }
                >
                  inquiry
                  <AddShoppingCartIcon />
                </Button>
                {decode.role === "SuperAdmin" ? (
                  <Button
                    variant="contained"
                    color='edit'
                    sx={{color:'#fff'}}
                    onClick={() =>
                      history.push(`/productProperties/:${product.productId}`)
                    }
                  >
                    Edit
                    <Edit />
                  </Button>
                ) : null}
              </ButtonGroup>
            </Box>
          </Box>
        </Box>:
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            bgcolor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading type="cylon" color="#000" />
        </Box>
        }
      </Box>
      <Footer />
    </div>
  );
}

export default SingleProduct;
