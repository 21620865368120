import {
  Button,
  TextField,
  IconButton,
  Box,
  Autocomplete,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Fab,
  ButtonGroup,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, Fragment, useEffect } from "react";
import { Add, Close, Delete, Edit } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toast, ToastContainer } from "react-toastify";
import { getRequest, postRequest } from "../../request";
import { useHistory } from "react-router-dom";

const styles = {
  box2: {
    boxShadow: "0px 0px 6px 2px #ddd",
    height: "70vh",
    width: "45rem",
    display: "flex",
    flexDirection: "column",
    borderRadius: "2%",
    padding: "5% 0"
  },
  box3: {
    boxShadow: "0px 0px 6px 2px #ddd",
    height: "70vh",
    width: "37rem",
    display: "flex",
    flexDirection: "column",
    borderRadius: "2%",
    padding: "5% 0"
  },
  box4: {
    // boxShadow: "0px 0px 6px 2px #ddd",
    height: "70vh",
    width: "37rem",
    display: "flex",
    flexDirection: "column",
    borderRadius: "2%",
    padding: "5% 0"
  },
  unitBox: {
    // boxShadow: "0px 0px 6px 2px #ddd",
    height: "70vh",
    padding: "0.5rem",
  },
  unit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  innerBox: {
    display: "grid",
    width: "100%",
    padding: "1rem",
    gridTemplateColumns: "auto auto",
    justifyContent: "space-around",
  },
  table2: {
    margin: "0rem 5rem",
    width: "auto",
  },
  table3: { margin: "0rem 3.5rem", width: "auto" },

  newclass1:{
    position: "absolute",
    width: "unset",
    background: "none",
    left: "60%",
    top: "27%",
  },
  newclass2:{
    position: "absolute",
    width: "unset",
    background: "none",
    left: "36%",
    top: "27%",
  },
};
function DefineSpeces({
  specUnitsList,
  setspecUnitsList,
  specesList,
  setSpecesList,
  selectedSpec,
  setSelectedSpec,
  newUnit,
  setNewUnit,
  specesData,
  setSpecesData
}) {
  const [units, setUnits] = useState([]);
  const [productSpecType, setProductSpecType] = useState([]);
  const history = useHistory();
  const handleChange = (event) => {
    setSpecesData({ ...specesData, [event.target.name]: event.target.value });
  };
  const handleRemoveSpec = (code) => {
    setSpecesList((prevstate) => [...prevstate.filter((x) => x.productSpecCode !== code)]);
  };
  const handleAddSpec = async() => {
    if ( !specesList.filter((x) => x.productSpecCode === specesData.productSpecCode).length ) {
      if (specesData.productSpecCode && specesData.productSpecName && specesData.productSpecTypeId) {
        const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec`,specesData)
        if(message === 'ok'){
          const tempData = specesData;
          tempData.productSpecId = data;
          setSpecesList((prevstate)=>[...prevstate,tempData])
          setSpecesData({
            productSpecCode: "",
            productSpecName: "",
            productSpecLocalName: "",
            productSpecDescription: "",
            productSpecTypeId:""
          });
        }
      }
    } else {
      if (specesList.filter((x) => x.productSpecCode === specesData.productSpecCode).length) {
        toast.error("code must be unique");
      } 
    }
  };
  const handleEditSpec = async (code, id) => {
    setSelectedSpec(id);
    setSpecesData(specesList.find((x) => x.productSpecCode === code));
    setNewUnit({
      productSpecId: "",
      productSpecValueAmount: "",
      unitCode: "",
      unitName: "",
      unitId: "",
    });
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/Search/Units`)
      if(data && Object.keys(data).length){
        setUnits(data);
      }else{
        toast.error(error);
      }
    if (id) {
      const response = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec/values/${id}`)
      if(data && Object.keys(data).length){
        const x = response.data.map((s) => ({
          productSpecValueId:s.productSpecValueId,
          productSpecId: s.productSpecId,
          productSpecValueAmount: s.productSpecValueAmount,
          unitCode: s.unit.unitCode,
          unitName: s.unit.unitName,
          unitId: s.unit.unitId,
        }));
        setspecUnitsList(x);
      }else{
        toast.error(error);
      }      
    }
  };
  const handleEdit = async() => {
    specesList[specesList.findIndex((x) => x.productSpecId === selectedSpec)] =
    specesData;
    const list = specesList;
    const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpec/edit`,specesData)
    
    setSpecesList(list);
    setSelectedSpec("");
    setSpecesData({
      productSpecCode: "",
      productSpecName: "",
      productSpecLocalName: "",
      productSpecDescription: "",
      productSpecTypeId:""
    });
  };
  
  const handleAddUnit = async()=>{
    const currentUnit = units.find(x=> x.name === newUnit.unitName);
    const {data,message} = await postRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue`,{productSpecId:selectedSpec,productSpecValueAmount:newUnit.productSpecValueAmount,unitId:currentUnit.id})
    const tempUnit = newUnit;
    tempUnit.unitId = data;
    tempUnit.productSpecId = selectedSpec;
    if(message==='ok'){
      setspecUnitsList(prevstate=>[...prevstate,tempUnit]);
    }
    setNewUnit({
      productSpecId: "",
      productSpecValueAmount: "",
      unitCode: "",
      unitName: "",
      unitId: "",
    })
  }
  const fetchSpecType=async()=>{
    const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecType`)
      if(data && Object.keys(data).length){
        setProductSpecType(data);
      }else{
        toast.error(error);
      }
  }
  useEffect(() => {
    fetchSpecType()
  }, []);
  
  return (
    <Fragment>
      <Box>
        <Box
          sx={{
            backgroundColor: "#ccc",
            borderRadius: "0.5rem 0.5rem 0rem 0rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography sx={selectedSpec ? styles.newclass2 : styles.newclass1}  variant="h5" color="initial">
            <span style={{
                fontWeight: "bold",
                color: "#343a40"
              }}>Specs</span>
          </Typography>
        </Box>
        <Box sx={selectedSpec ? styles.box3 : styles.box2}>
          <div style={styles.innerBox}>
            <TextField
              variant="outlined"
              label="Code"
              name="productSpecCode"
              value={specesData.productSpecCode}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              label="Name"
              name="productSpecName"
              value={specesData.productSpecName}
              onChange={handleChange}
            />
          </div>
          <div style={styles.innerBox}>
            <TextField
              variant="outlined"
              label="localName"
              name="productSpecLocalName"
              value={specesData.productSpecLocalName}
              onChange={handleChange}
            />

            <TextField
              variant="outlined"
              label="description"
              name="productSpecDescription"
              multiline={true}
              maxRows={4}
              value={specesData.productSpecDescription}
              onChange={handleChange}
            />
          </div>
          <div style={styles.innerBox}>
              <Box sx={{ minWidth: 211 }}>
                <FormControl fullWidth>
                  <InputLabel>Spec Type</InputLabel>
                  <Select
                    label="subCategory"
                    name="productSpecTypeId"
                    value={specesData.productSpecTypeId}
                    onChange={handleChange}
                  >
                    {productSpecType.map((type, index) => (
                      <MenuItem key={index} value={type.productSpecTypeId}>
                        <Box sx={{display:'flex',justifyContent:'space-between',width:'100%'}}>
                          {type.productSpecTypeName}
                          <Edit color="edit" onClick={(e)=>{
                            e.stopPropagation();
                            history.push(`/spec/${type.productSpecTypeId}`);
                          }}/>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <div style={{width:'211px'}}></div>
          </div>
          <div style={styles.innerBox}>
              <div style={{width:'211px'}}></div>
            <div
              style={{
                width: "211px",
                float: "right",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <ButtonGroup>
                <Button
                  variant="contained"
                  sx={{ color: "#efefef", bgcolor: "#e91e63" }}
                  onClick={() => {
                    setSelectedSpec("");
                    setSpecesData({
                      productSpecCode: "",
                      productSpecName: "",
                      productSpecLocalName: "",
                      productSpecDescription: "",
                    });
                  }}
                >
                  cancel
                  <Close />
                </Button>
                {selectedSpec ? null : (
                  <Button
                    variant="contained"
                    sx={{ color: "#efefef" }}
                    onClick={handleAddSpec}
                  >
                    add
                    <Add />
                  </Button>
                )}

                {selectedSpec ? (
                  <Button
                    color="edit"
                    variant="contained"
                    sx={{ color: "#efefef" }}
                    onClick={handleEdit}
                  >
                    edit
                    <Edit />
                  </Button>
                ) : null}
              </ButtonGroup>
            </div>
          </div>
          {specesList.length ? (
            <TableContainer
              sx={selectedSpec ? styles.table3 : styles.table2}
              component={Paper}
            >
              <Table
                sx={{ width: "95%", margin: "0.5rem" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>code</TableCell>
                    <TableCell align="right">name</TableCell>
                    <TableCell align="right">edit</TableCell>
                    <TableCell align="right">remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {specesList.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.productSpecCode}
                      </TableCell>
                      <TableCell align="right">{row.productSpecName}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleEditSpec(row.productSpecCode, row.productSpecId)}
                        >
                          <Edit style={{ color: "#009688" }} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label=""
                          onClick={() => handleRemoveSpec(row.productSpecCode)}
                        >
                          <Delete style={{ color: "#e53935" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </Box>
      </Box>
      {selectedSpec ? (
        <Box>
          <Box
            sx={{
              backgroundColor: "#ccc",
              borderRadius: "0.5rem 0.5rem 0rem 0rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography sx={{
              position: "absolute",
              width: "unset",
              background: "none",
              left: "70%",
              top: "27%",
            }} variant="h5" color="initial">
              <span style={{
                fontWeight: "bold",
                color: "#343a40"
              }}>Units</span>
            </Typography>
          </Box>
          <Box sx={selectedSpec ? styles.box3 : styles.box2}>
            <Box sx={styles.unitBox}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Autocomplete
                  disablePortal
                  value={newUnit.unitName}
                  onChange={(e, value) =>
                    setNewUnit({ ...newUnit, unitName: value })
                  }
                  options={units.map((x) => x.name)}
                  sx={{ width: "211px" }}
                  renderInput={(params) => (
                    <TextField {...params} label="unit" />
                  )}
                />
                <TextField
                  variant="outlined"
                  label="amount"
                  name="productSpecValueAmount"
                  value={newUnit.productSpecValueAmount}
                  onChange={(e) =>
                    setNewUnit({ ...newUnit, productSpecValueAmount: e.target.value })
                  }
                />
                <Fab
                  color="primary"
                  size="small"
                  sx={{ color: "#efefef" }}
                  onClick={() => {
                    if (newUnit.productSpecValueAmount && newUnit.unitName) {
                      handleAddUnit()
                    }else{
                      toast.error('add unit name and unit amount first');
                    }
                  }}
                >
                  <Add />
                </Fab>
              </div>

              {specUnitsList.length ? (
                <div style={{ marginTop: "2rem" }}>
                  <Box
                    sx={{
                      width: "100%",
                      minWidth: 230,
                      bgcolor: "background.paper",
                      boxShadow: "0px 0px 4px 2px #ddd",
                      height  : "30rem",
                      overflowY:'auto'
                    }}
                  >
                    <List>
                      {specUnitsList
                        .filter((x) => x.productSpecId === selectedSpec)
                        .map((x, index) => (
                          <ListItem
                            disablePadding
                            key={index}
                            sx={{
                              padding: "0.3rem",
                              display: "flex",
                              justifyContent: "space-around",
                              borderBottom: "1px solid #ddd",
                            }}
                          >
                            <ListItemText
                              primary={
                                x.productSpecValueAmount + " " + x.unitName
                              }
                            />

                            <ListItemIcon>
                              <IconButton
                                onClick={async() =>{
                                  const {data,error} = await getRequest(`${process.env.REACT_APP_BASE_URL}/ProductSpecValue/delete/${x.productSpecValueId}`)
                                  toast.success('item deleted successfully!')
                                  setspecUnitsList((prevstate) => [
                                    ...prevstate.filter(
                                      (state) =>  state.unitId !== x.unitId
                                    ),
                                  ]);
                                  }
                                }
                              >
                                <Delete />
                              </IconButton>
                            </ListItemIcon>
                          </ListItem>
                        ))}
                    </List>
                  </Box>
                </div>
              ) : null}
            </Box>
          </Box>
        </Box>
      ) : null}
    </Fragment>
  );
}

export default DefineSpeces;
